import React, { useState, createContext, useContext } from "react";
import complaintFormStructure from "../utils/complaintFormStructure";

// Create a context for complaint
const ComplaintContext = createContext();
export const ComplaintProvider = ({ children }) => {
    const [complaint, setComplaint] = useState(complaintFormStructure);
    return (
        <ComplaintContext.Provider value={{ complaint, setComplaint }}>
            {children}
        </ComplaintContext.Provider>
    );
};
export const useComplaint = () => {
    return useContext(ComplaintContext);
};