// TicketDetails.jsx
const sampleTickets = [
    {
      srNo: null,
      ticketId : '',
      complainantId : null,
      complainantPortalRole : null,
      ticketPortalId : null,
      issueCategoryId : null,
      issueSubcategoryId : null,
      issue : "",
      openDate : "",
      closedDate : null,
      assignedTo : null,
      ticketPriority : null,
      ticketType : null,
      ticketStatus : "",
      updateDate : "",
      logId : null,
      remark : null,
      screenshots: "",
      updatedBy: ""
    },

]

export default sampleTickets;