import React, { useEffect, useState } from 'react'
import { useAdminTicketCounts, useAdminTickets } from '../../../contextapi/adminContext'
import { getApiResponse } from '../../../utils/apiPromise'
import { useNavigate } from 'react-router-dom'

const fetchAdminTicketsApi = process.env.REACT_APP_API_FETCH_ADMIN_TICKETS

export default function AdminTickets() {
    const { setTickets } = useAdminTickets()
    const {setTicketCounts} = useAdminTicketCounts()
    const [fetchTicketsApi, setFetchTicketsApi] = useState(false)
    const authToken = sessionStorage.getItem('authToken')
    const navigate = useNavigate();

    useEffect(() => {
        setFetchTicketsApi(true)
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const ticketsResponse = await getApiResponse(fetchAdminTicketsApi, authToken)
                setTickets(ticketsResponse.tickets)
                setTicketCounts(ticketsResponse.ticketCounts)
            } catch (error) {
                alert(error)
                navigate('/')
            }
        }
        if (fetchTicketsApi) {
            fetchData()
        }
    }, [fetchTicketsApi])
    return (
        <></>
    )
}
