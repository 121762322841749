import React, { useEffect } from 'react'
import { formatToJSDate } from '../../../utils/formatDate'
import { FaTicketAlt } from 'react-icons/fa'
import { Button, Col, Row } from 'react-bootstrap'

export default function OfficerActionPerformedHistory({ ticketsAction }) {

    return (
        <>
            {ticketsAction.map((action, index) => (
                <>
                    <Row>
                        <Col md={2}>
                            <Button disabled style={{ pointerEvents: 'none', opacity: 1 }} variant={action.ticketStatus === 'REVIEWING' ? 'outline-success' : 'outline-danger'} size='sm'>
                                <FaTicketAlt /> | {action.ticketId}
                            </Button>
                        </Col>
                        <Col>
                            <p className='small my-0'>
                                <strong>{formatToJSDate(action.updateDate)}</strong> | {action.ticketStatus}
                                <br />
                                {action.remark}</p>
                        </Col>
                    </Row>
                    <hr className='my-2' />
                </>
            ))}
        </>
    )
}
