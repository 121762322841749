import React, { useState } from 'react'
import { Row, Col, Container, Form, InputGroup, Button } from 'react-bootstrap'
import CommonNavbar from '../components/common/CommonNavbar'
import { postApiResponse } from '../utils/apiPromise'
import loginFormStructure from '../utils/loginFormStructure'
import { useNavigate } from 'react-router-dom'
import { useUserDetails } from '../contextapi/userDetails'
import roles from '../assets/arrays/roleTypes'

const userLoginApi = process.env.REACT_APP_API_USER_LOGIN
const updatePasswordApi = process.env.REACT_APP_API_UPDATE_PASSWORD


export default function ChangePassword() {
    const [newPassword, setNewPassword] = useState({
        emailId: '',
        updatedPassword: ''
    })
    const navigate = useNavigate()
    const [user, setUser] = useState(loginFormStructure)
    const [validatedAccount, setValidatedAccount] = useState(false)
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [validated, setValidated] = useState(false);
    const [fieldValidate, setFieldValidate] = useState({
        emailId: true,
        password: true,
        updatedPassword: true,
        confirmPassword: true
    });
    const { userDetails, setUserDetails } = useUserDetails()
    const passwordPattern = /^[a-zA-Z0-9@.\-]+$/;
    // const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let isValid


    const handleChange = (event) => {
        const { id, value, type, name } = event.target
        if (type === 'radio' && name === 'roleType') {
            setUser({ ...user, userRole: value })
        } else if (id === 'emailId') {
            isValid = emailPattern.test(value) || value === ''
            setFieldValidate({ ...fieldValidate, [id]: isValid })
            setUser({ ...user, [id]: value })
        } else if (id === 'password') {
            isValid = passwordPattern.test(value) || value === ''
            setFieldValidate({ ...fieldValidate, [id]: isValid })
            setUser({ ...user, [id]: value })

        }
    }

    const validateAccount = async (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            try {
                const validateResponse = await postApiResponse(userLoginApi, user)
                if (validateResponse.status === true) {
                    setValidatedAccount(true)
                    setFormSubmitted(true)
                    setUserDetails({ ...userDetails, ...(validateResponse.userDetails) })
                    setNewPassword({ ...newPassword, emailId: user.emailId })
                    sessionStorage.setItem('authToken', validateResponse.userDetails.authToken)
                }
            } catch (error) {
                alert('Previous Credentials does not match')
            }
        }
        setValidated(true);
    }


    const handleNewPassword = (event) => {
        const { id, value, type, name } = event.target
        if (id === 'password') {
            isValid = passwordPattern.test(value) || value === ''
            setFieldValidate({ ...fieldValidate, [id]: isValid })
        } else if (id === 'confirmPassword') {
            isValid = value === newPassword.updatedPassword
            setFieldValidate({ ...fieldValidate, [id]: isValid })
        }
        setNewPassword({ ...newPassword, [id]: value })
    }


    const submitNewPassword = async (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            try {
                const authToken = sessionStorage.getItem('authToken')
                const passwordUpdateResponse = await postApiResponse(updatePasswordApi, newPassword, authToken)
                if (passwordUpdateResponse.status) {
                    alert('Password has been changed successfully')
                    sessionStorage.removeItem('authToken')
                    navigate('/')
                }
            } catch (error) {
                alert(error)
            }
        }
        setValidated(true);
    }

    return (
        <>
            <Row>
                <CommonNavbar />
            </Row>
            <Container className='mt-4 bg-light p-4' style={{ width: '40vw', borderRadius: '1rem' }}>
                {!formSubmitted && (
                    <Form noValidate validated={validated} onSubmit={validateAccount}>
                        <Row className="mb-3">
                            <Form.Group controlId="emailId" onChange={handleChange}>
                                <Form.Label>Email Id</Form.Label>
                                <InputGroup hasValidation>
                                    <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                                    <Form.Control
                                        type="email"
                                        placeholder="Your registered email id..."
                                        aria-describedby="inputGroupPrepend"
                                        isInvalid={!fieldValidate.emailId}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid email Id.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </Row >

                        <Row className="mb-3">
                            <Form.Group controlId="userRole" onChange={handleChange}>
                                <Form.Label>Select the role type</Form.Label>
                                <div key={`userRole`}>
                                    {roles.map((role, index) => (
                                        <Form.Check
                                            key={index + 1}
                                            inline
                                            label={role.label}
                                            type='radio'
                                            name='roleType'
                                            id={`roleType_${role.value}`}
                                            value={role.value}
                                            required={index === 0} // Make the first radio button required
                                        />
                                    ))}
                                </div>
                            </Form.Group>
                        </Row>

                        <Row className="mb-3">
                            <Col md={7}>
                                <Form.Group controlId="password" onChange={handleChange}>
                                    <Form.Label>Old Password</Form.Label>
                                    <InputGroup hasValidation>
                                        <Form.Control
                                            type="password"
                                            placeholder="Type your old password here..."
                                            aria-describedby="inputGroupPrepend"
                                            isInvalid={!fieldValidate.password}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Password must only contain letters, numbers, '@', '.', and '-'.
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Label className='w-100'>&nbsp;</Form.Label>
                                <Button type='submit' className='w-100'>Validate you Account</Button>
                            </Col>
                        </Row >
                    </Form>
                )}

                {(formSubmitted || validatedAccount) && (
                    <>
                        <Form noValidate validated={validated} onSubmit={submitNewPassword}>
                            <Row className="mb-3">
                                <Form.Group controlId="emailId" disabled>
                                    <Form.Label>Email Id</Form.Label>
                                    <InputGroup hasValidation>
                                        <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                                        <Form.Control
                                            type="email"
                                            readOnly
                                            aria-describedby="inputGroupPrepend"
                                            value={user.emailId}
                                            required
                                        />
                                    </InputGroup>
                                </Form.Group>
                            </Row >

                            <Row className="mb-3">
                                <Form.Group controlId="updatedPassword" onChange={handleNewPassword}>
                                    <Form.Label>New Password</Form.Label>
                                    <InputGroup hasValidation>
                                        <Form.Control
                                            type="password"
                                            placeholder="Type your new password here..."
                                            aria-describedby="inputGroupPrepend"
                                            isInvalid={!fieldValidate.updatedPassword}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Password must only contain letters, numbers, '@', '.', and '-'.
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            </Row >

                            <Row className="mb-3">
                                <Form.Group controlId="confirmPassword" onChange={handleNewPassword}>
                                    <Form.Label>Confirm New Password</Form.Label>
                                    <InputGroup hasValidation>
                                        <Form.Control
                                            type="password"
                                            placeholder="Type your new password again..."
                                            aria-describedby="inputGroupPrepend"
                                            isInvalid={!fieldValidate.confirmPassword}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Password doesn't match!!!
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            </Row >
                            <Button type='submit' className='mt-3'>Set New Password</Button>
                        </Form>
                    </>
                )}
                {/* <Modal show >
                    <Modal.Header closeButton>
                        <Modal.Title>Password Reset</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    </Modal.Body>
                </Modal> */}
            </Container >
        </>
    )
}
