import React, { useState, createContext, useContext } from "react";

// Create a context for sample tickets
const SampleTicketsContext = createContext();
export const SampleTicketsProvider = ({ children }) => {
    const [tickets, setTickets] = useState([]);
    return (
        <SampleTicketsContext.Provider value={{ tickets, setTickets }}>
            {children}
        </SampleTicketsContext.Provider>
    );
};
export const useSampleTickets = () => {
    return useContext(SampleTicketsContext);
};