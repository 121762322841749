import React, { useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import CommonNavbar from "../../components/common/CommonNavbar";
import SideMenu from "../../components/common/SideMenu";
import { handlerSideMenu } from "../../assets/sideMenuTabs";
import { postApiResponse } from "../../utils/apiPromise";
import { useNavigate } from "react-router-dom";
import { IoAlertCircleSharp } from "react-icons/io5";
import { DataGridWithMultipleButtons } from "../../components/datagrids/AgDataGrid";
import { formatToJSDate } from "../../utils/formatDate";
import { ticketDetailedStructure } from "../../assets/dataStructures/ticketsStructure";
import { MdOutlineSupportAgent } from 'react-icons/md';
import { FaTicketAlt } from 'react-icons/fa';
import { TiMessages } from "react-icons/ti";
import { FaUserTag } from "react-icons/fa6";
import { ControllerViewTicket } from "../../modals/ViewTicket";
import PromptRemark from "../../modals/PromptRemark";
import { ErrorMessage } from "../../modals/ErrorMessage";
import DataLoader from "../../components/common/DataLoader";
import NoDataAvailable from "../../components/common/NoDataAvailable";

const fetchStatusWiseTicketsApi = process.env.REACT_APP_API_FETCH_TICKETS_STATUSWISE;
const approveRevertTicketApi = process.env.REACT_APP_API_APPROVE_REVERT_HANDLER_TICKETS
const disapproveRevertTicketApi = process.env.REACT_APP_API_DISAPPROVE_REVERT_HANDLER_TICKETS

export default function HandlerRevertedTickets() {
  const [fetchTickets, setFetchTickets] = useState(false);
  const [loading, setLoading] = useState(false); // Loader state
  const [revertedTickets, setRevertedTickets] = useState([ticketDetailedStructure]);
  const [selectedApi, setSelectedApi] = useState(approveRevertTicketApi)

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorTitle, setErrorTitle] = useState('');

  const [showViewTicket, setShowViewTicket] = useState(false)
  const [showPrompt, setShowPrompt] = useState(false)
  const [selectedTicket, setSelectedTicket] = useState(null); // State for the selected ticket

  const authToken = sessionStorage.getItem("authToken");
  const navigate = useNavigate();

  useEffect(() => {
    setFetchTickets(true);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const ticketResponse = await postApiResponse(fetchStatusWiseTicketsApi, {
          ticketStatus: ['reverted']
        }, authToken);
        setRevertedTickets([...ticketResponse.tickets]);
      } catch (error) {
        setErrorTitle('Failed to Fetch Tickets');
        (error.message && setErrorMessage(error.message))
        setShowErrorModal(true);
      } finally {
        setLoading(false); // Hide loader
      }
    };
    if (fetchTickets) {
      fetchData();
    }
  }, [authToken, fetchTickets]);

  const handleCloseErrorModal = () => {
    setShowErrorModal(false)
  };

  const buttonsProps =
    [
      {
        variant: 'success',
        text: <FaUserTag />,
        tooltip: "Send Back to Officer", // Hover text
        onClick: (params) => {
          setSelectedApi(disapproveRevertTicketApi)
          setSelectedTicket(params.data); // Set the selected ticket
          setShowPrompt(true)
        }
      },
      {
        variant: 'warning',
        text: <MdOutlineSupportAgent />,
        tooltip: "Send to Help Desk", // Hover text
        onClick: (params) => {
          setSelectedApi(approveRevertTicketApi)
          setSelectedTicket(params.data); // Set the selected ticket
          setShowPrompt(true)
        }
      },
      {
        variant: 'secondary',
        text: <FaTicketAlt />,
        tooltip: "View Ticket", // Hover text
        onClick: (params) => {
          setSelectedTicket(params.data); // Set the selected ticket
          setShowViewTicket(true); // Open the modal
        }
      },
      {
        variant: 'primary',
        text: <TiMessages />,
        tooltip: "View Trail", // Hover text
        onClick: (params) => {
          navigate('/tickettrail', { state: { ticketId: params.data.ticketId } });
        }
      }
    ]


  const columns = [
    { field: 'ticketId', headerName: 'Ticket ID' },
    { field: 'openDate', headerName: 'Generated On', valueFormatter: (params) => formatToJSDate(params.value) },
    { field: 'updateDate', headerName: 'Reverted On', valueFormatter: (params) => formatToJSDate(params.value) },
    { field: 'issueCategory', headerName: 'Issue Type' },
    { field: 'remarkBy', headerName: 'Reverted By' }
  ];

  return (
    <>
      <Row>
        <CommonNavbar />
      </Row>

      <Row>
        <Col md={2}>
          <SideMenu menu={handlerSideMenu} theme="dark" />
        </Col>

        <Col md={10}>
          <h4 className="mt-2">Reverted Tickets</h4>
          <hr />
          {loading ? <DataLoader /> : (
            <>
              {revertedTickets.length > 0 ? (
                <DataGridWithMultipleButtons
                  data={revertedTickets}
                  pagination="true"
                  columns={columns}
                  rowsPerPage={20}
                  rowsPerPageSelector={[20, 50, 100]}
                  buttonsProps={buttonsProps}
                  theme="light"
                  headerBgClass="bg-success text-white"
                />
              ) : <NoDataAvailable />}
            </>
          )}
        </Col>
      </Row>

      <ControllerViewTicket showViewTicket={showViewTicket} setShowViewTicket={setShowViewTicket} ticketDetails={selectedTicket} />
      <PromptRemark showPrompt={showPrompt} setShowPrompt={setShowPrompt} ticketDetails={selectedTicket} postApi={selectedApi} />
      <ErrorMessage show={showErrorModal} handleClose={handleCloseErrorModal} errorTitle={errorTitle} errorMessage={errorMessage} />
    </>
  );
}
