import React from 'react'
import { Modal } from 'react-bootstrap'
import CreateComplainantForm from '../forms/CreateComplainantForm'


export default function CreateComplainantPrompt(props) {
  const showRegisterComplainant = props.showRegisterComplainant;
  const setShowRegisterComplainant = props.setShowRegisterComplainant;

  const handleClose = () => {
    setShowRegisterComplainant(false)
  }

  return (
    <>
      <Modal size='lg' show={showRegisterComplainant} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Complainant</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateComplainantForm handleClose={handleClose} />
        </Modal.Body>
      </Modal>
    </>
  )
}
