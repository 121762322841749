import React, { Suspense } from 'react'
import Loading from '../common/Loading'
import { Container, Row, Col, Button } from 'react-bootstrap'
import './ticketTrail.css'
import formatDate from '../../utils/formatDate'
import formatTime from '../../utils/formatTime'
import axios from 'axios'

const fetchDevScreenshotsApi = process.env.REACT_APP_API_FETCH_DEV_SCREENSHOTS



export default function TicketTrail({ ticketId, ticketTrail, uniqueDates }) {
    let trailIndex = 0

    const openScreenshot = async (fileName) => {
        const authToken = sessionStorage.getItem('authToken')
        try {
            axios.post(fetchDevScreenshotsApi, {
                filename: fileName
            }, {
                headers: {
                    'Authorization': authToken,
                    'Content-Type': 'application/json'
                },
                responseType: 'blob'
            })
                .then(response => {
                    const blob = new Blob([response.data], { type: 'application/pdf' });
                    const pdfUrl = URL.createObjectURL(blob);
                    window.open(pdfUrl); // Open the PDF in a new tab
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        } catch (error) {
            alert(error)
        }
    }

    return (
        <>
            <Suspense fallback={<Loading />}>
                {ticketTrail.length > 0 ? (

                    uniqueDates.map(date => (
                        <>
                            <Button variant='warning'>{date}</Button>
                            <ul className='timeline-3'>
                                {ticketTrail
                                    .filter((dateLog) => formatDate(dateLog.updateDate) === date)
                                    .map((log, index) => {
                                        const previousLog = trailIndex === ticketTrail.length ? null : ticketTrail[trailIndex + 1];

                                        trailIndex++;

                                        const isProgressAfterOpen = previousLog && previousLog.ticketStatus === "OPEN" && log.ticketStatus === "PROGRESS";

                                        return (
                                            <li key={index}>
                                                <Row className='mb-0'>
                                                    <Col md={8}>
                                                        <h6 className='mb-0'>{log.ticketStatus}</h6>
                                                    </Col>
                                                    <Col>
                                                        <h6 className="float-end mb-0">{formatTime(log.updateDate)}</h6>
                                                    </Col>
                                                </Row><hr className='mt-1 mb-1' />
                                                <Row>
                                                    <Col>
                                                        {isProgressAfterOpen ? (
                                                            <>
                                                                <p style={{ fontSize: '13px' }}>
                                                                    <strong>Assigned by: </strong>
                                                                    {log.loggedBy}
                                                                </p>
                                                                <blockquote style={{ fontStyle: "italic", fontSize: "0.9rem" }}>
                                                                    Ticket has been assigned to {log.assignedToName}
                                                                </blockquote >
                                                            </>
                                                        ) : (
                                                            <>
                                                                <p style={{ fontSize: '13px' }}>
                                                                    <strong>Updated by: </strong>
                                                                    {log.loggedBy}
                                                                </p>
                                                                <blockquote style={{ fontStyle: "italic", fontSize: "0.9rem" }}>
                                                                    {log.remark}
                                                                </blockquote >
                                                                {log.devScreenshots ? (
                                                                    <Button className='float-end' onClick={() => openScreenshot(log.devScreenshots)}>View Screenshot</Button>
                                                                ) : null}
                                                            </>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </li>
                                        )
                                    })}
                            </ul >
                        </>
                    ))
                ) : null}

            </Suspense >
        </>
    )
}
