export default function formatDate(timeStamp){

    // Convert the timestamp to a Date object
    const date = new Date(timeStamp);

    // Extract day, month, and year from the Date object
    const day = date.getDate();
    const month = date.getMonth() + 1; // Month starts from 0, so add 1
    const year = date.getFullYear();

    // Pad day and month with leading zeros if necessary`
    const paddedDay = day < 10 ? `0${day}` : day;
    const paddedMonth = month < 10 ? `0${month}` : month;

    // Format the date as "dd/mm/yyyy"
    const formattedDate = `${paddedDay}/${paddedMonth}/${year}`;
    if (year < 1990) return null
    return formattedDate
}

export const formatToJSDate = (timeStamp) => {
    // Convert the timestamp to a Date object
    const date = new Date(timeStamp);

    // Check for invalid dates or years before 1990
    if (isNaN(date.getTime()) || date.getFullYear() < 1990) {
        return null;
    }

    // Extract individual components
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' }); // Month in full name (e.g., January)
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    // Combine into JS Date format: "Day Month Year HH:mm:ss"
    return `${day} ${month} ${year} ${hours}:${minutes}:${seconds}`;
};