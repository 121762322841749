import React from 'react'
import Chart from 'react-apexcharts'
import { colorVariants } from '../../assets/colorVariants';

export function PieChartComponent({ data, labelKey, countKey, sectorCount }) {

    const countArray = data.map(item => item[countKey]);

    let finalChartSeries, finalLabels;
    if (sectorCount && sectorCount < countArray.length) {
        const individualSeries = countArray.slice(0, sectorCount);
        const otherSeriesSum = countArray.slice(sectorCount).reduce((acc, num) => acc + num, 0);

        finalChartSeries = [...individualSeries, otherSeriesSum];
        finalLabels = [
            ...data.slice(0, sectorCount).map(item => item[labelKey]),
            'Others'
        ];
    } else {
        finalChartSeries = countArray;
        finalLabels = data.map(item => item[labelKey]);
    }

    const chartOptions = {
        chart: {
            type: 'pie',
        },
        labels: finalLabels,
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    };

    return (
        <>
            <div>
                <Chart
                    options={chartOptions}
                    series={finalChartSeries}
                    type="pie"
                    width="400"
                />
            </div>
        </>
    )
}

export function PieChartMonochromeComponent({ data, labelKey, countKey, sectorCount, variant, theme }) {

    const selectedColorVariant = colorVariants.find(colors => colors.variantName === variant);
    const selectedTheme = (theme === 'dark' ? '#ffffff' : '#000000')
    const countArray = data.map(item => item[countKey]);

    let finalChartSeries, finalLabels;
    if (sectorCount && sectorCount < countArray.length) {
        const individualSeries = countArray.slice(0, sectorCount);
        const otherSeriesSum = countArray.slice(sectorCount).reduce((acc, num) => acc + num, 0);

        finalChartSeries = [...individualSeries, otherSeriesSum];
        finalLabels = [
            ...data.slice(0, sectorCount).map(item => item[labelKey]),
            'Others'
        ];
    } else {
        finalChartSeries = countArray;
        finalLabels = data.map(item => item[labelKey]);
    }

    const chartOptions = {
        series: finalChartSeries,
        chart: {
            type: 'pie',
        },
        labels: finalLabels,
        theme: {
            monochrome: {
                enabled: true,
                color: selectedColorVariant ? selectedColorVariant.hexCode : '#3B71CA',
                shadeTo: 'light',   // Can be 'light' or 'dark'
                shadeIntensity: 0.65 // Controls the intensity of shading
            },
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }],
        legend: {
            show: true,
            labels: {
                colors: selectedTheme
            }
        }
    };

    return (
        <>
            <div>
                <Chart
                    options={chartOptions}
                    series={chartOptions.series}
                    type="pie"
                    width="400"
                />
            </div>
        </>
    )
}



export function DonutChartComponent({ data, labelKey, countKey, sectorCount, title, theme }) {

    const selectedTheme = (theme === 'dark' ? '#ffffff' : '#000000')
    const countArray = data.map(item => item[countKey]);

    let finalChartSeries, finalLabels;
    if (sectorCount && sectorCount < countArray.length) {
        const individualSeries = countArray.slice(0, sectorCount);
        const otherSeriesSum = countArray.slice(sectorCount).reduce((acc, num) => acc + num, 0);

        finalChartSeries = [...individualSeries, otherSeriesSum];
        finalLabels = [
            ...data.slice(0, sectorCount).map(item => item[labelKey]),
            'Others'
        ];
    } else {
        finalChartSeries = countArray;
        finalLabels = data.map(item => item[labelKey]);
    }


    const chartOptions = {
        chart: {
            type: 'donut',
        },
        labels: finalLabels,
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom',
                }
            }
        }],
        legend: {
            labels: {
                colors: selectedTheme
            }
        },
        title: {
            text: title,
            align: 'left',
            style: {
                color: selectedTheme
            }
        }
    };

    return (
        <div>
            <Chart
                type="donut"
                options={chartOptions}
                series={finalChartSeries}
                width="400" />
        </div>
    );
};

export function DonutChartMonochromeComponent({ data, labelKey, countKey, sectorCount, variant, title, theme }) {

    const selectedColorVariant = colorVariants.find(colors => colors.variantName === variant);
    const selectedTheme = (theme === 'dark' ? '#ffffff' : '#000000')
    const countArray = data.map(item => item[countKey]);

    let finalChartSeries, finalLabels;
    if (sectorCount && sectorCount < countArray.length) {
        const individualSeries = countArray.slice(0, sectorCount);
        const otherSeriesSum = countArray.slice(sectorCount).reduce((acc, num) => acc + num, 0);

        finalChartSeries = [...individualSeries, otherSeriesSum];
        finalLabels = [
            ...data.slice(0, sectorCount).map(item => item[labelKey]),
            'Others'
        ];
    } else {
        finalChartSeries = countArray;
        finalLabels = data.map(item => item[labelKey]);
    }


    const chartOptions = {
        chart: {
            type: 'donut',
        },
        labels: finalLabels,
        theme: {
            monochrome: {
                enabled: true,
                color: selectedColorVariant ? selectedColorVariant.hexCode : '#3B71CA',
                shadeTo: 'light',   // Can be 'light' or 'dark'
                shadeIntensity: 0.65 // Controls the intensity of shading
            },
        },
        plotOptions: {
            pie: {
                donut: {
                    size: '65' // Increase this percentage to widen the donut
                }
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }],
        legend: {
            labels: {
                colors: selectedTheme
            }
        },
        title: {
            text: title,
            align: 'left',
            style: {
                color: selectedTheme,
            }
        }
    };

    return (
        <div>
            <Chart
                type="donut"
                options={chartOptions}
                series={finalChartSeries}
                width="400" />
        </div>
    );
};