// const formatTime = (timeStamp) => {
//     // Convert the timestamp to a Date object
//     const date = new Date(timeStamp);

//     // Extract hours and minutes from the Date object
//     const hours = date.getHours();
//     const minutes = date.getMinutes();

//     // Pad hours and minutes with leading zeros if necessary
//     const paddedHours = hours < 10 ? `0${hours}` : hours;
//     const paddedMinutes = minutes < 10 ? `0${minutes}` : minutes;

//     // Format the time as "hh:mm"
//     const formattedTime = `${paddedHours}:${paddedMinutes}`;

//     return formattedTime;
// };

const formatTime = (timeStamp) => {
    // Convert the timestamp to a Date object
    const date = new Date(timeStamp);

    // Extract hours, minutes, and AM/PM from the Date object
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const amOrPm = hours >= 12 ? 'PM' : 'AM';

    // Convert hours from 24-hour to 12-hour format
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;

    // Pad hours and minutes with leading zeros if necessary
    const paddedHours = formattedHours < 10 ? `0${formattedHours}` : formattedHours;
    const paddedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    // Format the time as "hh:mm AM/PM"
    const formattedTime = `${paddedHours}:${paddedMinutes} ${amOrPm}`;

    return formattedTime;
};

export default formatTime;
