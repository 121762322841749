const createUserStructure = {
    userName: '',
    emailId: '',
    phone: '',
    userRoleId: null,
    portalId: 1,
    userStatus: 'active',
    qaRequired: false,
    viewTicketType: []
}

const createComplainantStructure = {
    userName: '',
    emailId: '',
    password: '',
    phone: '',
    userRoleId: 5,
    portalId: 1,
    userStatus: 'active',
    qaRequired: false,
    companyName: '',
    eprRegNo: '',
    complainantPortalRole: 1
}

export { createUserStructure, createComplainantStructure }