import React, { useEffect, useState } from 'react'
import { PieChartComponent } from '../../graphs/PieChartComponents';
import { Form } from 'react-bootstrap';
import { convertDuration } from '../../../utils/convertText';

const chartTypeOptions = ['Less Than', 'More Than', 'Cumulative']


export default function OfficerTicketsDurationPieChart({ tickets, disabledStatuses = [] }) {

    const [selectStatus, setSelectStatus] = useState('PROGRESS'); // Store selected ticketStatus
    const [chartType, setChartType] = useState('Less Than'); // Store selected ticketStatus
    const pendingTicketsCount = timeDiffStatusWiseTicketsCounts(tickets, chartType)


    const [restructuredData, setRestructuredData] = useState(
        restructureDataForPieChart(pendingTicketsCount, selectStatus)
    );

    useEffect(() => {
        // Restructure data whenever the selected status or tickets change
        setRestructuredData(restructureDataForPieChart(pendingTicketsCount, selectStatus));
    }, [selectStatus, chartType, tickets]);


    return (
        <>
            <Form>
                <Form.Group>
                    <Form.Select
                        value={selectStatus}
                        onChange={(e) => setSelectStatus(e.target.value)}
                    >
                        <option value="">Select the Ticket Status...</option>
                        {Array.from(new Set(pendingTicketsCount.map((ticket) => ticket.ticketStatus))).map((status) => (
                            <option
                                key={status}
                                value={status}
                                disabled={disabledStatuses.includes(status)} // Disable based on prop
                            >
                                {status}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
            </Form>
            <Form className='py-2'>
                <Form.Group>
                    {/* <Form.Label>Select the Chart Type</Form.Label> */}
                    {chartTypeOptions.map((type) => (
                        <Form.Check
                            key={type}
                            type="radio"
                            id={`chart-type-${type}`}
                            label={type}
                            name="chartType"
                            value={type}
                            checked={chartType === type}
                            onChange={(e) => setChartType(e.target.value)}
                            disabled={type === 'Cumulative'} // Disable for 'Cumulative'
                            inline
                        />
                    ))}
                </Form.Group>
            </Form>
            <PieChartComponent
                data={restructuredData}
                labelKey="durationType"
                countKey="count"
                durationKey={selectStatus}
                variant='success'
            />
        </>
    )
}

function restructureDataForPieChart(data, statusKey) {
    // Filter data based on the selected ticketStatus
    const filteredData = data.filter(item => item.ticketStatus === statusKey);

    // Restructure the filtered data for pie chart
    return filteredData.flatMap(item =>
        Object.entries(item.ticketDurations || {}).map(([key, value]) => ({
            count: value,
            durationType: convertDuration(key)
        }))
    );
}

const timeDiffStatusWiseTicketsCounts = (tickets, chartType, currentDate = new Date()) => {
    const lessThanTimeFrames = {
        beforeTodayCount: 0,
        before7DaysCount: 7,
        before1MonthCount: 30,
        before3MonthsCount: 90,
        before6MonthsCount: 180,
        before1YearCount: 365,
    };

    const moreThanTimeFrames = {
        after1YearCount: 365,
        after6MonthsCount: 180,
        after3MonthsCount: 90,
        after1MonthCount: 30,
        after7DaysCount: 7,
        afterTodayCount: 0,
    };

    const cumulativeTimeFrames = {
        beforeTodayCount: 0,
        before7DaysCount: 7,
        before1MonthCount: 30,
        before3MonthsCount: 90,
        before6MonthsCount: 180,
        before1YearCount: 365,
    };

    const groupByStatus = {};

    tickets.forEach((ticket) => {
        const ticketStatus = ticket.ticketStatus;
        const updateDate = new Date(ticket.updateDate);
        const daysDiff = Math.floor((currentDate - updateDate) / (1000 * 60 * 60 * 24));

        if (!groupByStatus[ticketStatus]) {
            const ticketDurations = {};

            // Switch logic to add keys based on chartType
            switch (chartType) {
                case 'Less Than':
                    // Add only 'Less Than' related keys
                    Object.keys(lessThanTimeFrames).forEach(key => {
                        ticketDurations[key] = 0;
                    });
                    break;

                case 'More Than':
                    // Add only 'More Than' related keys
                    Object.keys(moreThanTimeFrames).forEach(key => {
                        ticketDurations[key] = 0;
                    });
                    break;

                case 'Cumulative':
                    // Add only 'Cumulative' related keys
                    Object.keys(cumulativeTimeFrames).forEach(key => {
                        ticketDurations[key] = 0;
                    });
                    break;

                default:
                    break;
            }

            groupByStatus[ticketStatus] = {
                ticketStatus: ticketStatus,
                ticketDurations: ticketDurations,
            };
        }

        const ticketDurations = groupByStatus[ticketStatus].ticketDurations;

        // Switch logic based on chartType to update the ticket counts
        switch (chartType) {
            case 'Less Than':
                for (const [key, days] of Object.entries(lessThanTimeFrames)) {
                    if (daysDiff <= 0) {
                        ticketDurations.beforeTodayCount++;
                        break;
                    } else if (daysDiff <= days) {
                        ticketDurations[key]++;
                        break; // Stop incrementing other keys
                    }
                }
                break;

            case 'More Than':
                for (const [key, days] of Object.entries(moreThanTimeFrames)) {
                    if (daysDiff <= 0) {
                        break;
                    } else if (daysDiff >= days) {
                        ticketDurations[key]++;
                        break; // Stop incrementing other keys
                    }
                }
                break;

            case 'Cumulative':
                for (const [key, days] of Object.entries(cumulativeTimeFrames)) {
                    if (daysDiff <= days) {
                        ticketDurations[key]++;
                        break; // Stop incrementing other keys
                    }
                }
                break;

            default:
                break;
        }
    });

    console.log(groupByStatus);

    return Object.values(groupByStatus);
};
