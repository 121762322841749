import React from 'react'
import { Row, Col } from 'react-bootstrap'
import dataGovLogo from '../../assets/images/dataGovLogo.png'
import digitalIndiaLogo from '../../assets/images/digitalIndiaLogo.png'
import indiaGovLogo from '../../assets/images/indiaGovLogo.png'
import myGovLogo from '../../assets/images/myGovLogo.png'
import pmIndiaLogo from '../../assets/images/pmIndiaLogo.png'
import swatchhBharatLogo from '../../assets/images/swatchhBharatLogo.png'

export default function Footer() {
    return (
        <>
            <Row className='text-ceter p-2 mt-3 mb-3'>
                <Col md={2}><img src={dataGovLogo} alt="data-gov-logo" /></Col>
                <Col md={2}><img src={digitalIndiaLogo} alt="digital-india-logo" /></Col>
                <Col md={2}><img src={indiaGovLogo} alt="india-gov-logo" /></Col>
                <Col md={2}><img src={myGovLogo} alt="my-gov-logo" /></Col>
                <Col md={2}><img src={pmIndiaLogo} alt="pm-india-logo" /></Col>
                <Col md={2}><img src={swatchhBharatLogo} alt="swatch-bharat-logo" /></Col>
            </Row>
            <Row className='text-center p-2'>
                <Col>
                    <h5>About CPCB</h5>
                    <p>The Central Pollution Control Board (CPCB), statutory organisation, was constituted in September, 1974 under the Water (Prevention and Control of Pollution) Act, 1974. Further, CPCB was entrusted with the powers and functions under the Air (Prevention and Control of Pollution) Act, 1981.</p>
                </Col>
                <Col>
                    <h5>About Ticket Tracker Portal</h5>
                    <p>A complaint lodging portal facilitating ticket generation for tracking updates. Enhances communication, prioritizes tasks, and streamlines issue resolution within organizations, optimizing workflow management.</p>
                </Col>
                <Col>
                    <h5>About Ministry of Environment</h5>
                    <p>The Ministry of Environment, Forest and Climate Change (MoEFCC) is the nodal agency in the administrative structure of the Central Government for the planning, promotion, co-ordination and overseeing the implementation of India’s environmental and forestry policies and programmes.</p>
                </Col>
            </Row>
            <Row className='bg-light'>
                <p className='text-center mt-2'>© 2024 | MoEF&CC | This site is designed, developed and maintained by CPCB</p>
            </Row>
        </>
    )
}
