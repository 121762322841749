import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'

export default function HandlerTicketCountCard({ tickets, ticketType }) {
    const dhupcReviewingTicketsCount = tickets.filter(ticket => ticket.ticketStatus === 'REVIEWING' && ticket.ticketType === 'DHUPC II').length;
    const dhitReviewingTicketsCount = tickets.filter(ticket => ticket.ticketStatus === 'REVIEWING' && ticket.ticketType === 'DHIT').length;
    const dhupcProgressTicketsCount = tickets.filter(ticket => ticket.ticketStatus === 'PROGRESS' && ticket.ticketType === 'DHUPC II').length;
    const dhitProgressTicketsCount = tickets.filter(ticket => ticket.ticketStatus === 'PROGRESS' && ticket.ticketType === 'DHIT').length;
    const dhupcRevertedTicketsCount = tickets.filter(ticket => ticket.ticketStatus === 'REVERTED' && ticket.ticketType === 'DHUPC II').length;
    const dhitRevertedTicketsCount = tickets.filter(ticket => ticket.ticketStatus === 'REVERTED' && ticket.ticketType === 'DHIT').length;
    const dhuptClosedTicketsCount = tickets.filter(ticket => ticket.ticketStatus === 'CLOSED' && ticket.ticketType === 'DHUPC II').length;
    const dhitClosedTicketsCount = tickets.filter(ticket => ticket.ticketStatus === 'CLOSED' && ticket.ticketType === 'DHIT').length;
    const dhupcUrgentTicketsCount = tickets.filter(ticket => ticket.ticketPriority === 'URGENT' && ticket.ticketStatus !== 'CLOSED' && ticket.ticketType === 'DHUPC II').length;
    const dhitUrgentTicketsCount = tickets.filter(ticket => ticket.ticketPriority === 'URGENT' && ticket.ticketStatus !== 'CLOSED' && ticket.ticketType === 'DHIT').length;

    return (
        <>
            {ticketType === 'PROGRESS' ? (
                <Button variant='primary' className='w-100 h-100'>
                    <p>TICKETS IN PROGRESS</p><hr />
                    <Row>
                        <Col>
                            <h1>{dhupcProgressTicketsCount}</h1>
                            <p>DHUPC II</p>
                        </Col>
                        <Col>
                            <h1>{dhitProgressTicketsCount}</h1>
                            <p>DHIT</p>
                        </Col>
                    </Row>
                </Button>
            ) : null}
            {ticketType === 'CLOSED' ? (
                <Button variant='success' className='w-100 h-100'>
                    <p>CLOSED TICKETS</p><hr />
                    <Row>
                        <Col>
                            <h1>{dhuptClosedTicketsCount}</h1>
                            <p>DHUPC II</p>
                        </Col>
                        <Col>
                            <h1>{dhitClosedTicketsCount}</h1>
                            <p>DHIT</p>
                        </Col>
                    </Row>
                </Button>
            ) : null}
            {ticketType === 'REVIEWING' ? (
                <Button variant='secondary' className='w-100 h-100'>
                    <p>TICKETS WAITING FOR REVIEW</p><hr />
                    <Row>
                        <Col>
                            <h1>{dhupcReviewingTicketsCount}</h1>
                            <p>DHUPC II</p>
                        </Col>
                        <Col>
                            <h1>{dhitReviewingTicketsCount}</h1>
                            <p>DHIT</p>
                        </Col>
                    </Row>
                </Button>
            ) : null}
            {ticketType === 'REVERTED' ? (
                <Button variant='dark' className='w-100 h-100'>
                    <p>TICKETS REVERTED BY OFFICER</p><hr />
                    <Row>
                        <Col>
                            <h1>{dhupcRevertedTicketsCount}</h1>
                            <p>DHUPC II</p>
                        </Col>
                        <Col>
                            <h1>{dhitRevertedTicketsCount}</h1>
                            <p>DHIT</p>
                        </Col>
                    </Row>
                </Button>
            ) : null}
            {ticketType === 'URGENT' ? (
                <Button variant='danger' className='w-100 h-100'>
                    <p>URGENT TICKETS</p><hr />
                    <Row>
                        <Col>
                            <h1>{dhupcUrgentTicketsCount}</h1>
                            <p>DHUPC II</p>
                        </Col>
                        <Col>
                            <h1>{dhitUrgentTicketsCount}</h1>
                            <p>DHIT</p>
                        </Col>
                    </Row>
                </Button>
            ) : null}
        </>
    )
}
