import React, { useState, useEffect, Suspense } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import CommonNavbar from '../components/common/CommonNavbar'
import TicketTable from '../components/cards/TicketTable'
import { SampleTicketsProvider } from '../contextapi/complainantContext'
import { getApiResponse } from '../utils/apiPromise'
import Loading from '../components/common/Loading'
import { useUserDetails } from '../contextapi/userDetails'

const complainantDashboardApi = process.env.REACT_APP_API_COMPLAINANT_DASHBOARD

export default function ComplainantDashboard() {
  const [validate, setValidate] = useState(false); // To validate the user from at server side : getRequest
  const { setUserDetails } = useUserDetails()
  // const [userDetails, setUserDetails] = useState({}) // Contains user Details : getRequest
  const authToken = sessionStorage.getItem('authToken') // Fetch AuthToken
  const [fetchUserApi, setFetchUserApi] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    setFetchUserApi(true)
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchUser = await getApiResponse(complainantDashboardApi, authToken)
        setUserDetails(fetchUser.userDetails)
        setValidate(true)
      } catch (error) {
        alert(error);
        navigate('/');
      }
    }
    if (fetchUserApi) {
      fetchData()
    }
  }, [authToken, fetchUserApi, navigate, setUserDetails])


  return (
    <>
      <Row>
        <CommonNavbar />
      </Row>

      {validate ? (
        <>
          <Suspense fallback={<Loading />}>
            {authToken ? (
              <Row>
                {/* User Details */}
                {/* <Col md={2} className='text-center p-4 bg-dark text-white'>
                <ProfileCard userRoleId={userDetails.user_role_id} userDetails={userDetails} />
              </Col> */}

                <Col>
                  <SampleTicketsProvider>
                    <TicketTable />
                  </SampleTicketsProvider>
                </Col>
              </Row >
            ) : null}
          </Suspense>
        </>
      ) : null}
    </>
  )
}
