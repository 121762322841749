import React from 'react'
import { Button } from 'react-bootstrap'
import { useAdminTickets } from '../../../contextapi/adminContext'


export default function AdminTicketCountCard(props) {
    const { tickets } = useAdminTickets();

    const openTickets = tickets.filter(ticket => ticket.ticketStatus === 'OPEN');
    const openTicketsCount = openTickets.length
    const reviewingTickets = tickets.filter(ticket => ticket.ticketStatus === 'REVIEWING');
    const reviewingTicketsCount = reviewingTickets.length
    const progressTickets = tickets.filter(ticket => ticket.ticketStatus === 'PROGRESS');
    const progressTicketsCount = progressTickets.length
    const closedTickets = tickets.filter(ticket => ticket.ticketStatus === 'CLOSED');
    const closedTicketsCount = closedTickets.length
    const urgentTickets = tickets.filter(ticket => ticket.ticketPriority === 'URGENT' && ticket.ticketStatus !== 'CLOSED');
    const urgentTicketsCount = urgentTickets.length
    return (
        <>
            {props.type === 'URGENT' ? (
                <Button variant='danger' className='w-100 h-100'>
                    <h1>{urgentTicketsCount}</h1>
                    <p>URGENT TICKETS</p>
                </Button>
            ) : null}
            {props.type === 'OPEN' ? (
                <Button variant='dark' className='w-100 h-100'>
                    <h1>{openTicketsCount}</h1>
                    <p>UNASSIGNED TICKETS</p>
                </Button>
            ) : null}
            {props.type === 'PROGRESS' ? (
                <Button variant='primary' className='w-100 h-100'>
                    <h1>{progressTicketsCount}</h1>
                    <p>ASSIGNED TICKETS</p>
                </Button>
            ) : null}
            {props.type === 'CLOSED' ? (
                <Button variant='danger' className='w-100 h-100'>
                    <h1>{closedTicketsCount}</h1>
                    <p>CLOSED TICKETS</p>
                </Button>
            ) : null}
            {props.type === 'REVIEWING' ? (
                <Button variant='secondary' className='w-100 h-100'>
                    <h1>{reviewingTicketsCount}</h1>
                    <p>TICKETS WAITING FOR REVIEW</p>
                </Button>
            ) : null}
        </>
    )
}
