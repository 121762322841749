import React from 'react'
import { Route } from 'react-router-dom'
import OfficerDashboard from '../views/officer/OfficerDashboard'
import OfficerAllTickets from '../views/officer/OfficerAllTickets'
import OfficerProgressTickets from '../views/officer/OfficerProgressTickets'

export default function OfficerRoutes() {
    return (
        <>
            <Route exact path="/officer/dashboard" element={<OfficerDashboard />} />
            <Route
                exact
                path="/officer/tickets/all"
                element={<OfficerAllTickets />}
            />
            <Route
                exact
                path="/officer/tickets/progress"
                element={<OfficerProgressTickets />}
            />
        </>
    )
}
