import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import CommonNavbar from '../../components/common/CommonNavbar'
import SideMenu from '../../components/common/SideMenu'
import { controllerSideMenu } from '../../assets/sideMenuTabs'
import { getApiResponse, postApiResponse } from '../../utils/apiPromise'
import { ticketDetailedStructure } from '../../assets/dataStructures/ticketsStructure'
import { DataGridWithMultipleButtons } from '../../components/datagrids/AgDataGrid'
import { Spinner } from "react-bootstrap"; // Using Bootstrap's Spinner for simplicity
import { FaTicketAlt } from 'react-icons/fa';
import { TiMessages } from "react-icons/ti";
import { IoAlertCircleSharp } from "react-icons/io5";
import { formatToJSDate } from "../../utils/formatDate";
import { MdAssignmentInd } from "react-icons/md";
import { ControllerTicketAssigner } from '../../modals/ControllerAssignTicket'
import { ControllerViewTicket } from '../../modals/ViewTicket'
import { developerDetailsStructure } from '../../assets/dataStructures/userStructures'
import { ErrorMessage } from '../../modals/ErrorMessage'
import NoDataAvailable from '../../components/common/NoDataAvailable'
import DataLoader from '../../components/common/DataLoader'

const fetchStatusWiseTicketsApi = process.env.REACT_APP_API_FETCH_TICKETS_STATUSWISE;
const fetchDropdownOptionsApi = process.env.REACT_APP_API_FETCH_USERS

export default function ControllerRevertedTickets() {
  const [fetchTickets, setFetchTickets] = useState(false);
  const [loading, setLoading] = useState(false); // Loader state
  const [revertedTickets, setRevertedTickets] = useState([ticketDetailedStructure]);
  const [dropdownOptions, fetchDropdownOptions] = useState(false)
  const [developerDetails, setDeveloperDetails] = useState([developerDetailsStructure])

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorTitle, setErrorTitle] = useState('');

  const [showAssignTicket, setShowAssignTicket] = useState(false)
  const [showViewTicket, setShowViewTicket] = useState(false)
  const [selectedTicket, setSelectedTicket] = useState(null); // State for the selected ticket



  const authToken = sessionStorage.getItem("authToken");
  const navigate = useNavigate();

  useEffect(() => {
    setFetchTickets(true);
    fetchDropdownOptions(true)
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Show loader
      try {
        const ticketResponse = await postApiResponse(fetchStatusWiseTicketsApi, {
          ticketStatus: ['reverted']
        }, authToken);
        const filteredTickets = ticketResponse.tickets.filter(ticket => ticket.ticketType === null)
        setRevertedTickets([...filteredTickets]);
      } catch (error) {
        setErrorTitle('Failed to Fetch Tickets');
        (error.message && setErrorMessage(error.message))
        setShowErrorModal(true);
      } finally {
        setLoading(false); // Hide loader 
      }
    };
    if (fetchTickets) {
      fetchData();
    }
  }, [authToken, fetchTickets]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dropdownResponse = await getApiResponse(fetchDropdownOptionsApi, authToken)
        setDeveloperDetails([...dropdownResponse.developers])
      } catch (error) {
        setErrorTitle('Failed to Fetch Developers');
        (error.message && setErrorMessage(error.message))
        setShowErrorModal(true);
      }
    }
    if (dropdownOptions) {
      fetchData()
    }
  }, [dropdownOptions]);

  const handleCloseErrorModal = () => {
    setShowErrorModal(false)
    navigate('/controller/dashboard')
  };

  const buttonsProps =
    [
      {
        variant: 'success',
        text: <MdAssignmentInd />,
        tooltip: "Assign Officer", // Hover text
        onClick: (params) => {
          setSelectedTicket(params.data); // Set the selected ticket
          setShowViewTicket(false); // Close the modal
          setShowAssignTicket(true); // Open the modal
        }
      },
      {
        variant: 'warning',
        text: <FaTicketAlt />,
        tooltip: "View Ticket", // Hover text
        onClick: (params) => {
          setSelectedTicket(params.data); // Set the selected ticket
          setShowAssignTicket(false); // Close the modal
          setShowViewTicket(true); // Open the modal
        }
      },
      {
        variant: 'primary',
        text: <TiMessages />,
        tooltip: "View Trail", // Hover text
        onClick: (params) => {
          navigate('/tickettrail', { state: { ticketId: params.data.ticketId } });
        }
      }
    ]

  const columns = [
    { field: 'ticketId', headerName: 'Ticket ID' },
    { field: 'issueCategory', headerName: 'Issue Type' },
    { field: 'openDate', headerName: 'Generated On', valueFormatter: (params) => formatToJSDate(params.value) },
    { field: 'updateDate', headerName: 'Reverted On', valueFormatter: (params) => formatToJSDate(params.value) },
    { field: 'remarkBy', headerName: 'Reverted By' },
  ];


  return (
    <>
      <Row>
        <CommonNavbar />
      </Row>

      <Row>
        <Col md={2}>
          <SideMenu menu={controllerSideMenu} theme="dark" />
        </Col>

        <Col md={10}>
          <h4 className="mt-2">Reverted Tickets</h4>
          <hr />
          {loading ? <DataLoader /> : (
            <>
              {revertedTickets.length > 0 ? (
                <DataGridWithMultipleButtons
                  data={revertedTickets}
                  columns={columns}
                  pagination="true"
                  rowsPerPage={20}
                  rowsPerPageSelector={[20, 50, 100]}
                  buttonsProps={buttonsProps}
                  theme="light"
                  headerBgClass="bg-success text-white"
                />
              ) : (<NoDataAvailable />)}
            </>
          )}
        </Col>
      </Row>

      <ControllerTicketAssigner showAssignTicket={showAssignTicket} setShowAssignTicket={setShowAssignTicket} ticketDetails={selectedTicket} developers={developerDetails} />
      <ControllerViewTicket showViewTicket={showViewTicket} setShowViewTicket={setShowViewTicket} ticketDetails={selectedTicket} />
      <ErrorMessage show={showErrorModal} handleClose={handleCloseErrorModal} errorTitle={errorTitle} errorMessage={errorMessage} />

    </>
  )
}
