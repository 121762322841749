const plasticSubcategories = [
    {
        "subCatId": 1,
        "subCat": "PAN not accepted",
        "catId": 1
    },
    {
        "subCatId": 2,
        "subCat": "CIN not accepted",
        "catId": 1
    },
    {
        "subCatId": 3,
        "subCat": "Document uploading error",
        "catId": 1
    },
    {
        "subCatId": 4,
        "subCat": "OTP not received on SMS",
        "catId": 2
    },
    {
        "subCatId": 5,
        "subCat": "OTP not received on email",
        "catId": 2
    },
    {
        "subCatId": 6,
        "subCat": "Error: “Error while logging in”",
        "catId": 2
    },
    {
        "subCatId": 7,
        "subCat": "Error: “Not a PIBO user”",
        "catId": 2
    },
    {
        "subCatId": 8,
        "subCat": "Legal Name/Trade Name not matching",
        "catId": 3
    },
    {
        "subCatId": 9,
        "subCat": "GST not accepted.",
        "catId": 3
    },
    {
        "subCatId": 10,
        "subCat": "Document uploading error (problem with extension) / Error: “document you uploaded is not a valid PDF”",
        "catId": 3
    },
    {
        "subCatId": 11,
        "subCat": "Consent cannot be fetched",
        "catId": 3
    },
    {
        "subCatId": 12,
        "subCat": "Consent fetching incorrect details",
        "catId": 3
    },
    {
        "subCatId": 13,
        "subCat": "Data mismatch error message",
        "catId": 3
    },
    {
        "subCatId": 14,
        "subCat": "Incorrect dropdown in procurement/sales section",
        "catId": 3
    },
    {
        "subCatId": 15,
        "subCat": "Not able to submit procurement/sales details",
        "catId": 3
    },
    {
        "subCatId": 16,
        "subCat": "Error in Part A | B | C | D",
        "catId": 3
    },
    {
        "subCatId": 17,
        "subCat": "Incorrect EPR Target visible",
        "catId": 3
    },
    {
        "subCatId": 18,
        "subCat": "Payment error/ Multiple payments",
        "catId": 3
    },
    {
        "subCatId": 19,
        "subCat": "Seeking amendment in application (Change in Address, or Email, or Data etc.)",
        "catId": 4
    },
    {
        "subCatId": 20,
        "subCat": "Delay in processing application",
        "catId": 4
    },
    {
        "subCatId": 21,
        "subCat": "Application rejected without giving reason/remarks",
        "catId": 4
    },
    {
        "subCatId": 22,
        "subCat": "Unable to edit rejected application for further resubmission",
        "catId": 4
    },
    {
        "subCatId": 23,
        "subCat": "Want to edit approved application",
        "catId": 4
    },
    {
        "subCatId": 24,
        "subCat": "Want to surrender approved application",
        "catId": 4
    },
    {
        "subCatId": 25,
        "subCat": "AR filing payment error",
        "catId": 4
    },
    {
        "subCatId": 26,
        "subCat": "Certificate validity expired",
        "catId": 4
    },
    {
        "subCatId": 27,
        "subCat": "Can’t find option to apply for renewal",
        "catId": 4
    },
    {
        "subCatId": 28,
        "subCat": "Certificate No. not visible in generated registration certificate",
        "catId": 4
    },
    {
        "subCatId": 29,
        "subCat": "BOT (Bulk Upload Tool) crashing",
        "catId": 5
    },
    {
        "subCatId": 30,
        "subCat": "BOT made multiple entries",
        "catId": 5
    },
    {
        "subCatId": 31,
        "subCat": "Wallet section not visible in application",
        "catId": 5
    },
    {
        "subCatId": 32,
        "subCat": "Dashboard showing incorrect target/registration year",
        "catId": 5
    },
    {
        "subCatId": 33,
        "subCat": "Error in production section: 'Total Quantity of Product Produced in Production Details is more than the Product Capacity specified in application Available Qty'",
        "catId": 6
    },
    {
        "subCatId": 34,
        "subCat": "Incorrect Conversion Factor",
        "catId": 6
    },
    {
        "subCatId": 35,
        "subCat": "Edit Procurement/Production/Sales details",
        "catId": 6
    },
    {
        "subCatId": 36,
        "subCat": "Production data not reflected in sales inventory",
        "catId": 6
    },
    {
        "subCatId": 37,
        "subCat": "Particular Denomination not available",
        "catId": 7
    },
    {
        "subCatId": 38,
        "subCat": "Certificate transfer failed",
        "catId": 7
    },
    {
        "subCatId": 39,
        "subCat": "Certificate Generation Potential not visible in wallet",
        "catId": 7
    },
    {
        "subCatId": 40,
        "subCat": "Incorrect EPR Target in AR for current FY",
        "catId": 8
    },
    {
        "subCatId": 41,
        "subCat": "Incorrect EPR Target in AR for upcoming FY",
        "catId": 8
    },
    {
        "subCatId": 42,
        "subCat": "Error uploading sales/export details during AR filing",
        "catId": 8
    },
    {
        "subCatId": 43,
        "subCat": "Add to AR option not working",
        "catId": 8
    },
    {
        "subCatId": 44,
        "subCat": "Offset option not working",
        "catId": 8
    },
    {
        "subCatId": 45,
        "subCat": "Submitted AR before adding credits to AR",
        "catId": 8
    },
    {
        "subCatId": 46,
        "subCat": "EC still visible even after filing AR",
        "catId": 8
    },
    {
        "subCatId": 47,
        "subCat": "Want to Edit submitted AR",
        "catId": 8
    },
    {
        "subCatId": 48,
        "subCat": "Others",
        "catId": 1
    },
    {
        "subCatId": 49,
        "subCat": "Others",
        "catId": 2
    },
    {
        "subCatId": 50,
        "subCat": "Others",
        "catId": 3
    },
    {
        "subCatId": 51,
        "subCat": "Others",
        "catId": 4
    },
    {
        "subCatId": 52,
        "subCat": "Others",
        "catId": 5
    },
    {
        "subCatId": 53,
        "subCat": "Others",
        "catId": 6
    },
    {
        "subCatId": 54,
        "subCat": "Others",
        "catId": 7
    },
    {
        "subCatId": 55,
        "subCat": "Others",
        "catId": 8
    },
    {
        "subCatId": 0,
        "subCat": "Others",
        "catId": 9
    }
]

export default plasticSubcategories