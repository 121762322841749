const roles = [
    {
        label: 'Admin',
        value: 'admin'
    },
    {
        label: 'Help Desk',
        value: 'controller'
    },
    {
        label: 'Division Head',
        value: 'handler'
    },
    {
        label: 'Officer',
        value: 'officer'
    },
    {
        label: 'Complainant',
        value: 'complainant'
    }
]

export default roles;