import React from "react";
import { Nav, Navbar, Row } from "react-bootstrap";
import './sideMenu.css'
import { Link } from "react-router-dom";

export default function SideMenu({ userDetails, menu, userRole, theme }) {
  const currentPath = window.location.pathname;
  const sections = [...new Set(menu.map((item) => item.section))];
  const textTheme = theme === "dark" ? "light" : "dark";

  return (
    <>
      <Navbar
        className="py-4 navbar-full-height"
        bg={theme}
        variant={theme}
      >
        {userDetails && (
          <Row className="m-1 mt-4 mb-4">
            <div className="d-flex justify-content-between p-1">
              <p className="mb-0">{userDetails.userName}</p>
            </div>
            <hr className="m-0" />

            <div className="d-flex justify-content-between p-1">
              <p className="mb-0">{userDetails.emailId}</p>
            </div>
            <hr className="m-0" />

            <div className="d-flex justify-content-between p-1">
              <p className="mb-0">{userDetails.phone}</p>
            </div>
            <hr className="m-0" />
          </Row>
        )}

        <h4 className={`text-${textTheme}`}>{userRole}</h4>
        <Nav defaultActiveKey="/" className="flex-column">
          {sections.map((sectionName) => (
            <>
              <h6 className={`mt-3 text-${textTheme}`}>{sectionName}</h6>
              {menu
                .filter((item) => item.section === sectionName)
                .map((tab, index) => (
                  <Link
                    to={tab.path}
                    className={`nav-link ${
                      currentPath === tab.path ? "active" : ""
                    } ${theme === "dark" ? "dark" : "light"}`}
                  >
                    {tab.icon} <span>{tab.title}</span>
                  </Link>
                ))}
            </>
          ))}
        </Nav>
      </Navbar>
    </>
  );
}
