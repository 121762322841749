import React, { useEffect, useState } from 'react'
import { ticketDetailedStructure } from '../../assets/dataStructures/ticketsStructure';
import { useLocation, useNavigate } from 'react-router-dom';
import { postApiResponse } from '../../utils/apiPromise';
import { ErrorMessage } from '../../modals/ErrorMessage';
import { DataGridWithToolbar } from '../../components/datagrids/AgDataGrid';
import DataLoader from '../../components/common/DataLoader';
import NoDataAvailable from '../../components/common/NoDataAvailable';
import SideMenu from '../../components/common/SideMenu';
import CommonNavbar from '../../components/common/CommonNavbar';
import { Col, Row } from 'react-bootstrap';
import { formatToJSDate } from '../../utils/formatDate';
import { handlerSideMenu } from '../../assets/sideMenuTabs';

const fetchStatusWiseTicketsApi = process.env.REACT_APP_API_FETCH_TICKETS_STATUSWISE;

export default function HandlerFilteredTickets() {

    const location = useLocation();
    const filteredKey = location.state?.filteredKey;
    const officerName = location.state?.officerName;

    const [fetchTickets, setFetchTickets] = useState(false);
    const [loading, setLoading] = useState(false); // Loader state
    const [filteredTickets, setFilteredTickets] = useState([ticketDetailedStructure]);

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorTitle, setErrorTitle] = useState('');

    const [showViewTicket, setShowViewTicket] = useState(false)
    const [selectedTicket, setSelectedTicket] = useState(null); // State for the selected ticket

    const authToken = sessionStorage.getItem("authToken");
    const navigate = useNavigate();

    useEffect(() => {
        setFetchTickets(true);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true); // Show loader
            try {
                // const ticketResponse = await postApiResponse(fetchStatusWiseTicketsApi, {
                //     ticketStatus: ['progress', 'reverted', 'reviewing', 'closed']
                // }, authToken);
                // setAllTickets([...ticketResponse.tickets]);
            } catch (error) {
                setErrorTitle('Failed to Fetch Tickets');
                (error.message && setErrorMessage(error.message))
                setShowErrorModal(true);
            } finally {
                setLoading(false); // Hide loader
            }
        };
        if (fetchTickets) {
            fetchData();
        }
    }, [authToken, fetchTickets]);

    const handleCloseErrorModal = () => {
        setShowErrorModal(false)
    };

    const columns = [
        { field: 'ticketId', headerName: 'Ticket ID' },
        { field: 'openDate', headerName: 'Generated On', valueFormatter: (params) => formatToJSDate(params.value) },
        { field: 'issueCategory', headerName: 'Issue Type' },
        { field: 'issueSubcategory', headerName: 'Issue Sub Type' },
        { field: 'companyName', headerName: 'Company Name' },
        { field: 'complainantName', headerName: 'Complainant Name' }
    ];

    return (
        <>
            <Row>
                <CommonNavbar />
            </Row>

            <Row>
                <Col md={2}>
                    <SideMenu menu={handlerSideMenu} theme="dark" />
                </Col>

                <Col md={10}>
                    <h4 className="mt-2">{filteredKey} - {officerName} </h4><hr />
                    {loading ? <DataLoader /> : (
                        <>
                            {filteredTickets.length > 0 ? (
                                <DataGridWithToolbar
                                    data={filteredTickets}
                                    columns={columns}
                                    pagination="true"
                                    rowsPerPage={20}
                                    rowsPerPageSelector={[20, 50, 100]}
                                    theme="light"
                                />
                            ) : <NoDataAvailable />}
                        </>
                    )}
                </Col>
            </Row>

            <ErrorMessage show={showErrorModal} handleClose={handleCloseErrorModal} errorTitle={errorTitle} errorMessage={errorMessage} />

        </>
    )
}
