import React, { useState, createContext, useContext } from "react";

// Create a context for ticket Updates
const UserDetailsContext = createContext()
export const UserDetailsProvider = ({ children }) => {
    const [userDetails, setUserDetails] = useState({
        userId: null,
        emailId: '',
        phone: '',
        userStatus: '',
        userRole: '',
        userRoleId: null
    });
    return (
        <UserDetailsContext.Provider value={{ userDetails, setUserDetails }}>
            {children}
        </UserDetailsContext.Provider>
    )
}
export const useUserDetails = () => useContext(UserDetailsContext);