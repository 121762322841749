import React, { useEffect, useState } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import CommonNavbar from '../../components/common/CommonNavbar'
import SideMenu from '../../components/common/SideMenu'
import { officerSideMenu } from '../../assets/sideMenuTabs'
import { postApiResponse } from '../../utils/apiPromise'
import { DataGridWithMultipleButtons } from '../../components/datagrids/AgDataGrid'
import { formatToJSDate } from '../../utils/formatDate'
import { ticketDetailedStructure } from '../../assets/dataStructures/ticketsStructure'
import { IoAlertCircleSharp } from 'react-icons/io5'
import { FaTicketAlt } from 'react-icons/fa';
import { AiOutlineFileDone } from "react-icons/ai";
import { TiMessages } from "react-icons/ti";
import { RiCustomerService2Fill } from "react-icons/ri";
import { ControllerViewTicket } from '../../modals/ViewTicket'
import { ErrorMessage, SuccessMessage } from '../../modals/ErrorMessage'
import PromptRemark from '../../modals/PromptRemark'
import DataLoader from '../../components/common/DataLoader'
import NoDataAvailable from '../../components/common/NoDataAvailable'



const fetchDeveloperTicketsApi = process.env.REACT_APP_API_FETCH_TICKETS_STATUSWISE
const revertDeveloperTicketApi = process.env.REACT_APP_API_REVERT_DEVELOPER_TICKET
const updateDeveloperTicketApi = process.env.REACT_APP_API_UPDATE_DEVELOPER_TICKETS


export default function OfficerProgressTickets() {

    // For Error Modal
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorTitle, setErrorTitle] = useState('');

    // For Success Modal
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [successTitle, setSuccessTitle] = useState('');

    const [fetchProgressTickets, setFetchProgressTickets] = useState(false)
    const [loading, setLoading] = useState(false); // Loader state
    const [progressTickets, setProgressTickets] = useState([ticketDetailedStructure])

    const [showViewTicket, setShowViewTicket] = useState(false)
    const [showPrompt, setShowPrompt] = useState(false)
    const [selectedTicket, setSelectedTicket] = useState(null); // State for the selected ticket

    const authToken = sessionStorage.getItem('authToken')
    const navigate = useNavigate()

    useEffect(() => {
        setFetchProgressTickets(true)
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true); // Show loader
            try {
                const progressTicketsResponse = await postApiResponse(fetchDeveloperTicketsApi, {
                    ticketStatus: ['progress']
                }, authToken)
                setProgressTickets([...progressTicketsResponse.tickets])
            } catch (error) {
                setErrorTitle('Failed to Fetch Tickets');
                (error.message && setErrorMessage(error.message))
                setShowErrorModal(true);
            } finally {
                setLoading(false); // Hide loader
            }
        }
        if (fetchProgressTickets) {
            fetchData()
        }
    }, [fetchProgressTickets])

    const handleCloseErrorModal = () => {
        setShowErrorModal(false)
        navigate('/officer/dashboard')
    };

    const handleCloseSuccessModal = () => {
        setShowSuccessModal(false);
        window.location.reload()
    };

    const buttonsProps =
        [
            {
                variant: 'success',
                text: <AiOutlineFileDone />,
                tooltip: "Close the Ticket", // Hover text
                onClick: (params) => {
                    setSelectedTicket(params.data); // Set the selected ticket
                    setShowPrompt(true)
                }
            },
            {
                variant: 'warning',
                text: <RiCustomerService2Fill />,
                tooltip: "Send Back to Help Desk", // Hover text
                onClick: async (params) => {
                    try {
                        const resp = await postApiResponse(revertDeveloperTicketApi, {
                            ticketId: params.data.ticketId
                        }, authToken)
                        setSuccessTitle('Successfully Reverted');
                        setSuccessMessage(resp.message);
                        setShowSuccessModal(true);
                    } catch (error) {
                        setErrorTitle('Failed to Revert Ticket');
                        (error.message && setErrorMessage(error.message))
                        setShowErrorModal(true);
                    }
                }
            },
            {
                variant: 'secondary',
                text: <FaTicketAlt />,
                tooltip: "View Ticket", // Hover text
                onClick: (params) => {
                    setSelectedTicket(params.data); // Set the selected ticket
                    setShowViewTicket(true); // Open the modal
                }
            },
            {
                variant: 'primary',
                text: <TiMessages />,
                tooltip: "View Trail", // Hover text
                onClick: (params) => {
                    navigate('/tickettrail', { state: { ticketId: params.data.ticketId } });
                }
            }
        ]

    const columns = [
        { field: 'ticketId', headerName: 'Ticket ID' },
        { field: 'openDate', headerName: 'Generated On', valueFormatter: (params) => formatToJSDate(params.value), filter: false },
        { field: 'issueCategory', headerName: 'Issue Type' },
        { field: 'complainantName', headerName: 'Complainant Name' },
        { field: 'complainantRole', headerName: 'Complainant Role' }
    ];

    return (
        <>
            <Row>
                <CommonNavbar />
            </Row>

            <Row>
                <Col md={2}>
                    <SideMenu menu={officerSideMenu} theme="dark" />
                </Col>

                <Col md={10}>
                    <h4 className="mt-2">In-Progress Tickets</h4>
                    <hr />
                    {loading ? <DataLoader /> : (
                        <>
                            {progressTickets.length > 0 ? (
                                <DataGridWithMultipleButtons
                                    data={progressTickets}
                                    pagination="true"
                                    columns={columns}
                                    rowsPerPage={20}
                                    rowsPerPageSelector={[20, 50, 100]}
                                    buttonsProps={buttonsProps}
                                    theme="light"
                                    headerBgClass="bg-success text-white"
                                />
                            ) : <NoDataAvailable />}
                        </>
                    )}
                </Col>
            </Row>

            <ControllerViewTicket showViewTicket={showViewTicket} setShowViewTicket={setShowViewTicket} ticketDetails={selectedTicket} />
            <PromptRemark showPrompt={showPrompt} setShowPrompt={setShowPrompt} ticketDetails={selectedTicket} postApi={updateDeveloperTicketApi} />
            <ErrorMessage show={showErrorModal} handleClose={handleCloseErrorModal} errorTitle={errorTitle} errorMessage={errorMessage} />
            <SuccessMessage show={showSuccessModal} handleClose={handleCloseSuccessModal} successTitle={successTitle} successMessage={successMessage} />

        </>
    )
}
