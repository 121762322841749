import React, { useEffect, useState } from 'react'
import { PieChartComponent } from '../../graphs/PieChartComponents';
import { Form } from 'react-bootstrap';
import { convertDuration } from '../../../utils/convertText';

export default function HandlerTicketsDurationPieChart({ tickets }) {
    const pendingTicketsCount = timeDiffPendingTicketsCounts(tickets)

    const [selectDuration, setSelectDuration] = useState('afterTodayCount')
    const [restructuredData, setRestructuredData] = useState(
        restructureDataForPieChart(pendingTicketsCount, 'userName', selectDuration)
    );

    useEffect(() => {
        // Restructure data whenever the selected duration or tickets change
        setRestructuredData(restructureDataForPieChart(pendingTicketsCount, 'userName', selectDuration));
    }, [selectDuration, tickets]);

    return (
        <>
            <Form>
                <Form.Group>
                    <Form.Select
                        value={selectDuration}
                        onChange={(e) => setSelectDuration(e.target.value)}
                    >
                        <option value="">Select the Option...</option>
                        {Object.keys(pendingTicketsCount[0]?.ticketDurations || {}).map((key) => (
                            <option key={key} value={key}>
                                {convertDuration(key)}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
            </Form>
            <PieChartComponent
                data={restructuredData}
                labelKey="userName"
                countKey="count"
                durationKey={selectDuration}
                variant='success'
            />
        </>
    )
}

function restructureDataForPieChart(data, labelKey, durationKey) {
    return data
        .filter(item => item[labelKey] !== null) // Filter out items with null labelKey
        .map(item => ({
            [labelKey]: item[labelKey],
            count: item.ticketDurations[durationKey]
        }));
}



const timeDiffPendingTicketsCounts = (tickets, currentDate = new Date()) => {
    const moreThanTimeFrames = {
        after1YearCount: 365,
        after6MonthsCount: 180,
        after3MonthsCount: 90,
        after1MonthCount: 30,
        after7DaysCount: 7,
        afterTodayCount: 0,
    };

    const groupByDeveloper = {};

    tickets.forEach((ticket) => {
        if (ticket.ticketStatus !== "PROGRESS") return;

        const developerName = ticket.developerName;
        const updateDate = new Date(ticket.updateDate);
        const daysDiff = Math.floor((currentDate - updateDate) / (1000 * 60 * 60 * 24));

        if (!groupByDeveloper[developerName]) {
            groupByDeveloper[developerName] = {
                userId: null, // You can assign a userId if needed
                userName: developerName,
                ticketDurations: {
                    afterTodayCount: 0,
                    after7DaysCount: 0,
                    after1MonthCount: 0,
                    after3MonthsCount: 0,
                    after6MonthsCount: 0,
                    after1YearCount: 0,
                },
            };
        }

        const ticketDurations = groupByDeveloper[developerName].ticketDurations;

        for (const [key, days] of Object.entries(moreThanTimeFrames)) {
            if (daysDiff <= 0) {
                break;
            } else if (daysDiff >= days) {
                ticketDurations[key]++;
                break;
            }
        }
    });
    return Object.values(groupByDeveloper);
};