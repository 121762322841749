import React, {useState} from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import LodgeComplaint from '../forms/LodgeComplaint'
import workflow from '../assets/images/workflow.png'
import CommonNavbar from '../components/common/CommonNavbar'
import CreateComplainantPrompt from '../modals/CreateComplainantPrompt'

export default function Homescreen() {
  const [showRegisterComplainant, setShowRegisterComplainant] = useState(false)

  return (
    <>
      <Row>
        <CommonNavbar />
      </Row>
      <Row>
        <Col md={8} className='p-4 bg-light'>
          <div className='d-flex justify-content-between'>
          <h2>Lodge your Complaint</h2>
          <Button className='align-self-end mb-2 btn btn-warning' onClick={()=>setShowRegisterComplainant(true)}>First Time Complainant ?</Button>
          </div>
          <hr />
          <LodgeComplaint />
        </Col>
        <Col md={4}>
          <img src={workflow} alt="workflow" style={{ width: '30vw' }} />
        </Col>
      </Row>
      <CreateComplainantPrompt showRegisterComplainant={showRegisterComplainant} setShowRegisterComplainant={setShowRegisterComplainant}  />
    </>
  )
}


// style={{backgroundColor: '#31ADFF'}}