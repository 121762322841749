import React from 'react'
import { generatePendingTicketsCounts, generateTicketsLastDurationCounts } from '../../../assets/dataStructures/ticketCountsStructure'
import { BasicDataGrid } from '../../datagrids/AgDataGrid'
import { useNavigate } from 'react-router-dom';

export function HandlerPendingTicketsDurationWise({ tickets }) {
    const result = timeDiffCounts(tickets).filter(item => item.userName !== null);
    const navigate = useNavigate()

    const columns = [
        { field: 'userName', headerName: 'User Name' },
        { field: 'ticketDurations.after7DaysCount', headerName: 'More than 7 Days', filter: false },
        { field: 'ticketDurations.after1MonthCount', headerName: 'More than 1 Month', filter: false },
        { field: 'ticketDurations.after3MonthsCount', headerName: 'More than 3 Months', filter: false },
        { field: 'ticketDurations.after6MonthsCount', headerName: 'More than 6 Months', filter: false },
        { field: 'ticketDurations.after1YearCount', headerName: 'More than 1 Year', filter: false }
    ];

    const handleCellClick = (params) => {
        const headerName = params.colDef.headerName; // Get the header name of the clicked column
        const officerName = params.data?.userName || 'Unknown'; // Fallback for undefined userName
        const officerId = params.data?.userId || 'Unknown'; // Fallback for undefined userName
        // navigate('/handler/tickets/filtered', {
        //     state: {
        //         filteredKey: headerName,
        //         officerId: officerId,
        //         officerName: officerName
        //     }
        // });
    };


    return (
        <>
            <BasicDataGrid
                data={result}
                columns={columns}
                theme='light'
                onCellClick={handleCellClick}
            />
        </>
    )
}

export function HandlerTicketsLastDurationWise() {
    const ticketsLastDurationCounts = generateTicketsLastDurationCounts(5)
    const columns = [
        { field: 'userName', headerName: 'User Name' },
        { field: 'ticketDurations.todayCount', headerName: 'Today Count' },
        { field: 'ticketDurations.last7DaysCount', headerName: 'Last 7 Days Count' },
        { field: 'ticketDurations.last1MonthCount', headerName: 'Last 1 Month Count' },
        { field: 'ticketDurations.last3MonthsCount', headerName: 'Last 3 Months Count' },
        { field: 'ticketDurations.last6MonthsCount', headerName: 'Last 6 Months Count' },
        { field: 'ticketDurations.last1YearCount', headerName: 'Last 1 Year Count' }
    ];

    return (
        <>
            <BasicDataGrid
                data={ticketsLastDurationCounts}
                columns={columns}
                theme='light' />
        </>
    )
}


const timeDiffCounts = (tickets, currentDate = new Date()) => {
    const moreThanTimeFrames = {
        after1YearCount: 365,
        after6MonthsCount: 180,
        after3MonthsCount: 90,
        after1MonthCount: 30,
        after7DaysCount: 7,
        afterTodayCount: 0,
    };

    const groupByDeveloper = {};

    tickets.forEach((ticket) => {
        if (ticket.ticketStatus !== "PROGRESS") return;

        const developerName = ticket.developerName;
        const updateDate = new Date(ticket.updateDate);
        const daysDiff = Math.floor((currentDate - updateDate) / (1000 * 60 * 60 * 24));

        if (!groupByDeveloper[developerName]) {
            groupByDeveloper[developerName] = {
                userId: null, // You can assign a userId if needed
                userName: developerName,
                ticketDurations: {
                    afterTodayCount: 0,
                    after7DaysCount: 0,
                    after1MonthCount: 0,
                    after3MonthsCount: 0,
                    after6MonthsCount: 0,
                    after1YearCount: 0,
                },
            };
        }

        const ticketDurations = groupByDeveloper[developerName].ticketDurations;

        for (const [key, days] of Object.entries(moreThanTimeFrames)) {
            if (daysDiff <= 0) {
                break;
            } else if (daysDiff >= days) {
                ticketDurations[key]++;
                break;
            }
        }
    });
    return Object.values(groupByDeveloper);
};