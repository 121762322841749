import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import CommonNavbar from '../../components/common/CommonNavbar'
import SideMenu from '../../components/common/SideMenu'
import { handlerSideMenu } from '../../assets/sideMenuTabs'
import { ticketDetailedStructure } from '../../assets/dataStructures/ticketsStructure'
import { postApiResponse } from '../../utils/apiPromise'
import { formatToJSDate } from '../../utils/formatDate'
import { DataGridWithMultipleButtons } from '../../components/datagrids/AgDataGrid'
import { IoAlertCircleSharp } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
import { Spinner } from "react-bootstrap"; // Using Bootstrap's Spinner for simplicity
import { FaTicketAlt } from 'react-icons/fa';
import { AiOutlineFileDone } from "react-icons/ai";
import { TiMessages } from "react-icons/ti";
import { FaUserTag } from "react-icons/fa6";
import { ControllerViewTicket } from '../../modals/ViewTicket'
import PromptRemark from '../../modals/PromptRemark'
import { MdLocalActivity } from "react-icons/md";
import { ErrorMessage } from '../../modals/ErrorMessage'
import DataLoader from '../../components/common/DataLoader'
import NoDataAvailable from '../../components/common/NoDataAvailable'



const fetchStatusWiseTicketsApi = process.env.REACT_APP_API_FETCH_TICKETS_STATUSWISE;
const updateHandlerTicketApi = process.env.REACT_APP_API_UPDATE_HANDLER_TICKETS


export default function HandlerReviewingTickets() {

    const [fetchReviewingTickets, setFetchReviewingTickets] = useState(false)
    const [loading, setLoading] = useState(false); // Loader state
    const [reviewingTickets, setReviewingTickets] = useState([ticketDetailedStructure])

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorTitle, setErrorTitle] = useState('');

    const [showViewTicket, setShowViewTicket] = useState(false)
    const [showPrompt, setShowPrompt] = useState(false)
    const [selectedTicket, setSelectedTicket] = useState(null); // State for the selected ticket

    const authToken = sessionStorage.getItem('authToken')
    const navigate = useNavigate()

    useEffect(() => {
        setFetchReviewingTickets(true)
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true); // Show loader
            try {
                const reviewingTicketsResponse = await postApiResponse(fetchStatusWiseTicketsApi, {
                    ticketStatus: ['reviewing']
                }, authToken)
                setReviewingTickets([...reviewingTicketsResponse.tickets])
            } catch (error) {
                setErrorTitle('Failed to Fetch Tickets');
                (error.message && setErrorMessage(error.message))
                setShowErrorModal(true);
            } finally {
                setLoading(false); // Hide loader
            }
        }
        if (fetchReviewingTickets) {
            fetchData()
        }
    }, [fetchReviewingTickets])

    const handleCloseErrorModal = () => {
        setShowErrorModal(false);
    };

    const buttonsProps =
        [
            {
                variant: 'danger',
                text: <MdLocalActivity />,
                tooltip: "Take Action", // Hover text
                onClick: (params) => {
                    setSelectedTicket(params.data); // Set the selected ticket
                    setShowPrompt(true)
                }
            },
            // {
            //     variant: 'danger',
            //     text: <AiOutlineFileDone />,
            //     tooltip: "Close the Ticket", // Hover text
            //     onClick: (params) => {
            //         setSelectedTicket(params.data); // Set the selected ticket
            //         setShowPrompt(true)
            //     }
            // },
            // {
            //     variant: 'warning',
            //     text: <FaUserTag />,
            //     tooltip: "Send Back to Officer", // Hover text
            //     onClick: (params) => {
            //         setSelectedTicket(params.data); // Set the selected ticket
            //         setShowPrompt(true)
            //     }
            // },
            {
                variant: 'secondary',
                text: <FaTicketAlt />,
                tooltip: "View Ticket", // Hover text
                onClick: (params) => {
                    setSelectedTicket(params.data); // Set the selected ticket
                    setShowViewTicket(true); // Open the modal
                }
            },
            {
                variant: 'primary',
                text: <TiMessages />,
                tooltip: "View Trail", // Hover text
                onClick: (params) => {
                    navigate('/tickettrail', { state: { ticketId: params.data.ticketId } });
                }
            },
        ]

    const columns = [
        { field: 'ticketId', headerName: 'Ticket ID' },
        { field: 'openDate', headerName: 'Generated On', valueFormatter: (params) => formatToJSDate(params.value) },
        { field: 'updateDate', headerName: 'Recieved On', valueFormatter: (params) => formatToJSDate(params.value) },
        { field: 'issueCategory', headerName: 'Issue Type' },
        { field: 'issueSubcategory', headerName: 'Issue Sub Type' },
        { field: 'developerName', headerName: 'Assigned To' }
    ];

    return (
        <>
            <Row>
                <CommonNavbar />
            </Row>

            <Row>
                <Col md={2}>
                    <SideMenu menu={handlerSideMenu} theme="dark" />
                </Col>
                <Col md={10}>
                    <h4 className="mt-2">Tickets for Review</h4>
                    <hr />
                    {loading ? <DataLoader /> : (
                        <>
                            {reviewingTickets.length > 0 ? (
                                <DataGridWithMultipleButtons
                                    data={reviewingTickets}
                                    pagination="true"
                                    columns={columns}
                                    rowsPerPage={20}
                                    rowsPerPageSelector={[20, 50, 100]}
                                    buttonsProps={buttonsProps}
                                    theme="light"
                                    headerBgClass="bg-success text-white"
                                />
                            ) : <NoDataAvailable />}
                        </>
                    )}
                </Col>
            </Row>

            <ControllerViewTicket showViewTicket={showViewTicket} setShowViewTicket={setShowViewTicket} ticketDetails={selectedTicket} />
            <PromptRemark userRole='handler' showPrompt={showPrompt} setShowPrompt={setShowPrompt} ticketDetails={selectedTicket} postApi={updateHandlerTicketApi} />
            <ErrorMessage show={showErrorModal} handleClose={handleCloseErrorModal} errorTitle={errorTitle} errorMessage={errorMessage} />
        </>
    )
}
