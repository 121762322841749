import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import imageToString from '../utils/imageToString';
import { formatToJSDate } from '../utils/formatDate';
import { postApiResponse } from '../utils/apiPromise';

export default function PromptRemark({ userRole, setShowPrompt, showPrompt, ticketDetails, postApi }) {

    const [updateReview, setUpdateReview] = useState({
        ticketPriority: '',
        ticketStatus: '',
        remark: '',
        devScreenshots: null
    })

    useEffect(() => {
        setUpdateReview({
            ticketPriority: '',
            ticketStatus: '',
            remark: '',
            devScreenshots: null
        })
    }, [showPrompt])

    // For Error Modal
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorTitle, setErrorTitle] = useState('');

    const handleClose = () => {
        setShowPrompt(false)
    }


    const authToken = sessionStorage.getItem('authToken')

    const handlePdfUpload = async (event) => {
        const file = event.target.files[0]; // Get the selected file
        let fileName = file.name;
        const maxSize = 2 * 1024 * 1024; // 2MB in bytes

        // Rename the file to remove double extensions like .jpg.pdf or .jpeg.pdf
        const lastDotIndex = fileName.lastIndexOf('.');
        const extension = fileName.substring(lastDotIndex + 1).toLowerCase();

        if (extension !== 'pdf') {
            // Remove the double extension
            fileName = fileName.substring(0, lastDotIndex);
            const lastDotIndexNew = fileName.lastIndexOf('.');
            const newExtension = fileName.substring(lastDotIndexNew + 1).toLowerCase();
            if (newExtension !== 'pdf') {
                alert('Please select a PDF file.');
                event.target.value = ''; // Clear the file input
                return;
            }
        }

        // Check if file size exceeds 2MB
        if (file && file.size > maxSize) {
            alert('File size exceeds 2MB. Please select a smaller file.');
            event.target.value = null; // Reset the file input
            return;
        }

        try {
            const devFile = await imageToString(file)
            setUpdateReview({ ...updateReview, devScreenshots: devFile })
        } catch (error) {
            alert('failed to create string')
        }
    };

    const handleChange = (event) => {
        setUpdateReview({ ...updateReview, [event.target.id]: event.target.value })
    }

    const copyRemark = () => {
        setUpdateReview((prevReview) => ({
            ...prevReview,
            remark: ticketDetails.remark,
        }));
    };

    const handleSubmit = async () => {
        try {
            // Add ticketId to updateReview
            const updatedReviewWithTicketId = {
                ...updateReview,
                ticketId: ticketDetails.ticketId // Replace 'yourTicketIdValue' with the actual ticket ID
            };

            const resp = await postApiResponse(postApi, {
                arr: [updatedReviewWithTicketId]
            }, authToken)
            alert(resp.message)
            window.location.reload()
        } catch (error) {
            setErrorTitle('Failed to insert Logs');
            setErrorMessage(error.response.data.message);
            setShowErrorModal(true);
        }
    }

    return (
        <>
            {ticketDetails ? (

                <Modal size='lg' show={showPrompt} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{ticketDetails.ticketId}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="d-flex justify-content-between p-1">
                            <Col md={4}>Complainant Name</Col>
                            <Col>{ticketDetails.complainantName}</Col>
                            <Col>({ticketDetails.complainantRole})</Col>
                        </Row><hr className="mt-0 mb-2" />

                        <Row className="d-flex justify-content-between p-1">
                            <Col md={4}>Generated On</Col>
                            <Col>{formatToJSDate(ticketDetails.openDate)}</Col>
                        </Row><hr className="mt-0 mb-2" />

                        {(ticketDetails.ticketStatus === "PROGRESS" && userRole === 'handler') && (
                            <>
                                <Row className="d-flex justify-content-between p-1">
                                    <Col md={4}>Set Ticket Priority</Col>
                                    <Col>
                                        <Form.Group controlId="ticketPriority">
                                            <div>
                                                <Form.Check
                                                    inline
                                                    type="radio"
                                                    label="URGENT"
                                                    name="ticketPriority"
                                                    value="URGENT"
                                                    // checked={status === 'PROGRESS'}
                                                    onChange={handleChange}
                                                />
                                                <Form.Check
                                                    inline
                                                    type="radio"
                                                    label="HIGH"
                                                    name="ticketPriority"
                                                    value="HIGH"
                                                    // checked={status === 'CLOSED'}
                                                    onChange={handleChange}
                                                />
                                                <Form.Check
                                                    inline
                                                    type="radio"
                                                    label="NORMAL"
                                                    name="ticketPriority"
                                                    value="NORMAL"
                                                    // checked={status === 'CLOSED'}
                                                    onChange={handleChange}
                                                />
                                                <Form.Check
                                                    inline
                                                    type="radio"
                                                    label="LOW"
                                                    name="ticketPriority"
                                                    value="LOW"
                                                    // checked={status === 'CLOSED'}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row><hr className="mt-0 mb-2" />
                            </>
                        )}

                        <Row className="d-flex justify-content-between p-1">
                            <Col md={4}>Issue Type</Col>
                            <Col>{ticketDetails.issueCategory}</Col>
                        </Row><hr className="mt-0 mb-2" />

                        <Row className="d-flex justify-content-between p-1">
                            <Col md={4}>Issue Sub-type</Col>
                            <Col>{ticketDetails.issueSubcategory}</Col>
                        </Row><hr className="mt-0 mb-2" />

                        <Row className="d-flex justify-content-between p-1">
                            <Col md={4}>Issue Summary</Col>
                            <Col>{ticketDetails.issue}</Col>
                        </Row><hr className="mt-0 mb-2" />

                        {userRole === 'handler' && (
                            <>
                                <Row className="d-flex justify-content-between p-1">
                                    <Col md={4}>Update Ticket Status</Col>
                                    <Col>
                                        {ticketDetails.ticketStatus === "REVIEWING" ? (
                                            <>
                                                <Form.Group controlId="ticketStatus">
                                                    <div>
                                                        <Form.Check
                                                            inline
                                                            type="radio"
                                                            label="Send to Officer"
                                                            name="ticketStatus"
                                                            value="PROGRESS"
                                                            // checked={status === 'PROGRESS'}
                                                            onChange={handleChange}
                                                        />
                                                        <Form.Check
                                                            inline
                                                            type="radio"
                                                            label="Close the Ticket"
                                                            name="ticketStatus"
                                                            value="CLOSED"
                                                            // checked={status === 'CLOSED'}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </Form.Group>
                                            </>
                                        ) : ticketDetails.ticketStatus}
                                    </Col>
                                </Row><hr className="mt-0 mb-2" />
                            </>
                        )}

                        {userRole === 'handler' && (
                            <>
                                <Row className="d-flex justify-content-between p-1">
                                    <Col md={4}>Previous Remark</Col>
                                    <Col>{ticketDetails.remark}</Col>
                                </Row><hr className="mt-0 mb-2" />
                            </>
                        )}


                        <Row className="d-flex justify-content-between p-1">
                            <Col md={4}>Remark</Col>
                            <Col>
                                {userRole === 'handler' && (
                                    <Button onClick={() => copyRemark()} size='sm' className='mb-2' variant='outline-success'>Copy Officer Remark</Button>
                                )}
                                <Form.Group as={Col} md="12" controlId="remark" onChange={handleChange}>
                                    <Form.Control
                                        as="textarea"
                                        value={updateReview.remark}
                                        rows={10}
                                        placeholder="Write your remark here... (Optional)"
                                        autoComplete="off"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => handleSubmit()}>Submit</Button>
                    </Modal.Footer>
                </Modal>
            ) : null}
        </>
    )
}
