import React from 'react'
import { IoAlertCircleSharp } from 'react-icons/io5'

export default function NoDataAvailable() {
    return (
        <div className="d-flex flex-column justify-content-center align-items-center">
            <IoAlertCircleSharp color="red" size={70} />
            <p className="text-center">No Data Available</p>
        </div>
    )
}
