import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

// export function ErrorMessage({ show, handleClose, errorTitle, errorMessage }) {

//     return (
//         <>
//             <div>
//                 <Modal show={show} onHide={handleClose}>
//                     <Modal.Header closeButton>
//                         <Modal.Title>{errorTitle}</Modal.Title>
//                     </Modal.Header>
//                     <Modal.Body>{errorMessage}</Modal.Body>
//                     {/* <Modal.Footer>
//                         <Button variant="secondary" onClick={handleClose}>
//                             Close
//                         </Button>
//                     </Modal.Footer> */}
//                 </Modal>
//             </div>
//         </>
//     )
// }

export function ErrorMessage({ show, handleClose, errorTitle, errorMessage }) {
    return (
        <Modal
            show={show}
            onHide={handleClose}
            contentClassName="bg-danger text-white" // Apply danger theme to the modal content

        >
            <Modal.Title className='fs-5'>
                {errorTitle}<hr className='my-1 w-100' />
                <span className="fs-6">{errorMessage}</span>
            </Modal.Title>
        </Modal>
    );
}


export function SuccessMessage({ show, handleClose, successTitle, successMessage, navigateTo, buttonText }) {
    const navigate = useNavigate()
    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                contentClassName="bg-success text-white"

            >
                <Modal.Title className='fs-5'>
                    {successTitle}<hr className='my-1 w-100' />
                    <span className="fs-6">{successMessage}</span>
                </Modal.Title>
                {buttonText && (
                    <Modal.Footer>
                        <Button variant='light' onClick={() => navigate(navigateTo)}>{buttonText}</Button>
                    </Modal.Footer>
                )}
            </Modal>
        </>
    )
}


export function ConfirmationPrompt({ show, handleClose, promptTitle, promptMessage, buttonAction, buttonText }) {
    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                contentClassName="bg-success text-white"

            >
                <Modal.Title className='fs-5'>
                    {promptTitle}<hr className='my-1 w-100' />
                    <span className="fs-6">{promptMessage}</span>
                </Modal.Title>
                {buttonAction && (
                    <Modal.Footer>
                        <Button onClick={buttonAction}>{buttonText}</Button>
                    </Modal.Footer>
                )}
            </Modal>
        </>
    )
}