import React from 'react'
import { Row, Col } from 'react-bootstrap'
import emblem from '../../assets/images/emblem.png'
import cpcbLogo from '../../assets/images/cpcbLogo.png'
import IndiaEmblem from '../../assets/images/logo/logo-india.png'
import CpcbLogo from '../../assets/images/logo/logo-cpcb.png'
import MoefccLogo from '../../assets/images/logo/logo-moefcc.png'

export default function Header() {
    return (
        <>
            {/* <Row className='mt-3 mb-3 p-2'>
                <Col md={1}>
                    <img src={emblem} alt='emblem' style={{ height: '15vh' }} />
                </Col>
                <Col md={1}>
                    <img src={cpcbLogo} alt='cpcb logo' style={{ height: '15vh' }} />
                </Col>
                <Col md={8} style={{ marginLeft: '20px' }}>
                    <h5 style={{ marginBottom: '5px' }}>Central Pollution Control Board</h5>
                    <p>Ministry of Environment, Forest and Climate Change<br />
                        Government of India
                    </p>
                </Col>
            </Row> */}

            <Row className="align-items-center py-2">
                <Col xs={4} md={2} className="text-center">
                    <img
                        src={IndiaEmblem}
                        alt="India Emblem"
                        style={{
                            height: '70px',
                            width: '70px',
                            borderRadius: '50%',
                            backgroundColor: 'white',
                            padding: '5px',
                            objectFit: 'contain',
                        }}
                        className="me-2"
                    />
                    <img
                        src={CpcbLogo}
                        alt="CPCB Logo"
                        style={{
                            height: '100px',
                            width: '100px',
                            borderRadius: '50%',
                            backgroundColor: 'white',
                            padding: '5px',
                            objectFit: 'contain',
                        }}
                        className="me-2"
                    />
                    <img
                        src={MoefccLogo}
                        alt="MoEFCC Logo"
                        style={{
                            height: '70px',
                            width: '70px',
                            borderRadius: '50%',
                            backgroundColor: 'white',
                            padding: '5px',
                            objectFit: 'contain',
                        }}
                        className="me-2"
                    />
                </Col>

                <Col></Col>

                <Col xs={4} md={3} className='text-end' style={{marginRight: '20px'}}>
                    <h5 style={{ marginBottom: '5px' }}>Central Pollution Control Board</h5>
                    <p>Ministry of Environment, Forest and Climate Change<br />
                        Government of India
                    </p>
                </Col>
            </Row>
        </>
    )
}
