import React, { useEffect, useState } from 'react'
import { Row, Table, Button } from 'react-bootstrap'
import { getApiResponse, postApiResponse } from '../utils/apiPromise'

const fetchBlockedUsersApi = process.env.REACT_APP_API_FETCH_BLOCKED_USERS
const updateUserStatusApi = process.env.REACT_APP_API_UPDATE_USERS_STATUS


export default function UpdateUserStatus() {

    const [fetchBlockedUsers, setFetchBlockedUsers] = useState(false)
    const [reRender, setReRender] = useState(false)
    const [blockedUsers, setBlockedUsers] = useState()
    const authToken = sessionStorage.getItem('authToken')

    useEffect(() => {
        setFetchBlockedUsers(true)
    }, [])

    useEffect(() => {
        if (fetchBlockedUsers) {
            const fetchData = async () => {
                try {
                    // Fetch controller tickets
                    const blockedUsersResponse = await getApiResponse(fetchBlockedUsersApi, authToken)
                    setBlockedUsers(blockedUsersResponse.blockedUsers)
                } catch (error) {
                    alert(error);
                }
            }
            fetchData()
        }
    }, [fetchBlockedUsers, reRender])

    const handleUnblockUser = async (emailId) => {
        try {
            await postApiResponse(updateUserStatusApi, {
                userEmail: emailId,
                userStatus: "active"
            }, authToken)
            setReRender(!reRender)
        } catch (error) {
            alert(error)
        }
    }

    return (
        <>
            <Row className='mt-1'>
                <Table hover>
                    <thead>
                        <tr>
                            <th style={{ textAlign: 'center', width: '180px' }}>User Name</th>
                            <th>Email ID</th>
                            <th style={{ width: '300px' }}>Contact Number</th>
                            <th style={{ textAlign: 'center', width: '180px' }}>Unblock User</th>
                        </tr>
                    </thead>
                    {blockedUsers ? (
                        <tbody>
                            {blockedUsers.map((user, index) => (
                                <tr key={index}>
                                    <td style={{ textAlign: 'center', width: '180px' }}>{user.userName}</td>
                                    <td>{user.emailId}</td>
                                    <td>{user.phone}</td>
                                    <td style={{ textAlign: 'center', width: '180px' }}>
                                        <Button variant="success" onClick={() => handleUnblockUser(user.emailId)}>Unblock</Button>
                                        {/* <Button variant={ticket.assignedToName === 'Unassigned' ? 'success' : 'secondary'} onClick={() => handleAssignTicket(ticket.ticketId)}>{ticket.assignedToName === 'Unassigned' ? 'Assign' : 'Re-Assign'}</Button> */}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    ) : null}
                </Table>
            </Row>
        </>
    )
}
