import React from 'react'
import { Button, Row } from 'react-bootstrap'
import { useUserDetails } from '../../contextapi/userDetails'
import Profile from "../../assets/images/profile.jpg"

export default function ProfileCard({ setSidebarIndex, sideMenu }) {
    const { userDetails } = useUserDetails()

    return (
        <>
            <img
                src={Profile}
                style={{ borderRadius: '50%', border: '5px solid #0d6efd', width: "10vw" }}
                alt=""
            />

            <Row className='m-1 mt-4 mb-4'>
                <div className='d-flex justify-content-between p-1'>
                    <p className='mb-0'>{userDetails.userName}</p>
                </div><hr className='m-0' />

                <div className='d-flex justify-content-between p-1'>
                    <p className='mb-0'>{userDetails.emailId}</p>
                </div><hr className='m-0' />

                <div className='d-flex justify-content-between p-1'>
                    <p className='mb-0'>{userDetails.phone}</p>
                </div><hr className='m-0' />
            </Row>

            {sideMenu.map((tab, index) => (
                <Button className='m-2 w-100' onClick={() => setSidebarIndex(index)}>{tab}</Button>
            ))}

        </>
    )
}
