import React, { useState } from 'react'
import CommonNavbar from '../components/common/CommonNavbar'
import { Button, Col, Row } from 'react-bootstrap'
import axios from 'axios'
import { encryptDataFunc } from '../utils/encryptBody'
import { ErrorMessage } from '../modals/ErrorMessage'
import TicketTrail from '../components/cards/TicketTrail'

const testApi = 'http://localhost:5000/testapi'

export default function ApiView() {
    const user = {
        emailId: "testing@gmail.com",
        password: "testing"
    }

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorTitle, setErrorTitle] = useState('');

    const handleSubmit = async () => {
        try {
            const encryptedData = await encryptDataFunc(user)
            console.log(encryptedData)
            axios.post(testApi, encryptedData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then((response) => console.log(response.data))
                .catch((error) => {
                    setErrorTitle('Test API failed');
                    setErrorMessage(error.response.data.message);
                    setShowErrorModal(true);
                })

        } catch (error) {
            alert("Error in encryption")
        }
    }

    const handleCloseErrorModal = () => {
        setShowErrorModal(false);
    };

    return (
        <>
            <Row>
                <CommonNavbar />
            </Row>
            <Row className='mt-4'>
                <Col>
                    <Button className='m-2 btn-danger' onClick={() => handleSubmit()}>Check Api</Button>
                </Col>
            </Row>

            <TicketTrail />

            <ErrorMessage show={showErrorModal} handleClose={handleCloseErrorModal} errorTitle={errorTitle} errorMessage={errorMessage} />
        </>
    )
}
