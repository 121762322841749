import React from "react";
import { Route } from "react-router-dom";
import HandlerDashboard from "../views/handler/HandlerDashboard";
import HandlerAllTickets from "../views/handler/HandlerAllTickets";
import HandlerRevertedTickets from "../views/handler/HandlerRevertedTickets";
import HandlerReviewingTickets from "../views/handler/HandlerReviewingTickets";
import HandlerFilteredTickets from "../views/handler/HandlerFilteredTickets";

export default function HandlerRoutes() {
  return (
    <>
      <Route exact path="/handler/dashboard" element={<HandlerDashboard />} />
      <Route
        exact
        path="/handler/tickets/all"
        element={<HandlerAllTickets />}
      />
      <Route
        exact
        path="/handler/tickets/reverted"
        element={<HandlerRevertedTickets />}
      />
      <Route
        exact
        path="/handler/tickets/reviewing"
        element={<HandlerReviewingTickets />}
      />
      <Route
        exact
        path="/handler/tickets/filtered"
        element={<HandlerFilteredTickets />}
      />
    </>
  );
}
