import React, { createContext, useState, useContext } from 'react'
import sampleTicketCounts from '../assets/arrays/sampleTicketCounts'
import sampleTickets from '../assets/arrays/sampleTickets'


const SampleHandlersContext = createContext();
export const AdminHandlersProvider = ({ children }) => {
    const [handlers, setHandlers] = useState([])
    return (
        <SampleHandlersContext.Provider value={{ handlers, setHandlers }}>
            {children}
        </SampleHandlersContext.Provider>
    );
};
export const useAdminHandler = () => useContext(SampleHandlersContext)


const SampleDevelopersContext = createContext();
export const AdminDevelopersProvider = ({ children }) => {
    const [developers, setDevelopers] = useState([])
    return (
        <SampleDevelopersContext.Provider value={{ developers, setDevelopers }}>
            {children}
        </SampleDevelopersContext.Provider>
    );
};
export const useAdminDeveloper = () => useContext(SampleDevelopersContext)


const SampleTicketsContext = createContext();
export const AdminTicketsProvider = ({ children }) => {
    const [tickets, setTickets] = useState(sampleTickets)
    return (
        <SampleTicketsContext.Provider value={{ tickets, setTickets }}>
            {children}
        </SampleTicketsContext.Provider>
    );
};
export const useAdminTickets = () => useContext(SampleTicketsContext)


const SampleTicketCountsContext = createContext();
export const AdminTicketCountsProvider = ({ children }) => {
    const [ticketCounts, setTicketCounts] = useState(sampleTicketCounts)
    return (
        <SampleTicketCountsContext.Provider value={{ ticketCounts, setTicketCounts }}>
            {children}
        </SampleTicketCountsContext.Provider>
    );
};
export const useAdminTicketCounts = () => useContext(SampleTicketCountsContext)