import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import CommonNavbar from '../../components/common/CommonNavbar'
import SideMenu from '../../components/common/SideMenu'
import { controllerSideMenu } from '../../assets/sideMenuTabs'
import { ticketDetailedStructure } from '../../assets/dataStructures/ticketsStructure'
import { Spinner } from "react-bootstrap"; // Using Bootstrap's Spinner for simplicity
import { FaTicketAlt } from 'react-icons/fa';
import { TiMessages } from "react-icons/ti";
import { IoAlertCircleSharp } from "react-icons/io5";
import { formatToJSDate } from "../../utils/formatDate";
import { DataGridWithMultipleButtons } from '../../components/datagrids/AgDataGrid'
import { postApiResponse } from '../../utils/apiPromise'
import { ErrorMessage } from '../../modals/ErrorMessage'
import DataLoader from '../../components/common/DataLoader'
import NoDataAvailable from '../../components/common/NoDataAvailable'

const fetchStatusWiseTicketsApi = process.env.REACT_APP_API_FETCH_TICKETS_STATUSWISE;

export default function ControllerClosedTickets() {
  const [fetchTickets, setFetchTickets] = useState(false);
  const [loading, setLoading] = useState(false); // Loader state
  const [allTickets, setAllTickets] = useState([ticketDetailedStructure]);

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorTitle, setErrorTitle] = useState('');

  const authToken = sessionStorage.getItem("authToken");
  const navigate = useNavigate();

  useEffect(() => {
    setFetchTickets(true);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Show loader
      try {
        const ticketResponse = await postApiResponse(fetchStatusWiseTicketsApi, {
          ticketStatus: ['closed']
        }, authToken);
        setAllTickets([...ticketResponse.tickets]);
      } catch (error) {
        setErrorTitle('Failed to Fetch Tickets');
        (error.message && setErrorMessage(error.message))
        setShowErrorModal(true);
      } finally {
        setLoading(false); // Hide loader 
      }
    };
    if (fetchTickets) {
      fetchData();
    }
  }, [authToken, fetchTickets]);

  const handleCloseErrorModal = () => {
    setShowErrorModal(false)
    navigate('/controller/dashboard')
  };

  const buttonsProps =
    [
      {
        variant: 'success',
        text: <FaTicketAlt />,
        tooltip: "View Ticket", // Hover text
        onClick: (params) => {
          navigate("/handler/dashboard");
        }
      },
      {
        variant: 'primary',
        text: <TiMessages />,
        tooltip: "View Trail", // Hover text
        onClick: (params) => {
          navigate("/handler/dashboard");
        }
      }
    ]

  const columns = [
    { field: 'ticketId', headerName: 'Ticket ID' },
    { field: 'openDate', headerName: 'Generated On', valueFormatter: (params) => formatToJSDate(params.value) },
    { field: 'updateDate', headerName: 'Closed On', valueFormatter: (params) => formatToJSDate(params.value) },
    { field: 'categoryName', headerName: 'Issue Type' },
    { field: 'ticketType', headerName: 'Division Name' },
    { field: 'developerName', headerName: 'Closed By' }
  ];


  return (
    <>
      <Row>
        <CommonNavbar />
      </Row>

      <Row>
        <Col md={2}>
          <SideMenu menu={controllerSideMenu} theme="dark" />
        </Col>
        <Col md={10}>
          <h4 className="mt-2">Closed Tickets</h4>
          <hr />
          {loading ? <DataLoader /> : (
            <>
              {allTickets.length > 0 ? (
                <DataGridWithMultipleButtons
                  data={allTickets}
                  columns={columns}
                  pagination="true"
                  rowsPerPage={20}
                  rowsPerPageSelector={[20, 50, 100]}
                  buttonsProps={buttonsProps}
                  theme="light"
                  headerBgClass="bg-success text-white"
                />
              ) : <NoDataAvailable />}
            </>
          )}
        </Col>
      </Row>

      <ErrorMessage show={showErrorModal} handleClose={handleCloseErrorModal} errorTitle={errorTitle} errorMessage={errorMessage} />
    </>
  )
}
