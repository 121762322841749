import React from 'react'
import { Button } from 'react-bootstrap';
import { useDeveloperData } from '../../../contextapi/developerContext';

export default function DeveloperTicketCountCard({tickets, ticketType}) {
    const reviewingTicketsCount = tickets.filter(ticket => ticket.ticketStatus === 'REVIEWING').length;
    const progressTicketsCount = tickets.filter(ticket => ticket.ticketStatus === 'PROGRESS').length;
    const closedTicketsCount = tickets.filter(ticket => ticket.ticketStatus === 'CLOSED').length;
    const revertedTicketsCount = tickets.filter(ticket => ticket.ticketStatus === 'REVERTED').length;
    const urgentTicketsCount = tickets.filter(ticket => ticket.ticketPriority === 'URGENT' && ticket.ticketStatus !== 'CLOSED').length;

  return (
    <>
    {ticketType === 'REVIEWING' ? (
        <Button  variant='warning' className='w-100 h-100'>
            <h1>{reviewingTicketsCount}</h1>
            <p>TICKETS UNDER REVIEW BY DH</p>
        </Button>
    ) : null }
    {ticketType === 'PROGRESS' ? (
        <Button  variant='primary' className='w-100 h-100'>
            <h1>{progressTicketsCount}</h1>
            <p>TICKETS ASSIGNED TO YOU</p>
        </Button>
    ) : null }
    {ticketType === 'CLOSED' ? (
        <Button  variant='success' className='w-100 h-100'>
            <h1>{closedTicketsCount}</h1>
            <p>TICKETS CLOSED</p>
        </Button>
    ) : null }
    {ticketType === 'REVERTED' ? (
        <Button  variant='dark' className='w-100 h-100'>
            <h1>{revertedTicketsCount}</h1>
            <p>TICKETS REVERTED</p>
        </Button>
    ) : null }
    {ticketType === 'URGENT' ? (
        <Button  variant='danger' className='w-100 h-100'>
            <h1>{urgentTicketsCount}</h1>
            <p>URGENT TICKETS</p>
        </Button>
    ) : null }

</>
  )
}
