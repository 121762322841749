const complainantPortalRole = [
    'Producer',
    'Importer',
    'Brand Owner',
    'PWP (Recycler)',
    'PWP (W2E)',
    'PWP(W2O)',
    'PWP (Co-Processor)',
    'SPCB | PCC',
    'General',
    'Industrial Composting',
    'ULB'
]

export default complainantPortalRole;