const complaintFormStructure = {
    emailId: '',
    complainantPortalRole: null,
    portalId: null,
    issueCategoryId: null,
    issueSubcategoryId: null,
    issue: '',
    screenshots: null
}

export default complaintFormStructure