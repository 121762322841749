import React, { useEffect, useState } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import CommonNavbar from '../../components/common/CommonNavbar'
import SideMenu from '../../components/common/SideMenu'
import { officerSideMenu } from '../../assets/sideMenuTabs'
import { getApiResponse, postApiResponse } from '../../utils/apiPromise'
import DeveloperTicketCountCard from '../../components/cards/developers/DeveloperTicketCountCard'
import OfficerTicketsDurationPieChart from '../../components/cards/developers/OfficerTicketsDurationPieChart'
import { ticketsActionPerformedStructure, ticketShortInfoStructure } from '../../assets/dataStructures/ticketsStructure'
import OfficerActionPerformedHistory from '../../components/cards/developers/OfficerActionPerformedHistory'
import { useNavigate } from 'react-router-dom'
import { ErrorMessage } from '../../modals/ErrorMessage'
import { IoAlertCircleSharp } from 'react-icons/io5'
import DataLoader from '../../components/common/DataLoader'
import NoDataAvailable from '../../components/common/NoDataAvailable'

const fetchTicketShorfInfoApi = process.env.REACT_APP_API_FETCH_TICKET_SHORT_INFO
const fetchActivityLogApi = process.env.REACT_APP_API_FETCH_ACTIVITY_LOG

export default function OfficerDashboard() {

    const [loadingTicketsShortInfo, setLoadingTicketsShortInfo] = useState(false);
    const [loadingTicketsAction, setLoadingTicketsAction] = useState(false);

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorTitle, setErrorTitle] = useState('');

    const [fetchTicketsAction, setFetchTicketsAction] = useState(false)
    const [fetchTicketsShortInfo, setFetchTicketsShortInfo] = useState(false)

    const [ticketsShortInfo, setTicketsShortInfo] = useState(ticketShortInfoStructure)
    const [ticketsAction, setTicketsAction] = useState([ticketsActionPerformedStructure])

    const authToken = sessionStorage.getItem('authToken')
    const navigate = useNavigate()

    useEffect(() => {
        setFetchTicketsAction(true)
        setFetchTicketsShortInfo(true)
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const shortInfoResponse = await getApiResponse(fetchTicketShorfInfoApi, authToken)
                setTicketsShortInfo([...shortInfoResponse.tickets])
            } catch (error) {
                setErrorTitle('Failed to Fetch Tickets Counts');
                (error.message && setErrorMessage(error.message))
                setShowErrorModal(true);
            } finally {
                setLoadingTicketsShortInfo(false);
            }
        }
        if (fetchTicketsShortInfo) {
            fetchData()
        }
    }, [fetchTicketsShortInfo])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const activityLogResponse = await postApiResponse(fetchActivityLogApi, {
                    logLimit: 10
                }, authToken)
                setTicketsAction([...activityLogResponse.actions])
            } catch (error) {
                setErrorTitle('Failed to Fetch Activity Logs');
                (error.message && setErrorMessage(error.message))
                setShowErrorModal(true);
            } finally {
                setLoadingTicketsAction(false); // Hide loader
            }
        }
        if (fetchTicketsAction) {
            fetchData()
        }
    }, [fetchTicketsAction])

    const handleCloseErrorModal = () => {
        setShowErrorModal(false)
        navigate('/officer/dashboard')
    };

    return (
        <>
            <Row>
                <CommonNavbar />
            </Row>

            <Row>
                <Col md={2}>
                    <SideMenu menu={officerSideMenu} theme="dark" />
                </Col>

                <Col md={10}>
                    <Row className='my-2 bg-light p-4' style={{ borderRadius: '1rem' }}>
                        {loadingTicketsShortInfo ? <DataLoader /> : (
                            <>
                                {(ticketsShortInfo.length > 0) ? (
                                    <>
                                        {/* In Progress Tickets */}
                                        <Col md={3}>
                                            <DeveloperTicketCountCard tickets={ticketsShortInfo} ticketType={'PROGRESS'} />
                                        </Col>

                                        {/* In-Review Tickets */}
                                        <Col md={3}>
                                            <DeveloperTicketCountCard tickets={ticketsShortInfo} ticketType={'REVIEWING'} />
                                        </Col>

                                        {/* Reverted Tickets */}
                                        <Col md={3}>
                                            <DeveloperTicketCountCard tickets={ticketsShortInfo} ticketType={'REVERTED'} />
                                        </Col>

                                        {/* Closed Tickets */}
                                        <Col md={3}>
                                            <DeveloperTicketCountCard tickets={ticketsShortInfo} ticketType={'CLOSED'} />
                                        </Col>
                                    </>
                                ) : <NoDataAvailable />}
                            </>
                        )}
                    </Row>

                    <Row className='my-2'>
                        <Col className='bg-light p-4' style={{ borderRadius: '1rem' }} md={8}>
                            <h4 className='text-center'>Latest Actions Performed</h4><hr />
                            {loadingTicketsAction ? <DataLoader /> : (
                                <>
                                    {(ticketsAction.length > 0) ? (
                                        <OfficerActionPerformedHistory ticketsAction={ticketsAction} />
                                    ) : <NoDataAvailable />}
                                </>
                            )}
                        </Col>
                        <Col className='bg-light p-4 ms-2' style={{ borderRadius: '1rem' }}>
                            <h4 className='text-center'>Tickets Status Duration</h4><hr />
                            {loadingTicketsShortInfo ? <DataLoader /> : (
                                <>
                                    {(ticketsShortInfo.length > 0) ? (
                                        <OfficerTicketsDurationPieChart tickets={ticketsShortInfo} disabledStatuses={['CLOSED', 'REVERTED']} />
                                    ) : <NoDataAvailable />}
                                </>
                            )}
                        </Col>
                    </Row>
                </Col>
            </Row>

            <ErrorMessage show={showErrorModal} handleClose={handleCloseErrorModal} errorTitle={errorTitle} errorMessage={errorMessage} />
        </>
    )
}
