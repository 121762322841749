const sampleTicketCounts = {
    weekAssignedCount: null,
    weekUnassignedCount: null,
    monthAssignedCount: null,
    monthUnassignedCount: null,
    dayAssignedCount: null,
    dayUnassignedCount: null,
    dayGeneratedCount: null,
    weekGeneratedCount: null,
    monthGeneratedCount: null
}

export default sampleTicketCounts