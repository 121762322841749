import React, { useEffect, useState } from 'react'
import { Modal, Button, Row, Form, InputGroup } from 'react-bootstrap'
import { complainantLoginStructure } from '../utils/loginFormStructure'
import { useComplaint } from '../contextapi/complaintContext';
import { loginApiResponse, multipartApiResponse } from '../utils/apiPromise';
import { useNavigate } from 'react-router-dom';
import { useUserDetails } from '../contextapi/userDetails';
import { ErrorMessage, SuccessMessage } from './ErrorMessage';
import axios from 'axios';

const generateTicketApi = process.env.REACT_APP_API_NEW_TICKET
const userLoginApi = process.env.REACT_APP_API_USER_LOGIN


export default function ComplainantLoginPrompt({ setShowLoginModal, emailId }) {
  const [validated, setValidated] = useState(false); // To validate fields in the form
  const navigate = useNavigate()

  // Change the login Structure for Complainant
  const [user, setUser] = useState(complainantLoginStructure) // To access form structure for submiting form

  const { complaint } = useComplaint()
  const { userDetails, setUserDetails } = useUserDetails();
  const [userLogedIn, setUserLogedIn] = useState(false)
  const [fieldValidate, setFieldValidate] = useState(true)

  const [errorNavigation, setErrorNavigation] = useState('/')
  const [successNavigation, setSuccessNavigation] = useState('/')

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorTitle, setErrorTitle] = useState('');

  // For Success Modal
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [successTitle, setSuccessTitle] = useState('');


  const passwordPattern = /^[a-zA-Z0-9@.\-]+$/;
  let isValid

  useEffect(() => {
    setUser({ ...user, 'emailId': complaint.emailId })
  }, [])

  // To generate Ticket after lodging complaint
  useEffect(() => {
    const lodgeComplaint = async () => {
      try {
        const authToken = sessionStorage.getItem('authToken')
        const ticketResponse = await multipartApiResponse(generateTicketApi, complaint, authToken)

        setSuccessTitle('Complaint has been lodged successfully');
        (ticketResponse.ticketId && setSuccessMessage(`Complaint has been lodged and ticket id is ${ticketResponse.ticketId}`))
        setSuccessNavigation('/complainant')
        setShowSuccessModal(true);
      } catch (error) {
        setErrorTitle('Failed to Lodge Ticket');
        (error.message && setErrorMessage(error.message))
        setErrorNavigation('/complainant')
        setShowErrorModal(true);
      }
    }
    if (userLogedIn) {
      lodgeComplaint()
    }
  }, [complaint, navigate, userLogedIn])


  // To login prior lodging the complaint
  const handleSubmit = (event) => {
    const loginUser = async () => {
      try {
        const loginResponse = await loginApiResponse(userLoginApi, user)
        sessionStorage.setItem('authToken', loginResponse.userDetails.authToken);
        if (loginResponse.passwordReset === true) {
          setErrorTitle('Password Reset Required');
          setErrorMessage("You are required to reset the password due to security reasons");
          setErrorNavigation('/passwordreset')
          setShowErrorModal(true);
          sessionStorage.removeItem('authToken')
        } else {
          setUserDetails({ ...userDetails })
          setUserLogedIn(true)
        }
      } catch (error) {
        setErrorTitle('Failed to Login');
        (error.message && setErrorMessage(error.message))
        setErrorNavigation('/')
        setShowErrorModal(true);
      }
    }
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      loginUser();
    }
    setValidated(true);
  };

  const handleCloseErrorModal = () => {
    setShowErrorModal(false);
    setShowLoginModal(false);
    navigate(errorNavigation)
  };

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    navigate(successNavigation)
  };

  // To handle values changing in the input fields
  const handleChange = (event) => {
    const { id, value, type, name } = event.target
    if (id === 'password') {
      isValid = passwordPattern.test(value) || value === ''
      setFieldValidate(isValid)
    }
    setUser({ ...user, [id]: value })
  }

  // To close the Login Modal
  const handleClose = () => {
    setShowLoginModal(false)
  }

  return (
    <>
      <Modal show onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Login Required</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            {/* User Email ID */}
            <Row className="mb-3">
              <Form.Group controlId="emailId">
                <Form.Label>Email Id</Form.Label>
                <InputGroup hasValidation>
                  <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                  <Form.Control
                    type="email"
                    placeholder={`${emailId}`}
                    aria-describedby="inputGroupPrepend"
                    disabled
                    autoComplete="off"
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid email Id.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Row >

            {/* User Password */}
            <Row className="mb-3">
              <Form.Group className="mb-3" controlId="password" onChange={handleChange}>
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  isInvalid={!fieldValidate}
                  autoComplete="off"
                />
                <Form.Control.Feedback type="invalid">
                  Password must only contain letters, numbers, '@', '.', and '-'.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Button type='submit' className='btn btn-primary'>Submit</Button>
          </Form>
        </Modal.Body>
      </Modal>

      <ErrorMessage show={showErrorModal} handleClose={handleCloseErrorModal} errorTitle={errorTitle} errorMessage={errorMessage} />
      <SuccessMessage show={showSuccessModal} handleClose={handleCloseSuccessModal} successTitle={successTitle} successMessage={successMessage} />

    </>
  )
}

