import { GrOverview } from "react-icons/gr";

export const handlerSideMenu = [
  {
    title: "Dashboard",
    path: "/handler/dashboard",
    section: "Main Menu",
    icon: <GrOverview />,
  },
  {
    title: "All Tickets",
    path: "/handler/tickets/all",
    section: "Main Menu",
    icon: <GrOverview />,
  },
  {
    title: "Reviewing Tickets",
    path: "/handler/tickets/reviewing",
    section: "Main Menu",
    icon: <GrOverview />,
  },
  {
    title: "Reverted Tickets",
    path: "/handler/tickets/reverted",
    section: "Main Menu",
    icon: <GrOverview />,
  },
];

export const officerSideMenu = [
  {
    title: "Dashboard",
    path: "/officer/dashboard",
    section: "Main Menu",
    icon: <GrOverview />,
  },
  {
    title: "All Tickets",
    path: "/officer/tickets/all",
    section: "Main Menu",
    icon: <GrOverview />,
  },
  {
    title: "Tickets for Action",
    path: "/officer/tickets/progress",
    section: "Main Menu",
    icon: <GrOverview />,
  }
];

export const controllerSideMenu = [
  {
    title: "Dashboard",
    path: "/controller/dashboard",
    section: "Main Menu",
    icon: <GrOverview />,
  },
  {
    title: "All Tickets",
    path: "/controller/tickets/all",
    section: "Main Menu",
    icon: <GrOverview />,
  },
  {
    title: "Open Tickets",
    path: "/controller/tickets/open",
    section: "Main Menu",
    icon: <GrOverview />,
  },
  {
    title: "Reverted Tickets",
    path: "/controller/tickets/reverted",
    section: "Main Menu",
    icon: <GrOverview />,
  },
  // {
  //   title: "Closed Tickets",
  //   path: "/controller/tickets/closed",
  //   section: "Main Menu",
  //   icon: <GrOverview />,
  // }
];
