const loginFormStructure = {
    emailId: '',
    userRole: null,
    password: ''
}

const complainantLoginStructure = {
    emailId: '',
    userRole: 'complainant',
    password: ''
}

export { complainantLoginStructure }

export default loginFormStructure