const portalName = [
    {
        'portalId': 1,
        'portalName' : 'EPR Plastic',
        'portalStatus' : null
    },
    {
        'portalId': 2,
        'portalName' : 'E-Waste Management',
        'portalStatus' : 'disabled'
    },
    {
        'portalId': 3,
        'portalName' : 'EPR Battery',
        'portalStatus' : 'disabled'
    },
    {
        'portalId': 4,
        'portalName' : 'EPR - Management of Waste Tyre',
        'portalStatus' : 'disabled'
    }
]

export default portalName