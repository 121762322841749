import React from 'react'
import ControllerDashboard from '../views/controller/ControllerDashboard'
import ControllerAllTickets from '../views/controller/ControllerAllTickets'
import ControllerRevertedTickets from '../views/controller/ControllerRevertedTickets'
import ControllerClosedTickets from '../views/controller/ControllerClosedTickets'
import { Route } from 'react-router-dom'
import ControllerOpenTickets from '../views/controller/ControllerOpenTickets'

export default function ControllerRoutes() {
    return (
        <>
            <Route exact path="/controller/dashboard" element={<ControllerDashboard />} />
            <Route
                exact
                path="/controller/tickets/all"
                element={<ControllerAllTickets />}
            />
            <Route
                exact
                path="/controller/tickets/open"
                element={<ControllerOpenTickets />}
            />
            <Route
                exact
                path="/controller/tickets/reverted"
                element={<ControllerRevertedTickets />}
            />
            <Route
                exact
                path="/controller/tickets/closed"
                element={<ControllerClosedTickets />}
            />
        </>
    )
}