import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useSampleTickets } from '../../contextapi/complainantContext'
import { getApiResponse } from '../../utils/apiPromise';
import formatDate from '../../utils/formatDate';

const fetchComplainantTicketApi = process.env.REACT_APP_API_FETCH_COMPLAINANT_TICKETS

export default function TicketTable() {

    const [fetchTicketApi, setFetchTicketApi] = useState(false)
    const { tickets, setTickets } = useSampleTickets()
    const navigate = useNavigate()
    const authToken = sessionStorage.getItem('authToken')

    useEffect(() => {
        setFetchTicketApi(true)
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const ticketResponse = await getApiResponse(fetchComplainantTicketApi, authToken)
                setTickets(ticketResponse.tickets)
            } catch (error) {
                alert(error);
                navigate('/');
            }
        }
        if(fetchTicketApi){
            fetchData()
        }
    }, [fetchTicketApi])
    return (
        <>
            <div className='m-4'>
            <Table striped hover>
                <thead>
                    <tr>
                        <th style={{ width: '120px' }}>Ticket ID</th>
                        <th style={{ width: '130px' }}>Generated On</th>
                        <th>Issue</th>
                        <th>Remark</th>
                        <th style={{ width: '170px' }}>Closed On</th>
                        <th style={{ width: '120px' }}>Status</th>
                        <th style={{ width: '120px' }}>Modified On</th>
                    </tr>
                </thead>
                <tbody>
                    {tickets.map((ticket, index) => (
                        <tr key={index}>
                            <td>{ticket.ticketId}</td>
                            <td>{formatDate(ticket.openDate)}</td>
                            <td>{ticket.issue}</td>
                            <td>{(ticket.ticketStatus === 'CLOSED') ?
                            ticket.lastRemark: null}</td>
                            <td>{formatDate(ticket.closedDate) ? formatDate(ticket.closedDate) : 'Not Closed Yet'}</td>
                            {(ticket.ticketStatus === 'CLOSED') ?
                                <td className='bg-danger text-white'>{ticket.ticketStatus}</td>
                                : null
                            }
                            {(ticket.ticketStatus === 'OPEN') ?
                                <td className='bg-secondary text-white'>{ticket.ticketStatus}</td>
                                : null
                            }
                            {(ticket.ticketStatus === 'PROGRESS') ?
                                <td className='bg-success text-white'>{ticket.ticketStatus}</td>
                                : null
                            }
                            {(ticket.ticketStatus === 'REVIEWING') ?
                                <td className='bg-primary text-white'>{ticket.ticketStatus}</td>
                                : null
                            }
                            <td>{formatDate(ticket.updateDate)}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            </div>
        </>
    )
}