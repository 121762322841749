const plasticCategories = [
    'Sign up Issues',
    'Login Issues',
    'Registration Issues',
    'Post-registration Issues',
    'PIBO Operations Issues',
    'PWP Operations Issues',
    'Certificate Generation Issues',
    'Annual Report Issues',
    'Other'
]

export default plasticCategories;