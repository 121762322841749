import React, { createContext, useState, useContext } from 'react';

const ControllerDataContext = createContext();

export const ControllerDataProvider = ({ children }) => {
  const [updatedChanges, setUpdatedChanges] = useState([]); // To store information of reverted tickets
  const [tickets, setTickets] = useState([]); // To store ticket details
  const [handlers, setHandlers] = useState([]); // To store handlers details
  const [developers, setDevelopers] = useState([]); // To store developers details
  const [viewSummary, setViewSummary] = useState(true); // State to switch summarized/complete ticket details
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState('all');


  return (
    <ControllerDataContext.Provider
      value={{
        updatedChanges,
        setUpdatedChanges,
        tickets,
        setTickets,
        handlers,
        setHandlers,
        developers,
        setDevelopers,
        viewSummary,
        setViewSummary,
        searchTerm,
        setSearchTerm,
        filter,
        setFilter
      }}
    >
      {children}
    </ControllerDataContext.Provider>
  );
};

export const useControllerData = () => useContext(ControllerDataContext);

