import React, { useState } from 'react'
import { Row, Col, Form, InputGroup, Button } from 'react-bootstrap'
import { createComplainantStructure } from '../utils/createUserStructure';
import axios from 'axios';
import complainantPortalRole from '../assets/arrays/complainantPortalRole';
import { ErrorMessage } from '../modals/ErrorMessage';

const createComplainantApi = process.env.REACT_APP_API_NEW_COMPLAINANT

export default function CreateComplainantForm(props) {
    const [validated, setValidated] = useState(false);
    const [user, setUser] = useState(createComplainantStructure)

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorTitle, setErrorTitle] = useState('');

    const handleClose = props.handleClose

    const namePattern = /^[a-zA-Z\s]*$/;
    const passwordPattern = /^[a-zA-Z0-9@.\-]+$/;
    const phoneNumberPattern = /^[0-9]{10}$/; // Example pattern for 10-digit phone number
    // const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const companyPattern = /^[a-zA-Z\s.,]*$/;
    const forbiddenRegex = /<script>|\.exe/i;

    const [fieldValidate, setFieldValidate] = useState({
        userName: true,
        emailId: true,
        phone: true,
        password: true,
        confirmPassword: true,
        companyName: true,
        eprRegNo: true
    })

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            axios.post(createComplainantApi, user, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then((response) => {
                    alert('User has been created')
                    handleClose();
                })
                .catch((error) => {
                    setErrorTitle('Failed to Create User');
                    setErrorMessage(error.response.data.message);
                    setShowErrorModal(true);
                })
        }
        setValidated(true);
    };

    const handleChange = (event) => {
        const { id, value, type, name } = event.target;
        let isValid

        if (id === 'userName') {
            isValid = namePattern.test(value) || value === ''
            setFieldValidate({ ...fieldValidate, [id]: isValid })
        } else if (id === 'password') {
            isValid = passwordPattern.test(value) || value === ''
            setFieldValidate({ ...fieldValidate, [id]: isValid })
        } else if (id === 'phone') {
            isValid = phoneNumberPattern.test(value) || value === ''
            setFieldValidate({ ...fieldValidate, [id]: isValid })
        } else if (id === 'emailId') {
            isValid = emailPattern.test(value) || value === ''
            setFieldValidate({ ...fieldValidate, [id]: isValid })
        } else if (id === 'confirmPassword') {
            isValid = value === user.password || value === ''
            setFieldValidate({ ...fieldValidate, [id]: isValid })
        } else if (id === 'companyName') {
            isValid = companyPattern.test(value) || value === ''
            setFieldValidate({ ...fieldValidate, [id]: isValid })
        } else if (id === 'eprRegNo') {
            isValid = forbiddenRegex.test(value)
            if (isValid) {
                setFieldValidate({ ...fieldValidate, [id]: 'XSS Attack Identified' })
            } else {
                setFieldValidate({ ...fieldValidate, [id]: true })
            }
        }
        setUser({ ...user, [id]: value })
    }

    const handleCloseErrorModal = () => {
        setShowErrorModal(false);
    };


    return (
        <>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className='mb-3'>
                    {/* Company Name */}
                    <Form.Group controlId="companyName" onChange={handleChange}>
                        <Form.Label>Company Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Provide the legal name of the unit"
                            aria-describedby="inputGroupPrepend"
                            isInvalid={!fieldValidate.companyName}
                            autoComplete="off"
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid name of the Company.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>



                <Row className='mb-3'>
                    {/* Complainant Portal Role*/}
                    <Col md={4}>
                        <Form.Group controlId="complainantPortalRole" onChange={handleChange} >
                            <Form.Label>Select your role</Form.Label>
                            <Form.Select value={user.complainantPortalRole} aria-label="" defaultValue={1} required>
                                {complainantPortalRole
                                    .map((portalRole, index) => (
                                        <option key={index + 1} value={index + 1}>{portalRole}</option>
                                    ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                Choose any role from the options.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col md={8}>
                        {/* EPR Registration Number */}
                        <Form.Group controlId="eprRegNo" onChange={handleChange}>
                            <Form.Label>EPR Registration Number (Optional)</Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    type="text"
                                    placeholder="Type your EPR No here if allotted, otherwise leave blank"
                                    autoComplete="off"
                                    isInvalid={!fieldValidate.eprRegNo}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Password must only contain letters, numbers, '@', '.', and '-'.
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Row>

                <Row className='mb-3'>
                    {/* User Full Name */}
                    <Form.Group controlId="userName" onChange={handleChange}>
                        <Form.Label>Full Name</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                                type="text"
                                placeholder="Full Name"
                                isInvalid={!fieldValidate.userName}
                                autoComplete="off"
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid Full Name.
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>

                </Row>


                <Row className='mb-3'>
                    <Col md={6}>
                        {/* User Email */}
                        <Form.Group controlId="emailId" onChange={handleChange}>
                            <Form.Label>Registered Email Id</Form.Label>
                            <InputGroup hasValidation>
                                <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                                <Form.Control
                                    type="email"
                                    placeholder="Type your email Id here..."
                                    aria-describedby="inputGroupPrepend"
                                    isInvalid={!fieldValidate.emailId}
                                    autoComplete="off"
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid email Id.
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        {/* Phone Number */}
                        <Form.Group controlId="phone" onChange={handleChange}>
                            <Form.Label>Registered Contact Number</Form.Label>
                            <InputGroup hasValidation>
                                <InputGroup.Text id="inputGroupPrepend">+91</InputGroup.Text>
                                <Form.Control
                                    type="text"
                                    placeholder="XXXXX XXXXX"
                                    aria-describedby="inputGroupPrepend"
                                    isInvalid={!fieldValidate.phone}
                                    autoComplete="off"
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid Contact Number.
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Row>

                <Row className='mb-3'>
                    {/* User Password */}
                    <Col md={6}>
                        <Form.Group controlId="password" onChange={handleChange}>
                            <Form.Label>Password</Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    type="password"
                                    placeholder="Set your Password here"
                                    isInvalid={!fieldValidate.password}
                                    autoComplete="off"
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    Password must only contain letters, numbers, '@', '.', and '-'.
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        {/* Confirm Password */}
                        <Form.Group controlId="confirmPassword" onChange={handleChange}>
                            <Form.Label>Confirm Password</Form.Label>
                            <InputGroup hasValidation>
                                <Form.Control
                                    type="password"
                                    placeholder="Set your Password here"
                                    isInvalid={!fieldValidate.confirmPassword}
                                    autoComplete="off"
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    Password didn't match.
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Row>
                <Button type='submit'>Create Complainant</Button>
            </Form>

            <ErrorMessage show={showErrorModal} handleClose={handleCloseErrorModal} errorTitle={errorTitle} errorMessage={errorMessage} />
        </>
    )
}
