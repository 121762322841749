import React, { useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode'; // Ensure this is correctly imported
import { useNavigate } from 'react-router-dom'; // For navigation
import { ErrorMessage } from './ErrorMessage';

export default function SessionExpiryAlert({ authToken }) {
    const navigate = useNavigate();
    const [alertShown, setAlertShown] = useState(false); // State to track if the alert has been shown
    const [showErrorModal, setShowErrorModal] = useState(false); // State for modal visibility
    const [errorTitle, setErrorTitle] = useState(''); // Modal title
    const [errorMessage, setErrorMessage] = useState(''); // Modal message

    const handleCloseErrorModal = () => {
        setShowErrorModal(false);
    };

    useEffect(() => {
        if (!authToken) return;

        try {
            const { exp } = jwtDecode(authToken);
            const currentTime = Math.floor(Date.now() / 1000);
            const timeRemaining = exp - currentTime;

            if (timeRemaining <= 0) {
                // Handle immediate expiration
                if (!alertShown) {
                    setErrorTitle('Session Expired');
                    setErrorMessage('Your session has expired. Redirecting to login.');
                    setShowErrorModal(true);
                    sessionStorage.clear(); // Clear session storage
                    navigate('/'); // Redirect to login
                    setAlertShown(true);
                }
                return; // Stop further execution to prevent negative timeRemaining
            }

            // Schedule expiration or warning based on time remaining
            const timeout = setTimeout(() => {
                if (timeRemaining <= 3 * 60 && !alertShown) {
                    // Session about to expire
                    setErrorTitle('Session About to Expire');
                    setErrorMessage(`Your session is about to expire in ${timeRemaining} seconds.`);
                    setShowErrorModal(true);
                    setAlertShown(true);
                } else if (timeRemaining <= 0 && !alertShown) {
                    // Session expired
                    setErrorTitle('Session Expired');
                    setErrorMessage('Your session has expired. Redirecting to login.');
                    setShowErrorModal(true);
                    sessionStorage.clear(); // Clear session storage
                    navigate('/'); // Redirect to login
                    setAlertShown(true);
                }
            }, Math.max(0, timeRemaining * 1000));
            return () => clearTimeout(timeout); // Cleanup on unmount
        } catch (error) {
            console.error('Error decoding token:', error);
            // Handle invalid token case
            setErrorTitle('Invalid Session');
            setErrorMessage('Invalid session detected. Redirecting to login.');
            setShowErrorModal(true);
            sessionStorage.clear(); // Clear session storage
            navigate('/'); // Redirect to login
        }
    }, [authToken, navigate, alertShown]);

    return (
        <ErrorMessage
            show={showErrorModal}
            handleClose={handleCloseErrorModal}
            errorTitle={errorTitle}
            errorMessage={errorMessage}
        />
    );
}
