import React, { useEffect, useState } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import CommonNavbar from '../../components/common/CommonNavbar'
import SideMenu from '../../components/common/SideMenu'
import { controllerSideMenu } from '../../assets/sideMenuTabs'
import { ticketShortInfoStructure } from '../../assets/dataStructures/ticketsStructure'
import { useNavigate } from 'react-router-dom'
import { getApiResponse } from '../../utils/apiPromise'
import ControllerTicketCountCard from '../../components/cards/controllers/ControllerTicketCountCard'
import ControllerIssueTypeCard from '../../components/cards/controllers/ControllerIssueTypeCard'
import { ErrorMessage } from '../../modals/ErrorMessage'
import { IoAlertCircleSharp } from 'react-icons/io5'
import NoDataAvailable from '../../components/common/NoDataAvailable'
import DataLoader from '../../components/common/DataLoader'

const fetchTicketShorfInfoApi = process.env.REACT_APP_API_FETCH_TICKET_SHORT_INFO

export default function ControllerDashboard() {

  const [loading, setLoading] = useState(false); // Loader state

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorTitle, setErrorTitle] = useState('');

  const [fetchTicketsShortInfo, setFetchTicketsShortInfo] = useState(false)
  const [ticketsShortInfo, setTicketsShortInfo] = useState(ticketShortInfoStructure)

  const authToken = sessionStorage.getItem('authToken')
  const navigate = useNavigate()

  useEffect(() => {
    setFetchTicketsShortInfo(true)
  }, [])


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Show loader
      try {
        const shortInfoResponse = await getApiResponse(fetchTicketShorfInfoApi, authToken)
        setTicketsShortInfo([...shortInfoResponse.tickets])
      } catch (error) {
        setErrorTitle('Failed to Fetch Tickets');
        (error.message && setErrorMessage(error.message))
        setShowErrorModal(true);
      } finally {
        setLoading(false); // Hide loader
      }
    }
    if (fetchTicketsShortInfo) {
      fetchData()
    }
  }, [fetchTicketsShortInfo])

  const handleCloseErrorModal = () => {
    setShowErrorModal(false)
    navigate('/controller/dashboard')
  };


  return (
    <>
      <Row>
        <CommonNavbar />
      </Row>

      <Row>
        <Col md={2}>
          <SideMenu menu={controllerSideMenu} theme="dark" />
        </Col>

        <Col md={10}>
          <Row className="my-2 g-2 bg-light p-2" style={{ borderRadius: '1rem' }}>
            {loading ? <DataLoader /> : (
              <>
                {(ticketsShortInfo.length > 0) && (
                  <>
                    {/* In-Review Tickets */}
                    <Col xs={12} sm={6} md={4} lg={2}>
                      <ControllerTicketCountCard tickets={ticketsShortInfo} ticketType={'OPEN'} />
                    </Col>

                    {/* Reverted Tickets */}
                    <Col xs={12} sm={6} md={4} lg={2}>
                      <ControllerTicketCountCard tickets={ticketsShortInfo} ticketType={'REVERTED'} />
                    </Col>

                    {/* In Progress Tickets */}
                    <Col xs={12} sm={6} md={4} lg={2}>
                      <ControllerTicketCountCard tickets={ticketsShortInfo} ticketType={'PROGRESS'} />
                    </Col>

                    {/* Reverted to Dh Tickets */}
                    <Col xs={12} sm={6} md={4} lg={2}>
                      <ControllerTicketCountCard tickets={ticketsShortInfo} ticketType={'REVERTED TO DIVISION'} />
                    </Col>

                    {/* Reviewing by Dh Tickets */}
                    <Col xs={12} sm={6} md={4} lg={2}>
                      <ControllerTicketCountCard tickets={ticketsShortInfo} ticketType={'REVIEWING'} />
                    </Col>

                    {/* Closed Tickets */}
                    <Col xs={12} sm={6} md={4} lg={2}>
                      <ControllerTicketCountCard tickets={ticketsShortInfo} ticketType={'CLOSED'} />
                    </Col>
                  </>
                )}
              </>
            )}
          </Row>


          <Row className='my-2 bg-light p-2' style={{ borderRadius: '1rem' }}>
            <Col md={4}>
              {loading ? <DataLoader /> : (
                <>
                  {(ticketsShortInfo.length > 0) ? <ControllerIssueTypeCard tickets={ticketsShortInfo} />
                    : <NoDataAvailable />}
                </>
              )}
            </Col>
          </Row>

        </Col>
      </Row>

      <ErrorMessage show={showErrorModal} handleClose={handleCloseErrorModal} errorTitle={errorTitle} errorMessage={errorMessage} />
    </>
  )
}
