function capitalizeWords(text) {
  return text.replace(/\b\w/g, (char) => char.toUpperCase());
}

export function convertDuration(inputDuration) {
  switch (inputDuration) {
    case "beforeTodayCount":
      return "Less than 24 Hours";
    case "before7DaysCount":
      return "Less than 1 Week";
    case "before1MonthCount":
      return "Less than 1 Month";
    case "before3MonthsCount":
      return "Less than 3 Months";
    case "before6MonthsCount":
      return "Less than 6 Months";
    case "before1YearCount":
      return "Less than 1 Year";
    case "afterTodayCount":
      return "More than 24 Hours";
    case "after7DaysCount":
      return "More than 1 Week";
    case "after1MonthCount":
      return "More than 1 Month";
    case "after3MonthsCount":
      return "More than 3 Months";
    case "after6MonthsCount":
      return "More than 6 Months";
    case "after1YearCount":
      return "More than 1 Year";
    default:
      return capitalizeWords(inputDuration); // Return the original role if no match is found
  }
}
