import React from 'react'
import { Button } from 'react-bootstrap';

export default function ControllerTicketCountCard({ tickets, ticketType }) {
    const openTicketsCount = tickets.filter(ticket => ticket.ticketStatus === 'OPEN').length;
    const progressTicketsCount = tickets.filter(ticket => ticket.ticketStatus === 'PROGRESS').length;
    const reviewingTicketsCount = tickets.filter(ticket => ticket.ticketStatus === 'REVIEWING').length;
    const revertedTicketsCount = tickets.filter(ticket => ticket.ticketStatus === 'REVERTED' && ticket.ticketType === null).length;
    const revertedToDhTicketsCount = tickets.filter(ticket => ticket.ticketStatus === 'REVERTED' && ticket.ticketType !== null).length;
    const closedTicketsCount = tickets.filter(ticket => ticket.ticketStatus === 'CLOSED').length;

    return (
        <>
            {ticketType === 'OPEN' ? (
                <Button variant='success' className='w-100 h-100'>
                    <h1>{openTicketsCount}</h1>
                    <p>UNASSIGNED TICKETS</p>
                </Button>
            ) : null}
            {ticketType === 'PROGRESS' ? (
                <Button variant='warning' className='w-100 h-100'>
                    <h1>{progressTicketsCount}</h1>
                    <p>TICKETS<br />WITH OFFICERS</p>
                </Button>
            ) : null}
            {ticketType === 'CLOSED' ? (
                <Button variant='danger' className='w-100 h-100'>
                    <h1>{closedTicketsCount}</h1>
                    <p>CLOSED TICKETS</p>
                </Button>
            ) : null}
            {ticketType === 'REVERTED' ? (
                <Button variant='info' className='w-100 h-100'>
                    <h1>{revertedTicketsCount}</h1>
                    <p>REVERTED TO HELP DESK</p>
                </Button>
            ) : null}
            {ticketType === 'REVERTED TO DIVISION' ? (
                <Button variant='primary' className='w-100 h-100'>
                    <h1>{revertedToDhTicketsCount}</h1>
                    <p>REVERTED TO DIVISION</p>
                </Button>
            ) : null}
            {ticketType === 'REVIEWING' ? (
                <Button variant='secondary' className='w-100 h-100'>
                    <h1>{reviewingTicketsCount}</h1>
                    <p>TICKETS<br />UNDER REVIEW</p>
                </Button>
            ) : null}

        </>
    )
}
