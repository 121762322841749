import React from 'react'
import { Spinner } from 'react-bootstrap'

export default function DataLoader() {
    return (
        <div className="d-flex flex-column justify-content-center align-items-center">
            <Spinner animation="border" role="status" className="mb-3" />
            <p className="mb-0 text-center">Fetching... <br />Please Wait... </p>
        </div>
    )
}
