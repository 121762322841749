import React, { useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import CommonNavbar from "../../components/common/CommonNavbar";
import SideMenu from "../../components/common/SideMenu";
import { handlerSideMenu } from "../../assets/sideMenuTabs";
import { getApiResponse } from "../../utils/apiPromise";
import HandlerTicketCountCard from "../../components/cards/handlers/HandlerTicketCountCard";
import HandlerDeveloperStats from "../../components/cards/handlers/HandlerDeveloperStats";
import HandlerTicketCounts from "../../components/cards/handlers/HandlerTicketCounts";
import { HandlerPendingTicketsDurationWise } from "../../components/cards/handlers/HandlerTicketsDurationStatusWise";
import HandlerTicketsDurationPieChart from "../../components/cards/handlers/HandlerTicketsDurationPieChart";
import { ticketShortInfoStructure } from "../../assets/dataStructures/ticketsStructure";
import { ErrorMessage } from "../../modals/ErrorMessage";
import DataLoader from "../../components/common/DataLoader";
import NoDataAvailable from "../../components/common/NoDataAvailable";

const fetchTicketShorfInfoApi = process.env.REACT_APP_API_FETCH_TICKET_SHORT_INFO


export default function HandlerDashboard() {

  const [loading, setLoading] = useState(false); // Loader state
  const [fetchTicketsShortInfo, setFetchTicketsShortInfo] = useState(false)
  const [ticketsShortInfo, setTicketsShortInfo] = useState(ticketShortInfoStructure)

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorTitle, setErrorTitle] = useState('');

  const authToken = sessionStorage.getItem('authToken')

  useEffect(() => {
    setFetchTicketsShortInfo(true)
  }, [])


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const shortInfoResponse = await getApiResponse(fetchTicketShorfInfoApi, authToken)
        setTicketsShortInfo([...shortInfoResponse.tickets])
      } catch (error) {
        setErrorTitle('Failed to Fetch Tickets Counts');
        (error.message && setErrorMessage(error.message))
        setShowErrorModal(true);
      } finally {
        setLoading(false); // Hide loader
      }
    }
    if (fetchTicketsShortInfo) {
      fetchData()
    }
  }, [fetchTicketsShortInfo])

  const handleCloseErrorModal = () => {
    setShowErrorModal(false)
  };

  return (
    <>
      <Row>
        <CommonNavbar />
      </Row>

      <Row>
        <Col md={2}>
          <SideMenu menu={handlerSideMenu} theme="dark" />
        </Col>

        <Col md={10}>
          <Row className='my-2 bg-light p-2' style={{ borderRadius: '1rem' }}>
            {loading ? <DataLoader /> : (
              <>
                {(ticketsShortInfo.length > 0) && (
                  <>
                    {/* In-Review Tickets */}
                    <Col md={3}>
                      <HandlerTicketCountCard tickets={ticketsShortInfo} ticketType={'REVIEWING'} />
                    </Col>

                    {/* In Progress Tickets */}
                    <Col md={3}>
                      <HandlerTicketCountCard tickets={ticketsShortInfo} ticketType={'PROGRESS'} />
                    </Col>

                    {/* Reverted Tickets */}
                    <Col md={3}>
                      <HandlerTicketCountCard tickets={ticketsShortInfo} ticketType={'REVERTED'} />
                    </Col>

                    {/* Closed Tickets */}
                    <Col md={3}>
                      <HandlerTicketCountCard tickets={ticketsShortInfo} ticketType={'CLOSED'} />
                    </Col>
                  </>
                )}
              </>
            )}
          </Row>


          <Row className='my-2'>
            <Col className='bg-light p-4' style={{ borderRadius: '1rem' }} md={8}>
              <h4>Developer's Tickets Status</h4><hr />
              {loading ? <DataLoader /> : (
                <>
                  {(ticketsShortInfo.length > 0) ? <HandlerDeveloperStats tickets={ticketsShortInfo} /> : <NoDataAvailable />}
                </>
              )}
            </Col>
            <Col className='bg-light p-4 ms-2' style={{ borderRadius: '1rem' }}>
              <h4>Pending Tickets - DurationWise</h4><hr />
              {loading ? <DataLoader /> : (
                <>
                  {(ticketsShortInfo.length > 0) ? <HandlerTicketsDurationPieChart tickets={ticketsShortInfo} /> : <NoDataAvailable />}
                </>
              )}
            </Col>
          </Row>

          <Row className='my-2 bg-light p-2' style={{ borderRadius: '1rem', display: 'flex', justifyContent: 'space-between' }}>
            <Col>
              <h4>Pending Tickets - Officers</h4><hr />
              {loading ? <DataLoader /> : (
                <>
                  {(ticketsShortInfo.length > 0) ? <HandlerPendingTicketsDurationWise tickets={ticketsShortInfo} /> : <NoDataAvailable />}
                </>
              )}
            </Col>
          </Row>
        </Col>
      </Row>

      <ErrorMessage show={showErrorModal} handleClose={handleCloseErrorModal} errorTitle={errorTitle} errorMessage={errorMessage} />

    </>
  );
}
