import React, { useState } from 'react'
import { Modal, Row, Col, Form, Button } from 'react-bootstrap';
import ticketTypes from '../assets/arrays/ticketTypes';
import { useControllerData } from '../contextapi/controllerContext';
import formatDate, { formatToJSDate } from '../utils/formatDate';
import { postApiResponse } from '../utils/apiPromise';

const updateControllerTicketApi = process.env.REACT_APP_API_UPDATE_CONTROLLER_TICKETS

export function ControllerTicketAssigner({ showAssignTicket, setShowAssignTicket, ticketDetails, developers }) {
  const [assignedDeveloper, setAssignedDeveloper] = useState()

  const authToken = sessionStorage.getItem('authToken')

  const handleClose = () => {
    setAssignedDeveloper(undefined)
    setShowAssignTicket(false);
  };

  const handleUpdates = async () => {
    if (assignedDeveloper.developerId === 0 || assignedDeveloper.developerId === undefined) {
      alert("Please select an officer before updating.");
      setShowAssignTicket(false);
      return; // Exit the function without updating updatedChanges
    }
    if (assignedDeveloper.typeId === 0 || assignedDeveloper.typeId === undefined) {
      alert("Please select a division before updating.");
      setShowAssignTicket(false);
      return; // Exit the function without updating updatedChanges
    }


    try {
      const resp = await postApiResponse(updateControllerTicketApi, {
        arr: [{
          ticketId: ticketDetails.ticketId,
          assignedTo: assignedDeveloper.developerId,
          ticketType: assignedDeveloper.typeId
        }]
      }, authToken)
      alert(resp.data.message)
      handleClose()
    } catch (error) {
      alert(error.message)
    }
  }

  let filteredDevelopers

  const filterDevelopers = () => {
    if (assignedDeveloper && assignedDeveloper.typeId === "DHUPC II") {
      // Apply filter only for typeId "DHUPC II"
      return developers.filter(developer => {
        return developer.viewTicketType && developer.viewTicketType.includes(ticketDetails.complainantRoleId);
      });
    } else {
      // Include all developers if assignedDeveloper.typeId is not "DHUPC II"
      return developers.filter(developer => {
        return developer.viewTicketType && !/[1-9]/.test(developer.viewTicketType);
      });

    }
  };


  if (ticketDetails) {
    filteredDevelopers = filterDevelopers()

  }

  // When developer is selected from the list
  const handleChange = (event) => {
    const { id, value, type, name } = event.target;
    setAssignedDeveloper({ ...assignedDeveloper, [id]: value })
  }


  return (
    <>
      {ticketDetails ? (
        <>
          <Modal size='lg' show={showAssignTicket} onHide={handleClose}>
            <Form onSubmit={handleUpdates}>
              <Modal.Header closeButton>
                <Modal.Title>
                  {ticketDetails.ticketId}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row className="d-flex justify-content-between p-1">
                  <Col md={4}>Ticket Status</Col>
                  <Col>{ticketDetails.ticketStatus}</Col>
                </Row>
                <hr className="mt-0 mb-2" />

                <Row className="d-flex justify-content-between p-1">
                  <Col md={4}>Generated On</Col>
                  <Col>{formatToJSDate(ticketDetails.openDate)}</Col>
                </Row>
                <hr className="mt-0 mb-2" />

                <Row className="d-flex justify-content-between p-1">
                  <Col md={4}>Issue</Col>
                  <Col>{ticketDetails.issue}</Col>
                </Row>
                <hr className="mt-0 mb-2" />

                <Row className="d-flex justify-content-between p-1">
                  <Col md={4}>Issue Category</Col>
                  <Col>{ticketDetails.issueCategory}</Col>
                </Row>
                <hr className="mt-0 mb-2" />

                <Row className="d-flex justify-content-between p-1">
                  <Col md={4}>Issue Subcategory</Col>
                  <Col>{ticketDetails.issueSubcategory}</Col>
                </Row>
                <hr className="mt-0 mb-2" />

                <Row className="d-flex justify-content-left p-1">
                  <Col md={4}>Assign to</Col>
                  <Col md={4}>
                    <Form.Select required id='typeId' onChange={handleChange} style={{ width: '200px' }}>
                      <option key={0} value={0}>Select a Division...</option>
                      {ticketTypes.map((type, index) => (
                        <option key={index + 1} value={type}>
                          {type}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please select a Division.
                    </Form.Control.Feedback>
                  </Col>

                  {assignedDeveloper ? (
                    <>
                      {filteredDevelopers ? (
                        <Col md={4}>
                          <Form.Select required id='developerId' onChange={handleChange} style={{ width: '200px' }}>
                            <option key={0} value={0}>Select an officer...</option>
                            {filteredDevelopers.map((developer, index) => (
                              <option key={index + 1} value={developer.userId}>
                                {developer.userName}
                              </option>
                            ))}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            Please select a Developer.
                          </Form.Control.Feedback>
                        </Col>
                      ) : null}
                    </>
                  ) : null}
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button type='submit' variant="primary">Update</Button>
              </Modal.Footer>
            </Form>
          </Modal>
        </>
      ) : null}
    </>
  );
}


export default function ControllerAssignTicket({ showAssignTicket, setShowAssignTicket, ticketDetails }) {
  const { updatedChanges, setUpdatedChanges, developers } = useControllerData();
  const [assignedDeveloper, setAssignedDeveloper] = useState()

  const handleClose = () => {
    setAssignedDeveloper(undefined)
    setShowAssignTicket(false);
  };


  // To store assigned ticket details in updateChanges array
  const handleUpdate = () => {
    if (assignedDeveloper.developerId === 0 || assignedDeveloper.developerId === undefined) {
      alert("Please select an officer before updating.");
      setShowAssignTicket(false);
      return; // Exit the function without updating updatedChanges
    }
    if (assignedDeveloper.typeId === 0 || assignedDeveloper.typeId === undefined) {
      alert("Please select a division before updating.");
      setShowAssignTicket(false);
      return; // Exit the function without updating updatedChanges
    }


    const index = updatedChanges.findIndex(item => item.ticketId === ticketDetails.ticketId);
    if (index !== -1) {
      setUpdatedChanges(prevUpdatedChanges => [
        ...prevUpdatedChanges.slice(0, index),
        {
          ...prevUpdatedChanges[index],
          assignedTo: assignedDeveloper.developerId,
          ticketType: assignedDeveloper.typeId
        },
        ...prevUpdatedChanges.slice(index + 1)
      ]);
    } else {
      setUpdatedChanges(prevUpdatedChanges => [...prevUpdatedChanges, {
        ticketId: ticketDetails.ticketId,
        assignedTo: assignedDeveloper.developerId,
        ticketType: assignedDeveloper.typeId
      }]);
    }
    setAssignedDeveloper(undefined)
    setShowAssignTicket(false);
  }

  let filteredDevelopers

  const filterDevelopers = () => {
    if (assignedDeveloper && assignedDeveloper.typeId === "DHUPC II") {
      // Apply filter only for typeId "DHUPC II"
      return developers.filter(developer => {
        return developer.viewTicketType && developer.viewTicketType.includes(ticketDetails.complainantRoleId);
      });
    } else {
      // Include all developers if assignedDeveloper.typeId is not "DHUPC II"
      return developers.filter(developer => {
        return developer.viewTicketType && !/[1-9]/.test(developer.viewTicketType);
      });

    }
  };


  if (ticketDetails) {
    filteredDevelopers = filterDevelopers()
  }

  // When developer is selected from the list
  const handleChange = (event) => {
    const { id, value, type, name } = event.target;
    setAssignedDeveloper({ ...assignedDeveloper, [id]: value })
  }


  return (
    <>
      {ticketDetails ? (
        <>
          <Modal size='lg' show={showAssignTicket} onHide={handleClose}>
            <Form onSubmit={handleUpdate}>
              <Modal.Header closeButton>
                <Modal.Title>
                  {ticketDetails.ticketId}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row className="d-flex justify-content-between p-1">
                  <Col md={4}>Ticket Status</Col>
                  <Col>{ticketDetails.ticketStatus}</Col>
                </Row>
                <hr className="mt-0 mb-2" />

                <Row className="d-flex justify-content-between p-1">
                  <Col md={4}>Generated On</Col>
                  <Col>{formatDate(ticketDetails.openDate)}</Col>
                </Row>
                <hr className="mt-0 mb-2" />

                <Row className="d-flex justify-content-between p-1">
                  <Col md={4}>Issue</Col>
                  <Col>{ticketDetails.issue}</Col>
                </Row>
                <hr className="mt-0 mb-2" />

                <Row className="d-flex justify-content-between p-1">
                  <Col md={4}>Issue Category</Col>
                  <Col>{ticketDetails.categoryName}</Col>
                </Row>
                <hr className="mt-0 mb-2" />

                <Row className="d-flex justify-content-between p-1">
                  <Col md={4}>Issue Subcategory</Col>
                  <Col>{ticketDetails.subcategoryName}</Col>
                </Row>
                <hr className="mt-0 mb-2" />

                <Row className="d-flex justify-content-left p-1">
                  <Col md={4}>Assign to</Col>
                  <Col md={4}>
                    <Form.Select required id='typeId' onChange={handleChange} style={{ width: '200px' }}>
                      <option key={0} value={0}>Select a Division...</option>
                      {ticketTypes.map((type, index) => (
                        <option key={index + 1} value={type}>
                          {type}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please select a Division.
                    </Form.Control.Feedback>
                  </Col>

                  {assignedDeveloper ? (
                    <>
                      {filteredDevelopers ? (
                        <Col md={4}>
                          <Form.Select required id='developerId' onChange={handleChange} style={{ width: '200px' }}>
                            <option key={0} value={0}>Select an officer...</option>
                            {filteredDevelopers.map((developer, index) => (
                              <option key={index + 1} value={developer.userId}>
                                {developer.userName}
                              </option>
                            ))}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            Please select a Developer.
                          </Form.Control.Feedback>
                        </Col>
                      ) : null}
                    </>
                  ) : null}


                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button type='submit' variant="primary">Update</Button>
              </Modal.Footer>
            </Form>
          </Modal>
        </>
      ) : null}
    </>
  );
}