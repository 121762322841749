import React, { useState } from 'react'
import { Row, Col, Form, InputGroup, Button } from 'react-bootstrap'
import { createUserStructure } from '../utils/createUserStructure'
import axios from 'axios'
import ticketTypes from '../assets/arrays/ticketTypes'
import roles from '../assets/arrays/roleTypes'
import { ErrorMessage } from '../modals/ErrorMessage'

const newUserApi = process.env.REACT_APP_API_NEW_USER

export default function CreateUserForm() {
    const [validated, setValidated] = useState(false);
    const [user, setUser] = useState(createUserStructure);

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorTitle, setErrorTitle] = useState('');

    const authToken = sessionStorage.getItem('authToken')
    const [fieldValidate, setFieldValidate] = useState({
        userName: true,
        emailId: true,
        phone: true,
        password: true,
        confirmPassword: true,
    });

    const roleTypes = roles.slice(1, -1)

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            axios.post(newUserApi, user, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authToken
                }
            })
                .then((response) => {
                    alert('User has been created')
                })
                .catch((error) => {
                    setErrorTitle('Failed to Create User');
                    setErrorMessage(error.response.data.message);
                    setShowErrorModal(true);
                })
        }
        setValidated(true);
    };

    const handleChange = (event) => {
        const { id, value, type, name } = event.target;
        const namePattern = /^[a-zA-Z\s]*$/;
        const passwordPattern = /^[a-zA-Z0-9@.\-]+$/;
        const phoneNumberPattern = /^[0-9]{10}$/; // Example pattern for 10-digit phone number
        // const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        let isValid

        if (type === 'radio' && name === 'roleType') {
            setUser({ ...user, userRoleId: value })
        } else if (id === 'emailId') {
            isValid = emailPattern.test(value) || value === ''
            setFieldValidate({ ...fieldValidate, [id]: isValid })
            setUser({ ...user, [id]: value })
        } else if (id === 'phone') {
            isValid = phoneNumberPattern.test(value) || value === ''
            setFieldValidate({ ...fieldValidate, [id]: isValid })
            setUser({ ...user, [id]: value })
        } else if (id === 'userName') {
            isValid = namePattern.test(value) || value === ''
            setFieldValidate({ ...fieldValidate, [id]: isValid })
            setUser({ ...user, [id]: value })
        } else if (id === 'viewTicketType') {
            // Split the selected value into an array
            const selectedValues = value.split(',');
            setUser({ ...user, [id]: selectedValues });
        } else {
            setUser({ ...user, [id]: value })
        }
    }

    const handleCloseErrorModal = () => {
        setShowErrorModal(false);
    };


    return (
        <>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-3">
                    {/* User Full Name */}
                    <Form.Group as={Col} md='4' controlId="userName" onChange={handleChange}>
                        <Form.Label>Full Name</Form.Label>
                        <InputGroup>
                            <Form.Control
                                type="text"
                                placeholder="Full Name"
                                isInvalid={!fieldValidate.userName}
                                autoComplete="off"
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Only Alphabets and Spaces allowed.
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>

                    {/* User Email */}
                    <Form.Group as={Col} md='4' controlId="emailId" onChange={handleChange}>
                        <Form.Label>Email Id</Form.Label>
                        <InputGroup hasValidation>
                            <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                            <Form.Control
                                type="email"
                                placeholder="email id"
                                aria-describedby="inputGroupPrepend"
                                isInvalid={!fieldValidate.emailId}
                                autoComplete="off"
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid email Id.
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>

                    {/* Phone Number */}
                    <Form.Group as={Col} md='4' controlId="phone" onChange={handleChange}>
                        <Form.Label>Contact Number</Form.Label>
                        <InputGroup hasValidation>
                            <InputGroup.Text id="inputGroupPrepend">+91</InputGroup.Text>
                            <Form.Control
                                type="text"
                                placeholder="XXXXX XXXXX"
                                aria-describedby="inputGroupPrepend"
                                isInvalid={!fieldValidate.phone}
                                autoComplete="off"
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid Contact Number.
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                </Row>

                <Row className='mb-3'>
                    <Col md={6}>
                        {/* Select Portal */}
                        <Form.Group controlId="portalId" onChange={handleChange}>
                            <Form.Label>Select the Portal</Form.Label>
                            <Form.Select aria-label="" required>
                                <option value="1">EPR Plastic</option>
                                <option value="2" disabled>E-Waste Management</option>
                                <option value="3" disabled>RTWQMS</option>
                                <option value="4" disabled>Bio Monitoring</option>
                                <option value="5" disabled>CEPI</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        {/* QA Status */}
                        <Form.Group controlId="qaRequired" onChange={handleChange}>
                            <Form.Label>QA Required / Not Required</Form.Label>
                            <Form.Select aria-label="" required>
                                <option value="1">Required</option>
                                <option value="0">Not Required</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>



                </Row>

                <Row className='mb-3'>
                    <Col md={6}>
                        {/* Select Role */}
                        <Form.Group controlId="userRoleId" onChange={handleChange}>
                            <Form.Label>Select the role type</Form.Label>
                            <div key={`userRole`}>
                                {roleTypes.map((role, index) => (
                                    <Form.Check
                                        key={index + 1}
                                        inline
                                        label={role.label}
                                        type='radio'
                                        name='roleType'
                                        id={role.value}
                                        value={index + 2}
                                        required={index === 0} // Make the first radio button required
                                    />
                                ))}
                            </div>
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        {/* Select the Division */}
                        {user.userRoleId === '3' ? (
                            <Form.Group controlId="viewTicketType" onChange={handleChange}>
                                <Form.Label>Select the Division of Manager</Form.Label>
                                <Form.Select name="ticketType">
                                    {ticketTypes.map(division => (
                                        <option value={division}>{division}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>

                        ) : null}
                    </Col>
                </Row>

                <Button type='submit' className='btn btn-primary'>Create User</Button>
            </Form>

            <ErrorMessage show={showErrorModal} handleClose={handleCloseErrorModal} errorTitle={errorTitle} errorMessage={errorMessage} />
        </>
    )
}
