import React from 'react'
import { Row, Col } from 'react-bootstrap'
import CreateUserForm from '../forms/CreateUserForm'
import CommonNavbar from '../components/common/CommonNavbar'
import ProfileCard from '../components/cards/ProfileCard'

export default function CreateUser() {
    return (
        <>
            <Row>
                <CommonNavbar />
            </Row>
            <Row>
                <Col md={2} className='text-center p-4 bg-dark text-white'>
                    {/* User Details */}
                    <ProfileCard />
                </Col>
                <Col md={10}>
                    <Row className='m-2'>
                        <CreateUserForm />
                    </Row>
                </Col>
            </Row>
        </>
    )
}
