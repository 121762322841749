import React from 'react'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid

export function BasicDataGrid({ data, columns, theme, gridHeight, gridWidth, onCellClick }) {
    return (
        <div
            className={`ag-theme-quartz${theme === 'dark' ? '-dark' : ''}`}
            style={{ height: (gridHeight ? gridHeight : '40vh'), width: (gridWidth ? gridWidth : '100%') }}
        >
            <AgGridReact
                rowData={data}
                columnDefs={columns}
                enableFilter={true}
                animateRows={true}
                defaultColDef={{
                    flex: 1, // Default proportional width
                    resizable: true, // Default manual resizing
                    sortable: true, // Enable sorting
                    filter: true, // Enable filtering
                }}
                onCellClicked={(params) => {
                    if (onCellClick) {
                        onCellClick(params); // Call the onCellClick handler if provided
                    }
                }}
            />
        </div>
    );
}

export function DataGridWithToolbar({ data, columns, theme, pagination, rowsPerPage, rowsPerPageSelector, gridHeight, gridWidth, onCellClick }) {
    const paginationPageSize = rowsPerPage ? rowsPerPage : 50;
    const paginationPageSizeSelector = rowsPerPageSelector ? rowsPerPageSelector : [20, 50, 100];

    return (
        <>
            <div
                className={`ag-theme-quartz${theme === 'dark' ? '-dark' : ''}`} // applying the Data Grid theme
                style={{ height: (gridHeight ? gridHeight : '60vh'), width: (gridWidth ? gridWidth : '80vw') }}
            >
                <AgGridReact
                    rowData={data}
                    columnDefs={columns}
                    pagination={pagination ? pagination : false}
                    paginationPageSize={paginationPageSize}
                    paginationPageSizeSelector={paginationPageSizeSelector}
                    animateRows={true}
                    defaultColDef={{
                        flex: 1, // Default proportional width
                        resizable: true, // Default manual resizing
                        sortable: true, // Enable sorting
                        filter: true, // Enable filtering
                    }}
                    onCellClicked={(params) => {
                        if (onCellClick) {
                            onCellClick(params); // Call the onCellClick handler if provided
                        }
                    }}
                />
            </div>
        </>
    )
}

export function DataGridWithSingleButton({ data, columns, theme, pagination, rowsPerPage, rowsPerPageSelector, dispatch, buttonProps, gridHeight, gridWidth, onCellClick }) {
    const defaultButtonProps = {
        variant: 'primary',
        size: 'sm',
        onClick: null
    };

    const combinedButtonProps = { ...defaultButtonProps, ...buttonProps };
    const updatedColumns = [
        ...columns,
        {
            headerName: 'Action',
            field: 'action',
            cellRenderer: (params) => (
                <Button
                    {...combinedButtonProps}
                    onClick={() => (combinedButtonProps.onClick ? combinedButtonProps.onClick(params) : null)}
                >
                    {combinedButtonProps.text}
                </Button>
            ),
            filter: false,
            sortable: false,
        },
    ];

    const paginationPageSize = rowsPerPage ? rowsPerPage : 50;
    const paginationPageSizeSelector = rowsPerPageSelector ? rowsPerPageSelector : [20, 50, 100];

    return (
        <div
            className={`ag-theme-quartz${theme === 'dark' ? '-dark' : ''}`}
            style={{ height: (gridHeight ? gridHeight : '60vh'), width: (gridWidth ? gridWidth : '80vw') }}
        >
            <AgGridReact
                rowData={data}
                columnDefs={updatedColumns}
                pagination={pagination ? pagination : false}
                paginationPageSize={paginationPageSize}
                paginationPageSizeSelector={paginationPageSizeSelector}
                animateRows={true}
                defaultColDef={{
                    flex: 1, // Default proportional width
                    resizable: true, // Default manual resizing
                    sortable: true, // Enable sorting
                    filter: true, // Enable filtering
                }}
                onCellClicked={(params) => {
                    if (onCellClick) {
                        onCellClick(params); // Call the onCellClick handler if provided
                    }
                }}
            />
        </div>
    );
}
// Following are the dummy buttonProps to be passed for above component
// const buttonProps = {
//     variant: 'danger',
//     text: <FaIndustry />,
//     onClick: (params) => {
//         console.log('Custom Button Clicked:', params.data);
//         dispatch({ type: 'VIEW_DETAILS', payload: params.data });
//     }
// };


export function DataGridWithMultipleButtons({
    data,
    columns,
    theme,
    pagination,
    rowsPerPage,
    rowsPerPageSelector,
    dispatch,
    buttonsProps = [], // Expecting an array of buttonProps with tooltips
    gridHeight,
    gridWidth,
    onCellClick,
}) {
    // Default buttonProps in case no buttons are passed
    const defaultButtonProps = {
        variant: "primary",
        size: "sm",
        onClick: null,
        tooltip: null, // Default tooltip to null
    };

    // Combine each buttonProps with defaultButtonProps
    const combinedButtonPropsArray = buttonsProps.map((buttonProps) => ({
        ...defaultButtonProps,
        ...buttonProps,
    }));

    // Add a new column for actions with multiple buttons
    const updatedColumns = [
        ...columns,
        {
            headerName: "Actions",
            field: "actions",
            cellRenderer: (params) => (
                <div className="d-flex gap-2">
                    {combinedButtonPropsArray.map((buttonProps, index) => (
                        <OverlayTrigger
                            key={index}
                            placement="top"
                            overlay={
                                buttonProps.tooltip ? (
                                    <Tooltip>{buttonProps.tooltip}</Tooltip>
                                ) : <></>
                            }
                        >
                            <Button
                                {...buttonProps}
                                onClick={() =>
                                    buttonProps.onClick
                                        ? buttonProps.onClick(params)
                                        : null
                                }
                            >
                                {buttonProps.text}
                            </Button>
                        </OverlayTrigger>
                    ))}
                </div>
            ),
            filter: false,
            sortable: false,
        },
    ];

    const paginationPageSize = rowsPerPage || 50;
    const paginationPageSizeSelector = rowsPerPageSelector || [20, 50, 100];

    return (
        <div
            className={`ag-theme-quartz${theme === "dark" ? "-dark" : ""}`}
            style={{
                height: gridHeight ? gridHeight : "60vh",
                width: gridWidth ? gridWidth : "80vw",
            }}
        >
            <AgGridReact
                rowData={data}
                columnDefs={updatedColumns}
                pagination={pagination || false}
                paginationPageSize={paginationPageSize}
                paginationPageSizeSelector={paginationPageSizeSelector}
                animateRows={true}
                defaultColDef={{
                    flex: 1,
                    resizable: true,
                    sortable: true,
                    filter: true,
                }}
                onCellClicked={(params) => {
                    if (onCellClick) {
                        onCellClick(params); // Call the onCellClick handler if provided
                    }
                }}
            />
        </div>
    );
}
