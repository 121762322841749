import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import CommonNavbar from "../../components/common/CommonNavbar";
import SideMenu from "../../components/common/SideMenu";
import { handlerSideMenu } from "../../assets/sideMenuTabs";
import { useNavigate } from "react-router-dom";
import { postApiResponse } from "../../utils/apiPromise";
import { DataGridWithMultipleButtons } from "../../components/datagrids/AgDataGrid";
import { IoAlertCircleSharp } from "react-icons/io5";
import { formatToJSDate } from "../../utils/formatDate";
import { ticketDetailedStructure } from "../../assets/dataStructures/ticketsStructure";
import { Spinner } from "react-bootstrap"; // Using Bootstrap's Spinner for simplicity
import { FaTicketAlt } from 'react-icons/fa';
import { TiMessages } from "react-icons/ti";
import { ControllerViewTicket } from "../../modals/ViewTicket";
import { ErrorMessage } from "../../modals/ErrorMessage";
import DataLoader from "../../components/common/DataLoader";
import NoDataAvailable from "../../components/common/NoDataAvailable";

const fetchStatusWiseTicketsApi = process.env.REACT_APP_API_FETCH_TICKETS_STATUSWISE;

export default function HandlerAllTickets() {
  const [fetchTickets, setFetchTickets] = useState(false);
  const [loading, setLoading] = useState(false); // Loader state
  const [allTickets, setAllTickets] = useState([ticketDetailedStructure]);

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorTitle, setErrorTitle] = useState('');

  const [showViewTicket, setShowViewTicket] = useState(false)
  const [selectedTicket, setSelectedTicket] = useState(null); // State for the selected ticket

  const authToken = sessionStorage.getItem("authToken");
  const navigate = useNavigate();

  useEffect(() => {
    setFetchTickets(true);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Show loader
      try {
        const ticketResponse = await postApiResponse(fetchStatusWiseTicketsApi, {
          ticketStatus: ['progress', 'reverted', 'reviewing', 'closed']
        }, authToken);
        setAllTickets([...ticketResponse.tickets]);
      } catch (error) {
        setErrorTitle('Failed to Fetch Tickets');
        (error.message && setErrorMessage(error.message))
        setShowErrorModal(true);
      } finally {
        setLoading(false); // Hide loader
      }
    };
    if (fetchTickets) {
      fetchData();
    }
  }, [authToken, fetchTickets]);

  const handleCloseErrorModal = () => {
    setShowErrorModal(false)
  };

  const buttonsProps =
    [
      {
        variant: 'secondary',
        text: <FaTicketAlt />,
        tooltip: "View Ticket", // Hover text
        onClick: (params) => {
          setSelectedTicket(params.data); // Set the selected ticket
          setShowViewTicket(true); // Open the modal
        }
      },
      {
        variant: 'primary',
        text: <TiMessages />,
        tooltip: "View Trail", // Hover text
        onClick: (params) => {
          navigate('/tickettrail', { state: { ticketId: params.data.ticketId } });

        }
      }
    ]

  const columns = [
    { field: 'ticketId', headerName: 'Ticket ID' },
    { field: 'ticketStatus', headerName: 'Ticket Status' },
    { field: 'openDate', headerName: 'Generated On', valueFormatter: (params) => formatToJSDate(params.value) },
    { field: 'issueCategory', headerName: 'Issue Type' },
    { field: 'developerName', headerName: 'Assigned To' }
  ];

  // const handleCellClick = (params) => {
  //   switch (params.colDef.field) {
  //     case 'ticketId':
  //       alert(`Ticket ID clicked ${params.value}`);
  //       // Add your logic for Ticket ID column here
  //       break;
  //     case 'ticketStatus':
  //       alert(`Ticket Status clicked: ${params.value}`);
  //       // Add your logic for Ticket Status column here
  //       break;
  //     case 'openDate':
  //       alert(`Generated On clicked: ${params.value}`);
  //       // Add your logic for Generated On column here
  //       break;
  //     case 'issueCategory':
  //       alert(`Issue Type clicked: ${params.value}`);
  //       // Add your logic for Issue Type column here
  //       break;
  //     case 'developerName':
  //       alert(`Assigned To clicked: ${params.value}`);
  //       // Add your logic for Assigned To column here
  //       break;
  //     default:
  //       alert(`Other column clicked ${params.value}`);
  //   }
  // };


  return (
    <>
      <Row>
        <CommonNavbar />
      </Row>

      <Row>
        <Col md={2}>
          <SideMenu menu={handlerSideMenu} theme="dark" />
        </Col>

        <Col md={10}>
          <h4 className="mt-2">All Tickets</h4><hr />
          {loading ? <DataLoader /> : (
            <>
              {allTickets.length > 0 ? (
                <DataGridWithMultipleButtons
                  data={allTickets}
                  columns={columns}
                  pagination="true"
                  rowsPerPage={20}
                  rowsPerPageSelector={[20, 50, 100]}
                  buttonsProps={buttonsProps}
                  // onCellClick={handleCellClick}
                  theme="light"
                  headerBgClass="bg-success text-white"
                />
              ) : <NoDataAvailable />}
            </>
          )}
        </Col>
      </Row>

      <ControllerViewTicket showViewTicket={showViewTicket} setShowViewTicket={setShowViewTicket} ticketDetails={selectedTicket} />
      <ErrorMessage show={showErrorModal} handleClose={handleCloseErrorModal} errorTitle={errorTitle} errorMessage={errorMessage} />

    </>
  );
}
