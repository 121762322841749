import React, { useState } from 'react'
import { useHandlerData } from '../../../contextapi/handlerContext';
import { Table } from 'react-bootstrap';
import { BasicDataGrid } from '../../datagrids/AgDataGrid';

export default function HandlerDeveloperStats({ tickets }) {
    const result = groupByDeveloperAndStatus(tickets);
    const columns = [
        { field: 'developerName', headerName: 'Officer Name' },
        { field: 'ticketStatuses.Progress', headerName: 'Action to be Taken by Officer', filter: false },
        { field: 'ticketStatuses.Reviewing', headerName: 'Under Review by DH', filter: false },
        { field: 'ticketStatuses.Closed', headerName: 'Closed by DH', filter: false },
    ]
    return (
        <>
            <BasicDataGrid
                data={result}
                columns={columns}
                theme='light' />
        </>
    )
}


const groupByDeveloperAndStatus = (tickets) => {
    const developerMap = {};
    tickets.forEach(({ developerName, ticketStatus }) => {
        // Skip tickets where developerName is null
        if (!developerName) return;

        // Check if developer already exists in the map
        if (!developerMap[developerName]) {
            developerMap[developerName] = {
                developerName,
                ticketStatuses: {
                    Progress: 0,
                    Reviewing: 0,
                    Closed: 0,
                },
            };
        }

        // Increment the corresponding ticketStatus count
        const statusKey = ticketStatus[0].toUpperCase() + ticketStatus.slice(1).toLowerCase();
        if (developerMap[developerName].ticketStatuses[statusKey] !== undefined) {
            developerMap[developerName].ticketStatuses[statusKey]++;
        }
    });

    // Convert the map to an array
    return Object.values(developerMap);
};