import React, { useState } from 'react'
import { Button, Row, Col, Form, InputGroup } from 'react-bootstrap';
import plasticCategories from '../assets/arrays/plasticCategories'
import plasticSubcategories from '../assets/arrays/plasticSubcategories'
import portalName from '../assets/arrays/portalName'
import ComplainantLoginPrompt from '../modals/ComplainantLoginPrompt';
import { useNavigate } from 'react-router-dom';
import { useComplaint } from '../contextapi/complaintContext';
import { ErrorMessage, SuccessMessage } from '../modals/ErrorMessage';
import { multipartApiResponse } from '../utils/apiPromise';

let catId = null
const generateTicketApi = process.env.REACT_APP_API_NEW_TICKET

export default function LodgeComplaint() {
    const [showLoginModal, setShowLoginModal] = useState(false)
    const { complaint, setComplaint } = useComplaint()
    const authToken = sessionStorage.getItem('authToken') // Fetch AuthToken from sessionStorage
    const navigate = useNavigate()
    const [validated, setValidated] = useState(false)
    const [fieldValidate, setFieldValidate] = useState({
        emailId: true,
    });
    const [errorNavigation, setErrorNavigation] = useState('/')
    const [successNavigation, setSuccessNavigation] = useState('/')

    // For Error Modal
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorTitle, setErrorTitle] = useState('');

    // For Success Modal
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [successTitle, setSuccessTitle] = useState('');


    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault()
            if (!authToken) {
                setShowLoginModal(true)
            } else {
                try {
                    const ticketResponse = await multipartApiResponse(generateTicketApi, complaint, authToken)
                    setSuccessTitle('Complaint has been lodged successfully');
                    (ticketResponse.ticketId && setSuccessMessage(`Complaint has been lodged and ticket id is ${ticketResponse.ticketId}`))
                    setShowSuccessModal(true);
                    setSuccessNavigation('/complainant')
                } catch (error) {
                    setErrorTitle('Failed to Lodge Ticket');
                    (error.message && setErrorMessage(error.message))
                    setErrorNavigation('/complainant')
                    setShowErrorModal(true);
                }
            }
        }
        setValidated(true);
    };


    const handleCloseErrorModal = () => {
        setShowErrorModal(false);
        navigate(errorNavigation)
    };

    const handleCloseSuccessModal = () => {
        setShowSuccessModal(false);
        navigate(successNavigation)
    };

    const handleChange = (event) => {
        const { id, value, type, name } = event.target;
        // const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const forbiddenRegex = /<script>|\.exe/i;
        let isValid

        if (type === 'radio' && name === 'portalId') {
            setComplaint({ ...complaint, portalId: value })
        } else if (type === 'radio' && name === 'issueCategoryId') {
            setComplaint({ ...complaint, issueCategoryId: value })
            catId = value
        } else if (type === 'radio' && name === 'issueSubategoryId') {
            setComplaint({ ...complaint, issueSubcategoryId: value })
        } else if (id === 'emailId') {
            isValid = emailPattern.test(value) || value === ''
            setFieldValidate({ ...fieldValidate, emailId: isValid })
            setComplaint({ ...complaint, emailId: value })
        } else if (id === 'issue') {
            isValid = forbiddenRegex.test(value)
            if (isValid) {
                setComplaint({ ...complaint, [id]: 'XSS Attack Identified' })
            } else {
                setComplaint({ ...complaint, [id]: value })
            }
        } else {
            setComplaint({ ...complaint, [id]: value })
        }
    }

    const handlePdfUpload = (event) => {
        const file = event.target.files[0]; // Get the selected file
        let fileName = file.name;
        const maxSize = 2 * 1024 * 1024; // 2MB in bytes

        // Rename the file to remove double extensions like .jpg.pdf or .jpeg.pdf
        const lastDotIndex = fileName.lastIndexOf('.');
        const extension = fileName.substring(lastDotIndex + 1).toLowerCase();

        if (extension !== 'pdf') {
            // Remove the double extension
            fileName = fileName.substring(0, lastDotIndex);
            const lastDotIndexNew = fileName.lastIndexOf('.');
            const newExtension = fileName.substring(lastDotIndexNew + 1).toLowerCase();
            if (newExtension !== 'pdf') {
                // If the new extension is not PDF, show an error message and return
                setErrorTitle('File Missing');
                setErrorMessage('Please select a PDF file to upload.')
                setShowErrorModal(true);
                event.target.value = ''; // Clear the file input
                return;
            }
        }

        // Check if file size exceeds 2MB
        if (file && file.size > maxSize) {
            setErrorTitle('Exceeding File Size');
            setErrorMessage('File size exceeds 2MB. Please select a smaller file.')
            setShowErrorModal(true);
            event.target.value = null; // Reset the file input
            return;
        }

        // Add your logic to handle the PDF file upload here, such as storing it in state or sending it to the server
        // For example, you can use FileReader to read the contents of the file
        // const reader = new FileReader();
        // reader.onload = (e) => {
        //     const fileContent = e.target.result;
        //     console.log("File Content:", fileContent);
        //     // You can then store or process the file content as needed
        // };
        // reader.readAsText(file); // Read the file as text

        // You can also update the form state with the selected file information if needed
        // For example:
        setComplaint({ ...complaint, screenshots: file });
        console.log(complaint)
    };


    return (
        <>
            <Form style={{ marginLeft: '20px' }} noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-5">

                    <Col md={6}>
                        {/* Email ID */}
                        <Form.Group controlId="emailId" onChange={handleChange}>
                            <Form.Label>Email Id</Form.Label>
                            <InputGroup>
                                <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                                <Form.Control
                                    type="email"
                                    placeholder="example@gmail.com"
                                    aria-describedby="inputGroupPrepend"
                                    value={complaint.emailId}
                                    isInvalid={!fieldValidate.emailId}
                                    autoComplete="off"
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please enter a valid Email Id.
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    </Col>

                    {/* Upload PDF */}
                    <Col md={6}>
                        <Form.Group controlId="screenshots" onChange={handlePdfUpload}>
                            <Form.Label>Upload Screenshots of the Issue<span style={{ fontSize: '13px' }}> (Only .pdf)</span></Form.Label>
                            <Form.Control type="file" accept=".pdf" autoComplete="off" required />
                        </Form.Group>
                    </Col>
                </Row>

                <Row className='mb-5'>
                    <Col>
                        {/* Select Portal */}
                        <Form.Group controlId="portalId" onChange={handleChange}>
                            <Form.Label>Select the portal</Form.Label>
                            <div></div>
                            {portalName
                                .map((portal, index) => (
                                    <Form.Check
                                        inline
                                        key={index + 1}
                                        disabled={portal.portalStatus}
                                        label={portal.portalName}
                                        name="portalId"
                                        type={`radio`}
                                        id='portalId'
                                        value={`${index + 1}`}
                                        required={index === 0} // Make the first radio button required
                                    />
                                ))}
                            <Form.Control.Feedback type="invalid">
                                Please enter a valid Email Id.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                <Row className='mb-3'>
                    {/* Issue Category */}
                    <Col md={6}>
                        <Form.Group controlId="issueCategoryId" onChange={handleChange}>
                            <Form.Label>Select the Issue from the following</Form.Label>
                            {plasticCategories
                                .map((cat, index) => (
                                    <Form.Check
                                        key={index + 1}
                                        label={cat}
                                        name='issueCategoryId'
                                        type='radio'
                                        id={`issueCategoryId_${index + 1}`}
                                        value={`${index + 1}`}
                                        required={index === 0} // Make the first radio button required
                                    />
                                ))}
                            <Form.Control.Feedback type="invalid">
                                Please enter a valid Email Id.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    {/* Issue Subcategory */}
                    {(catId === null) ? null : (
                        <Col md={6}>
                            <Form.Group controlId="issueSubcategoryId" onChange={handleChange}>
                                <Form.Label>Select the subcategory from the following</Form.Label>
                                {plasticSubcategories
                                    .filter(subcat => subcat.catId === parseInt(catId))
                                    .map((subcat, index) => (
                                        <Form.Check
                                            key={index + 1}
                                            label={subcat.subCat}
                                            name='issueSubcategoryId'
                                            type={`radio`}
                                            value={`${subcat.subCatId}`}
                                            required={index === 0} // Make the first radio button required
                                        />
                                    ))}
                                <Form.Control.Feedback type="invalid">
                                    Please enter a valid Email Id.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    )}
                </Row>


                <Row className='mb-3'>
                    {/* Issue */}
                    <Form.Group as={Col} md="12" controlId="issue" onChange={handleChange}>
                        <Form.Label>Issue:</Form.Label>
                        <Form.Control
                            as="textarea"
                            placeholder="Elaborate your issue here (Optional)"
                            style={{ height: '20vh' }}
                            autoComplete="off"
                        />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                </Row>

                <Button type="submit" className='btn btn-dark'>Generate Ticket</Button>
            </Form>

            {showLoginModal ?
                <ComplainantLoginPrompt setShowLoginModal={setShowLoginModal} emailId={complaint.emailId} />
                : null}

            <ErrorMessage show={showErrorModal} handleClose={handleCloseErrorModal} errorTitle={errorTitle} errorMessage={errorMessage} />

            <SuccessMessage show={showSuccessModal} handleClose={handleCloseSuccessModal} successTitle={successTitle} successMessage={successMessage} />
        </>
    )
}
