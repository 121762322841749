import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Common Components
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";

// Forms Views
import CreateUser from "./views/CreateUser";

//Dashboards Views
import Homescreen from "./views/Homescreen";
import AdminDashboard from "./views/AdminDashboard";
import ControllerDashboard from "./views/ControllerDashboard";
import HandlerDashboard from "./views/HandlerDashboard";
import ComplainantDashboard from "./views/ComplainantDashboard";

import {
  AdminDevelopersProvider,
  AdminHandlersProvider,
} from "./contextapi/adminContext";
import { ControllerDataProvider } from "./contextapi/controllerContext";
import { ComplaintProvider } from "./contextapi/complaintContext";
import { UserDetailsProvider } from "./contextapi/userDetails";

import DeveloperDashboard from "./views/DeveloperDashboard";
import OrganisationHome from "./views/OrganisationHome";
import ChangePassword from "./views/ChangePassword";
import ApiView from "./views/ApiView";
import TicketTrailView from "./views/TicketTrailView";
import HandlerRoutes from "./routes/HandlerRoutes";
import OfficerRoutes from "./routes/OfficerRoutes";
import ControllerRoutes from "./routes/ControllerRoutes";
import SessionExpiryAlert from "./modals/SessionExpiryAlert";
import { useEffect, useState } from "react";

function App() {
  const authToken = sessionStorage.getItem('authToken'); // Example, adjust as per your app
  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    // Update the key every 1 minute (60,000 ms)
    const interval = setInterval(() => {
      setRefreshKey((prevKey) => prevKey + 1);
    }, 60 * 1000);

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  return (
    <>

      <Header />
      <UserDetailsProvider>
        <AdminHandlersProvider>
          <AdminDevelopersProvider>
            <ControllerDataProvider>
              <ComplaintProvider>
                <Router>
                  <SessionExpiryAlert key={refreshKey} authToken={authToken} />
                  <Routes>
                    <Route exact path="/" element={<Homescreen />} />
                    <Route exact path="/admin" element={<AdminDashboard />} />
                    <Route exact path="/newuser" element={<CreateUser />} />
                    {/* <Route
                      exact
                      path="/controller"
                      element={<ControllerDashboard />}
                    /> */}
                    {/* <Route
                      exact
                      path="/handler"
                      element={<HandlerDashboard />}
                    /> */}
                    {/* <Route
                      exact
                      path="/officer"
                      element={<DeveloperDashboard />}
                    /> */}
                    <Route
                      exact
                      path="/complainant"
                      element={<ComplainantDashboard />}
                    />
                    <Route
                      exact
                      path="/organisation"
                      element={<OrganisationHome />}
                    />
                    <Route
                      exact
                      path="/passwordreset"
                      element={<ChangePassword />}
                    />
                    <Route
                      exact
                      path="/tickettrail"
                      element={<TicketTrailView />}
                    />
                    <Route exact path="/apiview" element={<ApiView />} />
                    {HandlerRoutes()}
                    {OfficerRoutes()}
                    {ControllerRoutes()}
                  </Routes>
                </Router>
              </ComplaintProvider>
            </ControllerDataProvider>
          </AdminDevelopersProvider>
        </AdminHandlersProvider>
      </UserDetailsProvider>
      <Footer />
    </>
  );
}

export default App;
