import React from 'react'
import { Modal } from 'react-bootstrap';
import UserLogin from '../forms/UserLogin';


export default function LoginPrompt({show, setShow}) {
    
    const handleClosePrompt = () =>{
      setShow(false)
    }
    
    return (
      <>
        <Modal show={show} onHide={handleClosePrompt}>
          <Modal.Header closeButton>
            <Modal.Title>Login Required</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <UserLogin setShow={setShow} />
          </Modal.Body>
        </Modal>
      </>
    )
  }
