import React from 'react'
import './loading.css'

export default function Loading() {
  return (
    <div className="loading-spinner-container">
      <div className="loading-spinner"></div>
    </div>
  )
}
