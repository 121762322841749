import React from 'react'
import { Row, Col } from 'react-bootstrap'
import AdminTicketCountCard from './AdminTicketCountCard'
import AdminDeveloperStats from './AdminDeveloperStats'
import AdminHandlerStatus from './AdminHandlerStatus'
import AdminTicketCounts from './AdminTicketCounts'
import AdminCategoryWiseTickets from './AdminCategoryWiseTickets'
import AdminTickets from './AdminTickets'
import CreateUserForm from '../../../forms/CreateUserForm'
import UpdateUserStatus from '../../../views/UpdateUserStatus'
import NoPreview from '../../common/NoPreview'

export default function AdminBody({ sidebarIndex }) {
    return (
        <>
            {sidebarIndex === 0 ? (
                <>
                    <Row className='m-2 bg-light p-4' style={{ borderRadius: '1rem', display: 'flex', justifyContent: 'space-between' }}>
                        <Col>
                            <AdminTicketCountCard type={'OPEN'} />
                        </Col>
                        <Col>
                            <AdminTicketCountCard type={'PROGRESS'} />
                        </Col>
                        <Col>
                            <AdminTicketCountCard type={'REVIEWING'} />
                        </Col>
                        <Col>
                            <AdminTicketCountCard type={'CLOSED'} />
                        </Col>
                    </Row>

                    <Row className='m-2 bg-light p-4' style={{ borderRadius: '1rem', display: 'flex', justifyContent: 'space-between' }}>
                        <Col md={6}>
                            <AdminDeveloperStats />
                            <AdminHandlerStatus />
                        </Col>
                        <Col md={6}>
                            <AdminTicketCounts />
                        </Col>
                    </Row>

                    <Row className='m-2 bg-light p-4' style={{ borderRadius: '1rem', display: 'flex', justifyContent: 'space-between' }}>
                        <Col>
                            <AdminCategoryWiseTickets />
                        </Col>
                    </Row>

                    <Row>
                        <AdminTickets />
                    </Row>
                </>
            ) : null}


            {sidebarIndex === 1 ? (
                <>
                    <Row className='m-2 bg-light p-4' style={{ borderRadius: '1rem', display: 'flex', justifyContent: 'space-between' }}>
                        <h4>Create New User</h4><hr />
                        <CreateUserForm />
                    </Row>
                </>
            ) : null}


            {sidebarIndex === 2 ? (
                <>
                    <Row className='m-2 bg-light p-4' style={{ borderRadius: '1rem', display: 'flex', justifyContent: 'space-between' }}>
                        <UpdateUserStatus />
                    </Row>
                </>
            ) : null}
        </>
    )
}
