import React, { useEffect, useState } from 'react'
import { Table, Button, ProgressBar } from 'react-bootstrap'
import { ErrorMessage } from '../../../modals/ErrorMessage';
import sampleCategoryTickets from '../../../assets/arrays/sampleCategoryTickets'
import { getApiResponse } from '../../../utils/apiPromise';

const fetchCategoryWiseTickestsApi = process.env.REACT_APP_API_FETCH_ADMIN_CATEGORY_TICKETS

export default function AdminCategoryWiseTickets() {

    const [fetchCategoryTickets, setFetchCategoryTickets] = useState(false)

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorTitle, setErrorTitle] = useState('');

    const [categoryTickets, setCategoryTickets] = useState(sampleCategoryTickets)

    const authToken = sessionStorage.getItem('authToken')

    useEffect(() => {
        setFetchCategoryTickets(true)
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const ticketsResponse = await getApiResponse(fetchCategoryWiseTickestsApi, authToken)
                setCategoryTickets(ticketsResponse.categoryTickets)
            } catch (error) {
                setErrorTitle('Failed to Fetch');
                setErrorMessage(error.response.data.message);
                setShowErrorModal(true);
            }
        }
        if (fetchCategoryTickets) {
            fetchData()
        }
    }, [fetchCategoryTickets])


    const handleCloseErrorModal = () => {
        setShowErrorModal(false);
    };

    // Initialize an object to store the ticket counts for each categoryName and ticketStatus
    const ticketCounts = categoryTickets.reduce((counts, ticket) => {
        const { categoryName, ticketStatus } = ticket;
        // Check if the categoryName already exists in the counts object
        if (!counts[categoryName]) {
            counts[categoryName] = {};
        }
        // Check if the ticketStatus already exists in the counts object under the categoryName
        if (!counts[categoryName][ticketStatus]) {
            counts[categoryName][ticketStatus] = 0;
        }
        // Increment the count for the corresponding categoryName and ticketStatus
        counts[categoryName][ticketStatus]++;
        return counts;
    }, {});

    return (
        <>
            <Table hover>
                <thead>
                    <tr>
                        <th>Category Name</th>
                        {/* <th style={{ width: '160px', textAlign: 'center' }}>Tickets Generated</th> */}
                        <th style={{ width: '120px', textAlign: 'center' }}>Unassigned</th>
                        <th style={{ width: '200px', textAlign: 'center' }}>With Officer/Developer</th>
                        <th style={{ width: '180px', textAlign: 'center' }}>With Divisional Head</th>
                        <th style={{ width: '160px', textAlign: 'center' }}>Tickets Closed</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(ticketCounts).map(([category, statusCounts]) => (
                        <tr key={`${category}-${statusCounts}`}>
                            <td>{category}</td>
                            {/* <td style={{ textAlign: 'center' }}>{Object.values(statusCounts).reduce((acc, count) => acc + (count || 0), 0)}</td> */}
                            <td style={{ textAlign: 'center' }}>{statusCounts['OPEN'] ? statusCounts['OPEN'] : "0"}</td>
                            <td style={{ textAlign: 'center' }}>{statusCounts['PROGRESS'] ? statusCounts['PROGRESS'] : "0"}</td>
                            <td style={{ textAlign: 'center' }}>{statusCounts['REVIEWING'] ? statusCounts['REVIEWING'] : "0"}</td>
                            <td style={{ textAlign: 'center' }}>{statusCounts['CLOSED'] ? statusCounts['CLOSED'] : "0"}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <ErrorMessage show={showErrorModal} handleClose={handleCloseErrorModal} errorTitle={errorTitle} errorMessage={errorMessage} />
        </>
    )
}
