const sampleCategoryTickets = [
    {
        ticketId: '',
        ticketStatus: '',
        updateDate: '',
        categoryName: '',
        subcategoryName: ''
    }
]

export default sampleCategoryTickets