import React, { useState } from 'react'
import { Button, Container, Nav, Navbar, Dropdown } from 'react-bootstrap'
import LoginPrompt from '../../modals/LoginPrompt'
import { Link, useNavigate } from 'react-router-dom'


export default function CommonNavbar() {
  const [show, setShow] = useState(false); // To show LoginPrompt
  const navigate = useNavigate();
  const handleLogout = () => {
    sessionStorage.removeItem('authToken')
    sessionStorage.removeItem('userRole')
    navigate('/')
  }
  let authToken = sessionStorage.getItem('authToken')
  const userRole = sessionStorage.getItem('userRole')
  return (
    <>
      <Navbar expand="lg" bg='primary' data-bs-theme="dark">
        {/* {userRole ? (
                <Link to={`/${userRole}`} style={{ textDecoration: 'none' }}><Button>Dashboard</Button></Link>
              ) : null} */}
        <Container>
          <Navbar.Brand href="/">Ticket Tracker</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Link to="/" style={{ textDecoration: 'none' }}>
                <Button>Home</Button>
              </Link>
            </Nav>

            <Nav className="d-flex">
              {authToken ? (
                <>
                  <Button disabled>Logged in as {userRole}</Button>
                  <Nav.Link>
                    <Button className="btn btn-dark" onClick={() => handleLogout()}>
                      Logout
                    </Button>
                  </Nav.Link>
                </>
              ) : (
                <>
                  <Dropdown>
                    <Dropdown.Toggle variant="dark" id="login-dropdown">
                      Login
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => setShow(true)}>
                        Login as Complainant
                      </Dropdown.Item>
                      <Dropdown.Item as={Link} to="/organisation">
                        Organisation Login
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </>
              )}
            </Nav>
            <LoginPrompt show={show} setShow={setShow} />
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}
