// // import { useJwt } from 'react-jwt'


// Function to generate a random AES-256 key
function generateKey() {
    return window.crypto.subtle.generateKey(
        { name: 'AES-GCM', length: 256 },
        true,
        ['encrypt', 'decrypt']
    );
}

// Function to encrypt data using AES-256
async function encryptData(data, key) {
    const encoder = new TextEncoder();
    const encodedData = encoder.encode(data);

    const iv = window.crypto.getRandomValues(new Uint8Array(12));

    const cipherText = await window.crypto.subtle.encrypt(
        { name: 'AES-GCM', iv },
        key,
        encodedData
    );

    return { cipherText, iv };
}

// Function to decrypt data using AES-256
async function decryptData(cipherText, iv, key) {
    const decryptedData = await window.crypto.subtle.decrypt(
        { name: 'AES-GCM', iv },
        key,
        cipherText
    );

    const decoder = new TextDecoder();
    const decodedData = decoder.decode(decryptedData);

    return decodedData;
}

// Usage example
export async function encryptDataFunc(data) {
    try {
        // Generate AES-256 key
        const key = await generateKey();

        // Encrypt data
        const { cipherText, iv } = await encryptData(data, key);

        const base64CipherText = btoa(String.fromCharCode.apply(null, new Uint8Array(cipherText)));
        const base64IV = btoa(String.fromCharCode.apply(null, new Uint8Array(iv)));

        console.log('Encrypted data:', cipherText);
        console.log('IV:', iv);
        console.log('key:', key);

        // Decrypt data
        const decryptedData = await decryptData(cipherText, iv, key);
        console.log('Decrypted data:', decryptedData);
        return { base64CipherText, base64IV, key }

    } catch (error) {
        console.error('Error:', error);
    }
}