import React, { Suspense, useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import ProfileCard from '../components/cards/ProfileCard'
import CommonNavbar from '../components/common/CommonNavbar'
import Loading from '../components/common/Loading'
import { useUserDetails } from '../contextapi/userDetails'
import { AdminTicketCountsProvider, AdminTicketsProvider, useAdminDeveloper, useAdminHandler } from '../contextapi/adminContext'
import AdminBody from '../components/cards/admin/AdminBody'
import { adminMenu } from '../assets/arrays/sideBarMenu'

const adminDashboardApi = process.env.REACT_APP_API_ADMIN_DASHBOARD
const fetchUsersApi = process.env.REACT_APP_API_FETCH_USERS


export default function AdminDashboard() {
    const [sidebarIndex, setSidebarIndex] = useState(0) // To select sidemenu button

    const [fetchAdminApi, setFetchAdminApi] = useState(false) // States to control axios requests
    const { setUserDetails } = useUserDetails() // Store user Details

    const [fetchUserDetailsApi, setFetchUserDetailsApi] = useState(false) // States to control axios requests
    const { setHandlers } = useAdminHandler() // Store Handler Details
    const { setDevelopers } = useAdminDeveloper() // Store Developers Details


    const authToken = sessionStorage.getItem('authToken') // Fetch AuthToken
    const navigate = useNavigate()

    useEffect(() => {
        setFetchAdminApi(true)
        setFetchUserDetailsApi(true)
    }, [])


    // UserDetails API
    useEffect(() => {
        const fetchData = async () => {
            try {
                const adminResponse = await insertResponses(adminDashboardApi, authToken)
                setUserDetails(adminResponse.userDetails)
            } catch (error) {
                alert(error.message)
                navigate('/')
            }
        }
        if (fetchAdminApi) {
            fetchData();
        }
    }, [authToken, fetchAdminApi, navigate, setUserDetails])

    // Handlers and Developers details API
    useEffect(() => {
        if (fetchUserDetailsApi) {
            const fetchData = async () => {
                try {
                    // Fetch user details
                    const fetchUserResponse = await insertResponses(fetchUsersApi, authToken)
                    setHandlers(fetchUserResponse.handlers)
                    setDevelopers(fetchUserResponse.developers)
                } catch (error) {
                    alert(error);
                    navigate('/');
                }
            }
            fetchData()
        }
    }, [authToken, fetchUserDetailsApi, navigate, setDevelopers, setHandlers])

    return (
        <>
            <Row>
                <CommonNavbar />
            </Row>

            <Suspense fallback={<Loading />}>
                <Row>
                    <Col md={2} className='text-center p-4 bg-dark text-white'>
                        <ProfileCard
                            setSidebarIndex={setSidebarIndex}
                            sideMenu={adminMenu}
                        />
                    </Col>

                    <Col md={10}>
                        <AdminTicketsProvider>
                            <AdminTicketCountsProvider>
                                <AdminBody
                                    sidebarIndex={sidebarIndex}
                                />
                            </AdminTicketCountsProvider>
                        </AdminTicketsProvider >
                    </Col>
                </Row>
            </Suspense >
        </>
    )
}

const insertResponses = (api, authToken) => {
    return new Promise(async (resolve, reject) => {
        await axios.get(api, {
            headers: {
                'Authorization': authToken
            }
        })
            .then(apiResponse => resolve(apiResponse.data))
            .catch(error => reject(error.message))
    })
}