import React, { useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import CommonNavbar from '../components/common/CommonNavbar'
import landscape from '../assets/images/landscape.png'
import UserLogin from '../forms/UserLogin'

export default function OrganisationHome() {
    const [show, setShow] = useState(false)
    return (
        <>
            <Row>
                <CommonNavbar />
            </Row>
            <Row>
                <Col md={7}>
                    <img src={landscape} alt="workflow" style={{ height: '70vh' }} />
                </Col>
                <Col md={5} className='p-4 bg-light'>
                    <div className='d-flex justify-content-between'>
                        <UserLogin show={show} setShow={setShow} source='organisation' />
                    </div>
                </Col>
            </Row>
        </>
    )
}
