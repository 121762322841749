import React from 'react'
import { Table } from 'react-bootstrap'
import { useAdminHandler, useAdminTickets } from '../../../contextapi/adminContext'

export default function AdminHandlerStatus() {
    const { tickets } = useAdminTickets();
    const { handlers } = useAdminHandler();


    const handlersWithViewTicket = handlers.map(handler => {
        let viewTicket;
        if (handler.userName.includes('Divya Sinha')) {
            viewTicket = 'DHUPC II';
        } else if (handler.userName.includes('Vinod Babu')) {
            viewTicket = 'DHIT';
        }
        return { ...handler, viewTicket };
    });
    

    const progressTicketCountsByHandler = {};
    const reviewingTicketCountsByHandler = {};
    const closedTicketCountsByHandler = {};


    tickets.forEach(ticket => {
        const assignedHandler = handlersWithViewTicket.find(handler => handler.viewTicket === ticket.ticketType);
        if (assignedHandler) {
            if (ticket.ticketStatus === 'PROGRESS') {
                if (progressTicketCountsByHandler[assignedHandler.userName]) {
                    progressTicketCountsByHandler[assignedHandler.userName]++;
                } else {
                    progressTicketCountsByHandler[assignedHandler.userName] = 1;
                }
            } else if (ticket.ticketStatus === 'REVIEWING') {
                if (reviewingTicketCountsByHandler[assignedHandler.userName]) {
                    reviewingTicketCountsByHandler[assignedHandler.userName]++;
                } else {
                    reviewingTicketCountsByHandler[assignedHandler.userName] = 1;
                }
            } else if (ticket.ticketStatus === 'CLOSED') {
                if (closedTicketCountsByHandler[assignedHandler.userName]) {
                    closedTicketCountsByHandler[assignedHandler.userName]++;
                } else {
                    closedTicketCountsByHandler[assignedHandler.userName] = 1;
                }
            }
        }
    });

    return (
        <>
            <Table hover>
                <thead>
                    <tr>
                        <th>Division</th>
                        <th style={{ width: '180px', textAlign: 'center' }}>Reviewing Tickets</th>
                        <th style={{ width: '140px', textAlign: 'center' }}>Closed Tickets</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(progressTicketCountsByHandler).map(([handler, progressCount]) => (
                        <tr key={handler}>
                            <td>{handler}</td>
                            <td style={{ textAlign: 'center' }}>{reviewingTicketCountsByHandler[handler] || 0}</td>
                            <td style={{ textAlign: 'center' }}>{closedTicketCountsByHandler[handler] || 0}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    )
}
