export const ticketDetailedStructure = {
    ticketId: null,
    ticketStatus: "",
    complainantName: "",
    complainantRoleId: null,
    developerName: "",
    ticketType: "",
    updateDate: "",
    ticketPriority: "",
    openDate: "",
    complainantEmailId: "",
    companyName: "",
    complainantPhone: "",
    complainantEpr: "",
    issueCategory: "",
    issueSubcategory: "",
    closedDate: "",
    issue: "",
    remark: "",
    remarkBy: "",
    screenshots: ''
}

export const ticketShortInfoStructure = {
    ticketId: null,
    ticketStatus: '',
    developerName: '',
    updateDate: ''
}

export const ticketsActionPerformedStructure = {
    ticketId: null,
    previousStatus: '',
    currentStatus: '',
    previousAssignedTo: '',
    currentAssignedTo: '',
    previousRemark: '',
    currentRemark: '',
    previousUpdateDate: '',
    currentUpdateDate: ''
}