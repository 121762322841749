export const handlerDetailsStructure = {
    userId: null,
    userName: '',
    portalId: null,
    userStatus: ''
}

export const developerDetailsStructure = {
    userId: null,
    userName: '',
    portalId: null,
    userStatus: '',
    viewTicketType: ''
}